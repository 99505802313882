import {
  CalendarDaysIcon,
  CalendarIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import {
  ArrowLeftIcon,
  CounterClockwiseClockIcon,
  TimerIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../../hooks/useStores";
import {
  districtRoute,
  districtSchedulingRoute,
} from "../../../utils/routeHelper";
import DistrictLogo from "../../Avatars/DistrictLogo";
import MainSidebar from "../MainSidebar";
import SidebarTab from "../SidebarTab";

function SchedulingSidebar() {
  const navigate = useNavigate();

  const { ui, districts, sessions } = useStores();

  const { activeDistrictId, activeSessionId, getSchoolColor } = ui;

  if (!activeDistrictId || !activeSessionId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSession = sessions.getByUrlParam(activeSessionId);

  if (!activeDistrict || !activeSession) return null;

  return (
    <MainSidebar>
      {/* Render the planner switching dropdown */}
      <button
        onClick={() => {
          navigate(districtRoute(activeDistrict, "scheduling"));
        }}
        className={clsx(
          "bg-slate hover:bg-slate5 group m-2 flex items-center rounded-md px-4 py-6"
        )}
        style={{
          height: "40px",
        }}
      >
        <div className="text-slate11 group-hover:text-slate12 mr-1 pt-1">
          <ArrowLeftIcon className="h-5 w-5" />
        </div>
        <div className="text-slate11 group-hover:text-slate12 pt-1 text-[15px] font-bold">
          Back
        </div>
      </button>

      {/* Render Tabs */}
      <div className="m-2 mt-4 flex flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Scheduling
        </div>

        <SidebarTab
          to={districtSchedulingRoute(
            activeDistrict,
            activeSession,
            "calendar"
          )}
          icon={<CalendarDaysIcon />}
          text="Calendar"
        />

        <SidebarTab
          to={districtSchedulingRoute(
            activeDistrict,
            activeSession,
            "rotations"
          )}
          icon={<CalendarIcon />}
          text="Rotation Cycle"
        />

        <SidebarTab
          to={districtSchedulingRoute(activeDistrict, activeSession, "blocks")}
          icon={<Squares2X2Icon />}
          text="Blocks"
        />

        {/* <SidebarTab
          to={districtSchedulingRoute(
            activeDistrict,
            activeSession,
            "block-schedules"
          )}
          icon={<LapTimerIcon />}
          text="Block Schedules"
        /> */}

        <SidebarTab
          to={districtSchedulingRoute(
            activeDistrict,
            activeSession,
            "alternate-schedules"
          )}
          icon={<CounterClockwiseClockIcon />}
          text="Alternate Days"
        />

        <SidebarTab
          to={districtSchedulingRoute(
            activeDistrict,
            activeSession,
            "class-schedules"
          )}
          icon={<TimerIcon />}
          text="Class Schedule"
        />
      </div>

      {/* Render Session name */}
      <div className="mt-auto flex w-full flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Session
        </div>
        <div
          className={clsx(
            "bg-slate focus-visible:ring-blue7 m-2 mt-auto flex items-center rounded-md px-4 py-6 focus-visible:ring focus-visible:ring-opacity-50"
          )}
          style={{
            height: "40px",
          }}
        >
          {/* Wrap the planner logo */}
          <div className="pr-[12px]">
            <DistrictLogo
              districtData={{
                initial: activeSession.name[0],
                id: activeSession.id,
                color: getSchoolColor(activeSession.id),
              }}
              size={28}
            />
          </div>
          {/* Render the planner name with truncation */}
          <div className="truncate text-[15px] font-semibold">
            {activeSession.name}
          </div>
        </div>
      </div>
    </MainSidebar>
  );
}

export default observer(SchedulingSidebar);
