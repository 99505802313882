import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { createSectionsList } from "../../actions/scheduling";
import useDropdownContext from "../../hooks/useDropdownContext";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
};

const SectionsDropdown = ({
  children: triggerComponent,
}: DropdownMenuProps) => {
  const context = useDropdownContext();

  const actions = useMemo(() => {
    const sectionsList = createSectionsList(context);

    if (!sectionsList) {
      return [];
    }

    return [sectionsList];
  }, [context]);

  return (
    <DropdownMenu triggerComponent={triggerComponent} asChild={false}>
      <DropdownList actions={actions} />
    </DropdownMenu>
  );
};

export default observer(SectionsDropdown);
