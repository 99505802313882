import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { createSchoolsList } from "../../actions/scheduling";
import useDropdownContext from "../../hooks/useDropdownContext";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
};

const SchoolsDropdown = ({ children: triggerComponent }: DropdownMenuProps) => {
  const context = useDropdownContext();

  const actions = useMemo(() => {
    const schoolsList = createSchoolsList(context);

    if (!schoolsList) {
      return [];
    }

    return [schoolsList];
  }, [context]);

  return (
    <DropdownMenu triggerComponent={triggerComponent} asChild={false}>
      <DropdownList actions={actions} />
    </DropdownMenu>
  );
};

export default observer(SchoolsDropdown);
