import {
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MinusIcon,
  PaintBrushIcon,
  PlayIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import {
  CheckIcon,
  CopyIcon,
  Cross1Icon,
  DotsHorizontalIcon,
  DotsVerticalIcon,
  DownloadIcon,
  EnterFullScreenIcon,
  ExitFullScreenIcon,
  GearIcon,
  LockClosedIcon,
  LockOpen1Icon,
  MixIcon,
  OpenInNewWindowIcon,
  Pencil1Icon,
  Pencil2Icon,
  RocketIcon,
  Share2Icon,
  SizeIcon,
  StarFilledIcon,
  StarIcon,
  SymbolIcon,
  TriangleDownIcon,
} from "@radix-ui/react-icons";

import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { SyntheticEvent } from "react";
import logo from "../../assets/logos/logo-no-text.png";
import styles from "./index.module.css";

interface ButtonProps {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "constructive"
    | "editor";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  showSpinner?: boolean;
  className?: string;
  iconClassName?: string;
  rounded?: "full" | "medium" | "none";
  padding?: "small" | "medium" | "large";
  iconPosition?: "left" | "right";
  shadow?: "default" | "none";
  tabIndex?: number;
  trackingName?: string;
}

export default function Button(props: ButtonProps) {
  const {
    icon,
    buttonText = "",
    onClick,
    disabled,
    fullWidth,
    width,
    type,
    theme = "primary",
    className: classNameProp,
    iconClassName: iconClassNameProp,
    rounded,
    padding,
    iconPosition = "left",
    shadow = "default",
    tabIndex,
    trackingName,
  } = props;

  // Write switch function for icon later on if needed (e.g. create, plus, delete, etc.);
  // Primary button = Accent color background and border and hover effect
  // Secondary button = No background but border and hover effect
  // Tertiary button = No background and no border and no hover effect (e.g. in app link)
  // Destructive button = Red background and border and hover effect
  // Constructive button = Green background and border and hover effect
  // Editor button = No background and no border but hover effect

  const renderIcon = () => {
    switch (icon) {
      case "chevron-left":
        return (
          <ChevronLeftIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "chevron-right":
        return (
          <ChevronRightIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "chevron-down":
        return (
          <ChevronDownIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "expand":
        return (
          <EnterFullScreenIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "comments":
        return (
          <ChatBubbleBottomCenterTextIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "options":
        return (
          <EllipsisHorizontalIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "play":
        return (
          <PlayIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-5 w-5"
            )}
          />
        );
      case "plus":
        return (
          <PlusIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "minus":
        return (
          <MinusIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "adjustments":
        return (
          <AdjustmentsVerticalIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "paint-brush":
        return (
          <PaintBrushIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "AI":
        return (
          <SparklesIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "help":
        return (
          <QuestionMarkCircleIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "share":
        return (
          <Share2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "dots-horizontal":
        return (
          <DotsHorizontalIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "dots-vertical":
        return (
          <DotsVerticalIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "settings":
        return (
          <GearIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star":
        return (
          <StarIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "import":
        return (
          <ArrowUpTrayIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-filled":
        return (
          <StarFilledIcon
            className={clsx(
              "h-5 w-5 text-yellow-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-remove":
        return (
          <div className="relative h-5 w-5">
            <StarIcon
              className={clsx(
                "h-5 w-5 ",
                iconClassNameProp ? iconClassNameProp : ""
              )}
            />
            {/* Render slash on the star */}
          </div>
        );
      case "blocks":
        return (
          <MixIcon
            className={clsx(
              "h-5 w-5",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "triangle-down":
        return (
          <TriangleDownIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "pencil":
        return (
          <Pencil1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "edit":
        return (
          <Pencil2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1"
            )}
          />
        );
      case "check":
        return (
          <CheckIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "text-green-400"
            )}
          />
        );
      case "x":
        return (
          <Cross1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "h-4 w-4 text-red-400"
            )}
          />
        );
      case "external-link":
        return (
          <OpenInNewWindowIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "enter-full-screen":
        return (
          <EnterFullScreenIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "exit-full-screen":
        return (
          <ExitFullScreenIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "rocket":
        return (
          <RocketIcon
            className={clsx(
              styles.radixButtonIcon,
              // "text-blue-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "close":
        return (
          <Cross1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "size":
        return (
          <SizeIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "download":
        return (
          <DownloadIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copy":
        return (
          <CopyIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copied":
        return (
          <CheckIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "text-green-400"
            )}
          />
        );
      case "sync":
        return (
          <SymbolIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1 h-4 w-4"
            )}
          />
        );
      case "locked":
        return (
          <LockClosedIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1 h-4 w-4"
            )}
          />
        );
      case "unlock":
        return (
          <LockOpen1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1 h-4 w-4"
            )}
          />
        );

      case "logo":
        return <img src={logo} alt="logo" className="mr-1 h-6 w-6" />;
      default:
        return null;
    }
  };

  const renderTheme = () => {
    switch (theme) {
      case "primary":
        return styles.buttonPrimary;
      case "secondary":
        return styles.buttonSecondary;
      case "tertiary":
        return styles.buttonTertiary;
      case "destructive":
        return styles.buttonDestructive;
      case "constructive":
        return styles.buttonConstructive;
      case "editor":
        return styles.buttonEditor;
      default:
        return styles.buttonPrimary;
    }
  };
  // const fullW = fullWidth ? "w-full" : width ? `w-[${width}px]` : "w-auto";

  return (
    <button
      onClick={(e) => {
        if (onClick) {
          // if (trackingName) {
          //   mixpanel.track(trackingName, {
          //     componentType: "button",
          //   });
          // }
          onClick(e);
        }
      }}
      disabled={disabled}
      type={type}
      className={clsx(
        styles.button,
        "flex items-center justify-center text-sm font-semibold text-white ",
        // fullW,
        renderTheme(),
        classNameProp ? classNameProp : "",
        padding === "small" || theme === "editor"
          ? styles.buttonPaddingSmall
          : padding === "medium"
          ? styles.buttonPaddingMedium
          : styles.buttonPaddingLarge,
        theme !== "editor" && shadow === "default" ? "shadow-sm" : ""
      )}
      style={{
        borderRadius:
          rounded === "full"
            ? "50%"
            : rounded === "medium" || theme === "editor"
            ? "8px"
            : "24px",
        pointerEvents: disabled ? "none" : "auto",
        cursor: disabled ? "not-allowed" : "pointer",
        width: fullWidth ? "100%" : width ? `${width}px` : "auto",
      }}
      tabIndex={tabIndex || 0}
    >
      {icon && iconPosition === "left" && <span>{renderIcon()}</span>}
      {icon && buttonText && iconPosition === "left" && (
        <span className="w-1" />
      )}
      {buttonText && <span>{buttonText}</span>}
      {icon && buttonText && iconPosition === "right" && (
        <span className="w-1" />
      )}
      {icon && iconPosition === "right" && <span>{renderIcon()}</span>}
    </button>
  );
}
