export const theme = {
  mainSidebarWidth: 240, // 260
  secondarySidebarWidth: 280, // 240
  mainSidebarMaxWidth: 500,
  mainSidebarMinWidth: 200,
  secondarySidebarMaxWidth: 450,
  secondarySidebarMinWidth: 200,
  mainSidebarWhenCollapsedWidth: 20,
  sidebarPadding: 16,
};
