import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import { Fragment, useState } from "react";

function Dashboard() {
  const secondaryNavigation = [
    { id: "overview", name: "Overview" },
    { id: "users", name: "Users" },
    { id: "chats", name: "Chats" },
    { id: "tools", name: "Tools" },
    { id: "resources", name: "Resources" },
    { id: "documents", name: "Documents" },
  ];

  const stats = [
    {
      name: "Revenue",
      value: "$405,091.00",
      change: "+4.75%",
      changeType: "positive",
    },
    {
      name: "Payouts",
      value: "$12,787.00",
      change: "+54.02%",
      changeType: "negative",
    },
    {
      name: "Paid Organizations",
      value: "3",
      change: "-1.39%",
      changeType: "positive",
    },
    {
      name: "Paid Users",
      value: "30",
      change: "+10.18%",
      changeType: "positive",
    },
  ];

  const usageStats = [
    {
      name: "Users",
      value: "230",
      change: "+4.75%",
      changeType: "positive",
    },
    {
      name: "Chats",
      value: "500",
      change: "+20.02%",
      changeType: "positive",
    },
    {
      name: "Tools",
      value: "300",
      change: "+5.39%",
      changeType: "positive",
    },
    {
      name: "Resources",
      value: "240",
      change: "+10.18%",
      changeType: "positive",
    },
    {
      name: "Documents",
      value: "24",
      change: "+4.18%",
      changeType: "positive",
    },
  ];

  const clients = [
    {
      id: 1,
      name: "Tuple",
      imageUrl: "https://tailwindui.com/img/logos/48x48/tuple.svg",
      lastInvoice: {
        date: "December 13, 2022",
        dateTime: "2022-12-13",
        amount: "$2,000.00",
        status: "Overdue",
      },
    },
    {
      id: 2,
      name: "SavvyCal",
      imageUrl: "https://tailwindui.com/img/logos/48x48/savvycal.svg",
      lastInvoice: {
        date: "January 22, 2023",
        dateTime: "2023-01-22",
        amount: "$14,000.00",
        status: "Paid",
      },
    },
    {
      id: 3,
      name: "Reform",
      imageUrl: "https://tailwindui.com/img/logos/48x48/reform.svg",
      lastInvoice: {
        date: "January 23, 2023",
        dateTime: "2023-01-23",
        amount: "$7,600.00",
        status: "Paid",
      },
    },
  ];

  const statuses: any = {
    Paid: "text-green-700 bg-green-50 ring-green-600/20",
    Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
    Overdue: "text-red-700 bg-red-50 ring-red-600/10",
  };

  const timeFilterOptions = [
    { id: "7days", name: "Last 7 days", href: "#", current: true },
    { id: "30days", name: "Last 30 days", href: "#", current: false },
    { id: "allTime", name: "All-time", href: "#", current: false },
  ];

  const userCategoryOptions = [
    { id: "all", name: "All" },
    { id: "individual", name: "Individual" },
    { id: "enterprise", name: "Enterprise" },
  ];

  const [currentTab, setCurrentTab] = useState("overview");
  const [currentTimeFilter, setCurrentTimeFilter] = useState("7days");
  const [currentUserCategory, setCurrentUserCategory] = useState("all");

  const renderFilters = () => {
    return (
      <header
        className={clsx(
          "flex w-full pb-4 pt-6 sm:pb-6",
          currentTab === "overview" || currentTab === "organizations"
            ? "justify-start"
            : "justify-between"
        )}
      >
        {/* Time filters */}
        <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
          <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
            {timeFilterOptions.map((item: any) => (
              <button
                key={item.id}
                className={
                  currentTimeFilter === item.id
                    ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                    : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
                }
                onClick={() => {
                  setCurrentTimeFilter(item.id);
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        {/* Enterprise filters */}
        {currentTab === "overview" || currentTab === "organizations" ? null : (
          <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
            <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
              {userCategoryOptions.map((item: any) => (
                <button
                  key={item.id}
                  className={
                    currentUserCategory === item.id
                      ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                      : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
                  }
                  onClick={() => {
                    setCurrentUserCategory(item.id);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </header>
    );
  };

  const renderNavBar = () => {
    return (
      <nav className="flex w-full overflow-x-auto border-b border-white/10 py-4">
        <ul
          role="list"
          className="flex min-w-full flex-none justify-center gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
        >
          {secondaryNavigation.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => {
                  setCurrentTab(item.id);
                }}
                className={currentTab === item.id ? "text-blue-500" : ""}
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  // Overview functions
  const renderOverviewStripeStats = () => {
    return (
      <div className="relative isolate overflow-hidden pt-4">
        <div className="text-base font-semibold leading-7 text-gray-900">
          Payments
        </div>
        <div className="mt-4 border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            {stats.map((stat: any, statIdx: number) => (
              <div
                key={stat.name}
                className={clsx(
                  statIdx % 2 === 1
                    ? "sm:border-l"
                    : statIdx === 2
                    ? "lg:border-l"
                    : "",
                  "flex flex-wrap items-baseline justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                )}
              >
                <dt className="text-sm font-medium leading-6 text-gray-500">
                  {stat.name}
                </dt>
                <dd
                  className={clsx(
                    stat.changeType === "negative"
                      ? "text-rose-600"
                      : "text-green-700",
                    "text-xs font-medium"
                  )}
                >
                  {stat.change}
                </dd>
                <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        {/*  */}

        <div
          className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>
    );
  };

  const renderOverviewUsageStats = () => {
    return (
      <div className="relative isolate mt-16 overflow-hidden">
        <div className="text-base font-semibold leading-7 text-gray-900">
          Activity
        </div>
        <div className="mt-4 border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 lg:px-2 xl:px-0">
            {usageStats.map((stat: any, statIdx: number) => (
              <div
                key={stat.name}
                className={clsx(
                  statIdx % 2 === 1
                    ? "sm:border-l"
                    : statIdx === 2 || statIdx === 4
                    ? "lg:border-l"
                    : "",
                  "flex flex-wrap items-baseline justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
                )}
              >
                <dt className="text-sm font-medium leading-6 text-gray-500">
                  {stat.name}
                </dt>
                <dd
                  className={clsx(
                    stat.changeType === "negative"
                      ? "text-rose-600"
                      : "text-green-700",
                    "text-xs font-medium"
                  )}
                >
                  {stat.change}
                </dd>
                <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        {/*  */}

        <div
          className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>
    );
  };

  const renderOverviewOrgsStats = () => {
    return (
      <div className="mx-auto mt-16 max-w-7xl">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="flex items-center justify-between">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Recent organizations
            </h2>
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              View all<span className="sr-only">, clients</span>
            </a>
          </div>
          <ul
            role="list"
            className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
          >
            {clients.map((client) => (
              <li
                key={client.id}
                className="overflow-hidden rounded-xl border border-gray-200"
              >
                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                  <img
                    src={client.imageUrl}
                    alt={client.name}
                    className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                  />
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    {client.name}
                  </div>
                  <Menu as="div" className="relative ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisHorizontalIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={clsx(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              View
                              <span className="sr-only">, {client.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={clsx(
                                active ? "bg-gray-50" : "",
                                "block px-3 py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              Edit
                              <span className="sr-only">, {client.name}</span>
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Last invoice</dt>
                    <dd className="text-gray-700">
                      <time dateTime={client.lastInvoice.dateTime}>
                        {client.lastInvoice.date}
                      </time>
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Amount</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-gray-900">
                        {client.lastInvoice.amount}
                      </div>
                      <div
                        className={clsx(
                          statuses[client.lastInvoice.status],
                          "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                        )}
                      >
                        {client.lastInvoice.status}
                      </div>
                    </dd>
                  </div>
                </dl>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  // Users functions

  // Chats functions

  // Tools functions

  // Resources functions

  // Documents functions

  return (
    <div>
      {renderNavBar()}
      {renderFilters()}
      {renderOverviewStripeStats()}
      {renderOverviewOrgsStats()}
      {renderOverviewUsageStats()}
    </div>
  );
}

export default Dashboard;
