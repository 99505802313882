import { gql } from "../../__generated__/gql";

export const GetAITools = gql(`
    query GetAiTools(
        $where: AiToolWhereInput!,
        $orderBy: [AiToolOrderByInput!],
        $take: Float,
        $skip: Float
    ) {
        aiTools(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
            aiToolParticipants {
                aiTool {
                    id
                }
                createdAt
                owner
                id 
                permissions
                updatedAt
                user {
                    id
                    firstName
                    lastName
                    email
                    avatarUrl
                }
            }
            aiToolSteps {
                aiTool {
                    id
                }
                createdAt
                data
                id 
                stepId
                updatedAt
            }
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
        }
    }
`);

export const GetAIToolsMeta = gql(`
    query GetAiToolsMeta(
        $where: AiToolWhereInput!
    ) {
        _aiToolsMeta(where: $where) {
            count
        }
    }
`);
