import { ChevronDownIcon } from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import EnrollmentCreateAndEditModal from "../components/CRUD/CreateAndEdit/Enrollment";
import SectionsDropdown from "../components/Dropdown/SectionsDropdown";
import ListOfEnrollments from "../components/Enrollments/ListOfEnrollments";
import PageLayout from "../components/PageLayout";
import Tooltip from "../components/Tooltip";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Enrollments() {
  const [showCreateEnrollmentModal, setShowCreateEnrollmentModal] =
    useState(false);

  const { ui, sections, enrollments } = useStores();

  const { addToast } = useToastsContext();

  const { activeSectionId } = ui;

  if (!activeSectionId) return null;

  const activeSection = sections.getByUrlParam(activeSectionId);

  if (!activeSection) return null;

  const enrollmentsForSection = enrollments.getEnrollmentsForSection(
    activeSection.id
  );

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateEnrollmentModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div className="flex">
            <h2 className="text-xl font-semibold">Enrollments</h2>
            <SectionsDropdown>
              <Tooltip content="Change section" dir="top" asChild={false}>
                <button className="border-slate7 ml-4 flex items-center justify-center rounded-lg border-[1px] border-solid p-1 px-2 shadow-sm">
                  {/* Name */}
                  <div className="ml-2 text-sm font-medium">
                    {activeSection.name}
                  </div>
                  <span className="w-1" />
                  <ChevronDownIcon className="h-5 w-5" />
                </button>
              </Tooltip>
            </SectionsDropdown>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfEnrollments
            key={enrollmentsForSection.length}
            enrollments={enrollmentsForSection}
          />
        </div>
      </PageLayout>
      {showCreateEnrollmentModal && (
        <EnrollmentCreateAndEditModal
          onClose={() => setShowCreateEnrollmentModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateEnrollmentModal(false);

            addToast("Class created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Enrollments);
