import { BuildingOfficeIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../../hooks/useStores";
import { districtRoute } from "../../../utils/routeHelper";
import DistrictLogo from "../../Avatars/DistrictLogo";
import MainSidebar from "../MainSidebar";
import SidebarTab from "../SidebarTab";

function DistrictSidebar() {
  const navigate = useNavigate();

  const { ui, districts } = useStores();

  const { activeDistrictId, getDistrictColor } = ui;

  if (!activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  return (
    <MainSidebar>
      {/* Render the planner switching dropdown */}
      <button
        onClick={() => {
          navigate("/");
        }}
        className={clsx(
          "bg-slate hover:bg-slate5 group m-2 flex items-center rounded-md px-4 py-6"
        )}
        style={{
          height: "40px",
        }}
      >
        <div className="text-slate11 group-hover:text-slate12 mr-1 pt-1">
          <ArrowLeftIcon className="h-5 w-5" />
        </div>
        <div className="text-slate11 group-hover:text-slate12 pt-1 text-[15px] font-bold">
          Back
        </div>
      </button>

      {/* Render Tabs */}
      <div className="m-2 mt-4 flex flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Rostering
        </div>

        <SidebarTab
          to={districtRoute(activeDistrict, "users")}
          icon={<UsersIcon />}
          text="People"
        />

        <SidebarTab
          to={districtRoute(activeDistrict, "schools")}
          icon={<BuildingOfficeIcon />}
          text="Schools"
        />

        {/* <SidebarTab
          to={districtRoute(activeDistrict, "scheduling")}
          icon={<CalendarDaysIcon />}
          text="Sessions"
        />

        <SidebarTab
          to={districtRoute(activeDistrict, "subjects")}
          icon={<BeakerIcon />}
          text="Subjects"
        />

        <SidebarTab
          to={districtRoute(activeDistrict, "courses")}
          icon={<MixIcon />}
          text="Courses"
        />

        <SidebarTab
          to={districtRoute(activeDistrict, "classes")}
          icon={<CalculatorIcon />}
          text="Classes"
        /> */}
      </div>

      {/* <div className="m-2 mt-4 flex flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Curriculum
        </div>
        <SidebarTab
          to={districtRoute(activeDistrict, "drives")}
          icon={<FolderIcon />}
          text="Drives"
        />
        <SidebarTab
          to={districtRoute(activeDistrict, "docs")}
          icon={<DocumentTextIcon />}
          text="Resources"
        />
      </div>

      <div className="m-2 mt-4 flex flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Collaboration
        </div>
        <SidebarTab
          to={districtRoute(activeDistrict, "departments")}
          icon={<BuildingLibraryIcon />}
          text="Departments"
        />
        <SidebarTab
          to={districtRoute(activeDistrict, "groups")}
          icon={<UserGroupIcon />}
          text="Groups"
        />
      </div> */}

      {/* <div className="m-2 mt-4 flex flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Integrations
        </div>
        <SidebarTab
          to={districtRoute(activeDistrict, "logs")}
          icon={<WrenchIcon />}
          text="Active"
        />
        <SidebarTab
          to={districtRoute(activeDistrict, "logs")}
          icon={<CircleStackIcon />}
          text="Logs"
        />
      </div> */}

      {/* Render District name */}
      <div className="mt-auto flex w-full flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          District
        </div>
        <div
          className={clsx(
            "bg-slate focus-visible:ring-blue7 m-2 mt-auto flex items-center rounded-md px-4 py-6 focus-visible:ring focus-visible:ring-opacity-50"
          )}
          style={{
            height: "40px",
          }}
        >
          {/* Wrap the planner logo */}
          <div className="pr-[12px]">
            <DistrictLogo
              districtData={{
                initial: activeDistrict.name[0],
                id: activeDistrict.id,
                color: getDistrictColor(activeDistrict.id),
              }}
              size={28}
            />
          </div>
          {/* Render the planner name with truncation */}
          <div className="truncate text-[15px] font-semibold">
            {activeDistrict.name}
          </div>
        </div>
      </div>
    </MainSidebar>
  );
}

export default observer(DistrictSidebar);
