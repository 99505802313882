import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ScrollArea } from "../../ScrollArea";
import styles from "./EmojiList.module.scss";

export const EmojiList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ name: item.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x) => {
          if (x.event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (x.event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (x.event.key === "Enter") {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  return (
    <div
      className={
        "data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade shadow-dropdown-shadow z-[500] flex min-w-[220px] flex-col rounded-md bg-white will-change-[opacity,transform]"
      }
    >
      <ScrollArea className={"max-h-[220px]"}>
        {props.items.map((item, index) => (
          <button
            className={clsx(
              styles.item,
              index === selectedIndex ? styles.isSelected : ""
            )}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.fallbackImage ? (
              <img src={item.fallbackImage} align="absmiddle" />
            ) : (
              item.emoji
            )}
            <span className="ml-2">:{item.name}:</span>
          </button>
        ))}
      </ScrollArea>
    </div>
  );
});
