import {
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { Datepicker, momentTimezone } from "@mobiscroll/react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../components/Button";
import RotationEventCreateAndEditModal from "../components/CRUD/CreateAndEdit/Event";
import PageLayout from "../components/PageLayout";
import RotationDays, {
  RotationDayFormEntity,
} from "../components/RotationDays";
import ListOfRotationEvents from "../components/RotationEvents/ListOfRotationEvents";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";
import Event from "../models/Event";
import { districtSchedulingRoute } from "../utils/routeHelper";
import { CreateRotationDayInput } from "../__generated__/graphql";

momentTimezone.moment = moment;

/**
 * @description Round time to nearest seconds
 */
const roundSeconds = (time: Date) => {
  time.setMinutes(time.getMinutes() + Math.round(time.getSeconds() / 60));
  time.setSeconds(0, 0);
  return time;
};

const replaceTimeWithZeros = (isoString: string): string => {
  try {
    const dateOnly = isoString.split("T")[0]; // Extract the date part
    return dateOnly + "T00:00:00.000Z"; // Append zeros for time and 'Z' for UTC timezone
  } catch (error) {
    // If there's an error (invalid ISO string), return the original string
    return isoString;
  }
};

function RotationCalendar() {
  const { rotationId = "" } = useParams();

  const [modifiedRotatationDays, setModifiedRotationDays] = useState<
    RotationDayFormEntity[]
  >([]);

  const [submitRotationDaysErrors, setSubmitRotationDaysErrors] = useState<
    string[]
  >([]);

  const [showCreateRotationEventModal, setShowCreateRotationEventModal] =
    useState(false);

  const { rotationDays, districts, rotationSchedules, sessions, ui, events } =
    useStores();

  const { addToast } = useToastsContext();

  useEffect(() => {
    if (rotationId) {
      ui.setActiveRotationScheduleId(rotationId);
    }

    return () => {
      ui.setActiveRotationScheduleId(undefined);
    };
  }, [rotationId, ui]);

  const { activeDistrictId, activeSessionId } = ui;

  const [startDayForPicker, setStartEndForPicker] = useState<Date | null>(null);
  const [endDayForPicker, setEndDayForPicker] = useState<Date | null>(null);

  const [startDayEvent, setStartDayEvent] = useState<Event | null>(null);
  const [endDayEvent, setEndDayEvent] = useState<Event | null>(null);

  const getDateInLocalTimezone = (isoDatetime: string): Date | null => {
    // Split the ISO string into date and time
    const [date] = isoDatetime.split("T");

    const parts = date.split("-");

    // Initialize a Date object with the date part
    const dateOnly = new Date(
      Number(parts[0]),
      Number(parts[1]) - 1,
      Number(parts[2])
    );

    return dateOnly;
  };

  const activeRotationSchedule = rotationSchedules.get(rotationId);

  // Initialize start and end days for picker
  useEffect(() => {
    if (!activeRotationSchedule) return;

    const activeCalendar = activeRotationSchedule.activeCalendar;

    if (!activeCalendar) return;

    const startDayEvent = activeCalendar.firstDayEvent;

    const startDay =
      startDayEvent && startDayEvent.date
        ? getDateInLocalTimezone(startDayEvent.date)
        : undefined;

    console.log("Active calendar", toJS(activeCalendar.toGQLAttributes()));

    const endDayEvent = activeCalendar.lastDayEvent;
    const endDay =
      endDayEvent && endDayEvent.date
        ? getDateInLocalTimezone(endDayEvent.date)
        : undefined;

    setStartDayEvent(startDayEvent);

    setEndDayEvent(endDayEvent);

    if (startDay) {
      setStartEndForPicker(new Date(startDay));
    }

    if (endDay) {
      setEndDayForPicker(new Date(endDay));
    }
  }, [
    activeDistrictId,
    activeRotationSchedule,
    activeRotationSchedule?.activeCalendar,
  ]);

  if (!activeDistrictId || !activeSessionId || !rotationId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSession = sessions.getByUrlParam(activeSessionId);

  const activeRotationScheduleDays =
    rotationDays.getRotationDaysForSchedule(rotationId);

  if (!activeDistrict || !activeSession || !activeRotationSchedule) return null;

  const activeCalendar = activeRotationSchedule.activeCalendar;

  if (!activeCalendar) return null;

  const submitValidationForRotationDays = (): string[] => {
    // modifiedRotatationDays

    const errors = [];

    if (modifiedRotatationDays.length === 0) {
      errors.push("You must add at least one rotation day");
    }

    // We loop over all the rotation days and check for errors
    // Here is the schema for the rotaiton day input
    // id?: string;
    // dayIndex?: number;
    // abbreviation?: string;
    // description?: string;

    // We need to check for the following errors:
    // 1. Day index is not a number
    // 2. Day index is not unique
    // 3. Day index must be greater than 0
    // 4. Day index must be covering all indexes from 1 to the last day index (no gaps) (order doesn't matter)
    // 5. Abbreviation is empty

    let counter = 0;

    const duplicateDayIndexes = new Set<number>();

    for (let rotationDayInput of modifiedRotatationDays) {
      counter++;

      // Check for empty abbreviation
      if (!rotationDayInput.abbreviation) {
        errors.push("Abbreviation cannot be empty for row " + counter);
        continue;
      }

      if (!rotationDayInput.dayIndex) {
        errors.push("Day index cannot be empty for row " + counter);
        continue;
      }

      const parsedDayIndex = parseInt(rotationDayInput.dayIndex);

      // Check for day index not being a number
      if (Number.isNaN(parsedDayIndex)) {
        errors.push("Day index must be a number for row " + counter);
        continue;
      }

      // Check for day index being less than 1
      if (parsedDayIndex < 1) {
        errors.push("Day index must be greater than 0 for row " + counter);
      }

      // Check for day index being a duplicate
      if (duplicateDayIndexes.has(parsedDayIndex)) {
        errors.push("Day index must be unique for row " + counter);
      }

      duplicateDayIndexes.add(parsedDayIndex);
    }

    // Check for day index not covering all indexes from 1 to the last day index (no gaps) (order doesn't matter)
    const dayIndexes = Array.from(duplicateDayIndexes);

    dayIndexes.sort((a, b) => a - b);

    const firstDayIndex = dayIndexes[0];

    const lastDayIndex = dayIndexes[dayIndexes.length - 1];

    if (firstDayIndex !== 1) {
      errors.push("Day index must start at 1");
    }

    if (lastDayIndex !== dayIndexes.length) {
      errors.push(
        "Day index must be between 1 and " +
          dayIndexes.length +
          ". It should include all values."
      );
    }

    setSubmitRotationDaysErrors(errors);

    return errors;
  };

  const submitRotationDays = async () => {
    if (!startDayForPicker || !endDayForPicker) {
      return;
    }

    let initialStartDay = startDayEvent;
    let initialEndDay = endDayEvent;

    const startDate = replaceTimeWithZeros(startDayForPicker.toISOString());
    const endDate = replaceTimeWithZeros(endDayForPicker.toISOString());

    // We first make the call to update the start and end days
    const { startEvent, endEvent } = await events.setFirstAndLastDayEvents({
      id: activeRotationSchedule.id,
      startDate,
      endDate,
    });

    setStartDayEvent(startEvent);
    setEndDayEvent(endEvent);

    // we run this validation only if the start and end dates are set
    if (initialStartDay && initialEndDay) {
      const errors = submitValidationForRotationDays();
      console.log(errors);
      if (errors.length > 0) {
        return;
      }
    } else {
      return;
    }

    const createRotationDaysInput = modifiedRotatationDays.map(
      (rotationDayInput) => {
        return {
          id: rotationDayInput.id,
          dayIndex: parseInt(rotationDayInput.dayIndex!),
          abbreviation: rotationDayInput.abbreviation!,
          description: rotationDayInput.description,
        } as CreateRotationDayInput;
      }
    );

    console.log("create Rotation days input", createRotationDaysInput);

    try {
      const response = await rotationDays.saveRotationDays(
        createRotationDaysInput,
        activeRotationSchedule.id
      );

      if (response) {
        addToast("Rotation days saved successfully.", {
          type: "success",
        });
      } else {
        addToast("Error saving rotation days.", {
          type: "error",
        });
      }
    } catch (e) {
      addToast("Error saving rotation days.", {
        type: "error",
      });
    }
  };

  const renderRotationCalendarBreadcrumbs = () => {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div className="flex items-center">
              <Link
                to={districtSchedulingRoute(activeDistrict, activeSession)}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={undefined}
              >
                Rotation Schedules
              </Link>
            </div>
          </li>

          <li key={activeRotationSchedule.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <Link
                to={activeRotationSchedule.id}
                className="ml-4 text-sm font-medium text-gray-700"
                aria-current={"page"}
              >
                {activeRotationSchedule.name}
              </Link>
            </div>
          </li>
        </ol>
      </nav>
    );
  };

  const renderRight = (
    <div className="flex items-center">
      <React.Fragment>
        <Button
          type="button"
          theme="primary"
          className="mr-3"
          buttonText="Save Changes"
          padding="medium"
          rounded="medium"
          onClick={() => submitRotationDays()}
        />
      </React.Fragment>
    </div>
  );

  const renderNoFirstAndLastEventsWarning = () => {
    const { firstDayEvent, lastDayEvent } = activeCalendar;

    let errorMessage = "";

    if (!firstDayEvent && !lastDayEvent) {
      errorMessage =
        "You must enter first and last day of this schedule in order to add rotation days.";
    } else if (!firstDayEvent) {
      errorMessage =
        "You must enter first day of this schedule in order to add rotation days.";
    } else {
      errorMessage =
        "You must enter last day of this schedule in order to add rotation days.";
    }

    return (
      <div className="mt-20 w-full text-center">
        <ExclamationCircleIcon className="mx-auto h-12 w-12 text-yellow-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          {errorMessage}
        </h3>
      </div>
    );
  };

  const renderDaysTabContent = () => {
    const field = {
      fieldKind: "datepicker",
      id: "date",
      label: "Date*",
      placeholder: "Select the date of the event",
    };

    return (
      <div className="flex w-full flex-col">
        <div className="flex max-w-xl space-x-5 p-5">
          <div className="flex-grow">
            <label
              htmlFor="date"
              className="text-slate11 block text-sm font-medium"
            >
              First day of rotation
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date"]}
                timezonePlugin={momentTimezone}
                touchUi={true}
                theme="ios"
                value={startDayForPicker}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={async (event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);

                  // if end day exists and is less than start day, then throw exception
                  if (
                    endDayForPicker &&
                    new Date(endDayForPicker) < roundOffDate
                  ) {
                    addToast("Start date cannot be after the end date.", {
                      type: "error",
                    });
                    return;
                  }

                  // if roundoffdate is less than today, then throw exception
                  if (roundOffDate < new Date()) {
                    addToast("Start date cannot be before today.", {
                      type: "error",
                    });
                    return;
                  }

                  setStartEndForPicker(roundOffDate);
                }}
                responsive={{
                  xsmall: {
                    controls: ["date"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
          <div className="flex-grow">
            <label
              htmlFor="date"
              className="text-slate11 block text-sm font-medium"
            >
              Last day of rotation
            </label>
            <div className="mt-1">
              <Datepicker
                controls={["date"]}
                timezonePlugin={momentTimezone}
                touchUi={true}
                theme="ios"
                value={endDayForPicker}
                themeVariant="light"
                inputProps={{
                  placeholder: field.placeholder,
                }}
                onChange={(event: any) => {
                  const date = new Date(event.value);
                  const roundOffDate = roundSeconds(date);

                  // if start day exists and is greater than end day, then throw exception
                  if (
                    startDayForPicker &&
                    new Date(startDayForPicker) > roundOffDate
                  ) {
                    addToast("End date cannot be before the start date.", {
                      type: "error",
                    });
                    return;
                  }

                  // if roundoffdate is less than today, then throw exception
                  if (roundOffDate < new Date()) {
                    addToast("End date cannot be before today.", {
                      type: "error",
                    });
                    return;
                  }

                  setEndDayForPicker(roundOffDate);
                }}
                responsive={{
                  xsmall: {
                    controls: ["date"],
                    display: "bottom",
                    touchUi: true,
                  },
                  medium: {
                    controls: ["date"],
                    display: "anchored",
                    touchUi: false,
                  },
                }}
              />
            </div>
          </div>
        </div>
        {!activeCalendar.hasFirstAndLastEvents ? (
          <div className="max-w-xl">{renderNoFirstAndLastEventsWarning()}</div>
        ) : (
          <div>
            <RotationDays
              activeRotationScheduleDays={activeRotationScheduleDays}
              setModifiedRotationDays={setModifiedRotationDays}
            />
            <div>
              {submitRotationDaysErrors.length > 0 && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        There were {submitRotationDaysErrors.length} errors with
                        your submission
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                          {submitRotationDaysErrors.map((error, index) => {
                            return <li key={index}>{error}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderEventsTabContent = () => {
    return (
      <div className="w-full py-2">
        <ListOfRotationEvents events={activeCalendar.activeEvents} />
      </div>
    );
  };

  const renderCalendarTabContent = () => {
    if (!activeCalendar.hasFirstAndLastEvents) {
      return renderNoFirstAndLastEventsWarning();
    }

    return null;
  };

  return (
    <React.Fragment>
      <PageLayout
        left={renderRotationCalendarBreadcrumbs()}
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          {/*  */}
          <div className="mt-8 flex">{renderDaysTabContent()}</div>
        </div>

        {/* Create Modals */}
        {showCreateRotationEventModal && (
          <RotationEventCreateAndEditModal
            onClose={() => setShowCreateRotationEventModal(false)}
            calendarId={activeCalendar.id}
            onUpdateAfterSaving={() => {
              setShowCreateRotationEventModal(false);

              addToast("Event created successfully", {
                type: "success",
              });
            }}
          />
        )}
      </PageLayout>
    </React.Fragment>
  );
}

export default observer(RotationCalendar);
