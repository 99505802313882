import { observer } from "mobx-react";
import React, { useState } from "react";
import { colorsMap200 } from "../../../colors-list";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import Block from "../../../models/Block";
import { DashboardBreakpoint } from "../../../types";
import { districtScheduleBlockRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import BlockCreateAndEditModal from "../../CRUD/CreateAndEdit/Block";
import Link from "../../Link";

type BlockItemProps = {
  breakpoint: DashboardBreakpoint;
  block: Block;
};

function BlockItem({ breakpoint, block }: BlockItemProps) {
  const [showEditBlockModal, setShowEditBlockModal] = useState(false);

  const { addToast } = useToastsContext();

  const { districts, sessions, ui } = useStores();

  const { activeSessionId, activeDistrictId } = ui;

  if (!activeSessionId || !activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  const session = sessions.getByUrlParam(activeSessionId);

  if (!district || !session) {
    return null;
  }

  const renderBlockItemLg = () => {
    return (
      <tr key={block.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <Link
            to={districtScheduleBlockRoute(district, session, block)}
            className="mr-3 hover:underline"
          >
            {block.abbreviation}
          </Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {block.description}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <div
            className="h-6 w-6 rounded-full border-2 border-white shadow-md"
            style={{ backgroundColor: colorsMap200[block.color || "blue"] }}
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {block.scheduledOn}
        </td>

        <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
          <Button
            onClick={() => setShowEditBlockModal(true)}
            type="button"
            theme="editor"
            buttonText="Edit"
            padding="medium"
            width="48"
          />
        </td>
      </tr>
    );
  };

  // TBD
  const renderBlockItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditBlockModal && (
        <BlockCreateAndEditModal
          isEditing={true}
          block={block}
          onClose={() => setShowEditBlockModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditBlockModal(false);

            addToast("Block updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderBlockItemLg() : renderBlockItemSm()}
    </React.Fragment>
  );
}

export default observer(BlockItem);
