import React, { useMemo } from "react";
import { sharedCalendarViewToggle } from "../../actions/scheduling";
import useDropdownContext from "../../hooks/useDropdownContext";
import DropdownMenu from "../DropdownMenu";
import DropdownList from "../DropdownMenu/DropdownList";

type DropdownMenuProps = {
  children: React.ReactNode;
};

const SharedCalendarViewDropdown = ({
  children: triggerComponent,
}: DropdownMenuProps) => {
  const context = useDropdownContext();

  const actions = useMemo(() => {
    return [sharedCalendarViewToggle];
  }, [context]);

  return (
    <DropdownMenu asChild={false} triggerComponent={triggerComponent}>
      <DropdownList actions={actions} />
    </DropdownMenu>
  );
};

export default SharedCalendarViewDropdown;
