import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../contexts/modals";
import { useToastsContext } from "../../../contexts/toasts";
import useStores from "../../../hooks/useStores";
import ClassV2 from "../../../models/ClassV2";
import { DashboardBreakpoint } from "../../../types";
import { districtScheduleClassTimeblockingRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import SectionTimeblocking from "../../CRUD/CreateAndEdit/SectionTimeblocking";

type ClassAssignedScheduleItemProps = {
  breakpoint: DashboardBreakpoint;
  classV2: ClassV2;
};

function ClassAssignedScheduleItem({
  breakpoint,
  classV2,
}: ClassAssignedScheduleItemProps) {
  const [showScheduleTimeblockingModal, setShowScheduleTimeblockingModal] =
    useState(false);

  const navigate = useNavigate();

  const { districts, sessions, ui } = useStores();

  const { addToast } = useToastsContext();

  const { addModal } = useModalContext();

  const { activeSessionId, activeDistrictId } = ui;

  if (!activeSessionId || !activeDistrictId) {
    return null;
  }

  const district = districts.getByUrlParam(activeDistrictId);

  const session = sessions.getByUrlParam(activeSessionId);

  if (!district || !session) {
    return null;
  }

  const renderSchedulingDoneStatus = () => {
    const sectionCount = classV2.sectionsCount;
    const sectionSchedulingDone = classV2.sectionSchedulingDone;

    console.log("sectionCount", sectionCount);
    console.log("sectionSchedulingDone", sectionSchedulingDone);

    if (
      (sectionSchedulingDone.total === 0 ||
        sectionSchedulingDone.total === 1) &&
      sectionSchedulingDone.unassigned === 1
    ) {
      return (
        <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
      );
    } else if (sectionSchedulingDone.total === sectionSchedulingDone.assigned) {
      return (
        <CheckCircleIcon
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      );
    } else {
      return (
        <div className="flex items-center">
          <span className="mr-2">
            {sectionSchedulingDone.unassigned}
            <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
          </span>
          <span className="mr-2">
            {sectionSchedulingDone.assigned}
            <CheckCircleIcon
              className="h-6 w-6 text-green-400"
              aria-hidden="true"
            />
          </span>
        </div>
      );
    }
  };

  const renderClassAssignedScheduleItemLg = () => (
    <tr key={classV2.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {classV2.name}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {classV2.rotationScheduleNameForClass}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {renderSchedulingDoneStatus()}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => {
            // addModal({
            //   title:
            //     classV2.sectionsCount > 1
            //       ? "Sections scheduling"
            //       : "Class scheduling",
            //   description:
            //     classV2.sectionsCount > 1
            //       ? "Assign periods/blocks to the sections of this class."
            //       : "Assign periods/blocks to this class.",
            //   content: (
            //     <SectionTimeblocking classV2={classV2} onClose={() => {}} />
            //   ),
            //   size: "full-wd",
            //   onClose: () => {},
            //   // onSubmit: () => {},
            //   // submitText: "Done editing",
            // });

            // setShowScheduleTimeblockingModal(true);

            navigate(
              districtScheduleClassTimeblockingRoute(district, session, classV2)
            );
          }}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderClassAssignedScheduleItemSm = () => <div />;

  return (
    <React.Fragment>
      {breakpoint === "lg"
        ? renderClassAssignedScheduleItemLg()
        : renderClassAssignedScheduleItemSm()}

      {/* Need to render the large modal here */}
      {showScheduleTimeblockingModal && (
        <SectionTimeblocking
          classV2={classV2}
          // onClose={() => {
          //   setShowScheduleTimeblockingModal(false);
          // }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(ClassAssignedScheduleItem);
