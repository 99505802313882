import invariant from "invariant";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import DistrictUser from "../../../models/DistrictUser";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import {
  userGradeLevelDropdownOptions,
  userGradesEnumArrayToDropdownValues,
  userRoleDropdownOptions,
  userRolesEnumArrayToDropdownValues,
} from "../../../utils/gradeLevelsMap";
import {
  EnumUserDistrictRoles,
  EnumUserGradeLevels,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type PeopleFormState = {
  firstName: string;
  lastName: string;
  email: string;
  gradeLevels: DropdownOptionType[];
  districtRoles: DropdownOptionType[];
  [key: string]: string | boolean | any[];
};

const initialState: PeopleFormState = {
  firstName: "",
  lastName: "",
  email: "",
  gradeLevels: [],
  districtRoles: [],
  schools: [],
};

const PEOPLE_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "firstName",
    label: "First Name",
    required: true,
  },
  {
    fieldKind: "text",
    id: "lastName",
    label: "Last Name",
    required: true,
  },
  {
    fieldKind: "text",
    id: "email",
    label: "Email",
    required: true,
  },
  {
    fieldKind: "multiselect",
    id: "districtRoles",
    label: "Roles",
    placeholder: "Select roles",
    required: true,
  },
  {
    fieldKind: "multiselect",
    id: "gradeLevels",
    label: "Grade Levels",
    placeholder: "Select grade levels",
  },
  {
    fieldKind: "dropdown",
    id: "schools",
    label: "School",
    placeholder: "Select the school the session belongs to (optional)",
  },
];

const formReducer: Reducer<PeopleFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type PeopleCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  user?: DistrictUser;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function PeopleCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  user,
  isEditing = false,
}: PeopleCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { districts, schools, ui, users } = useStores();

  const { getSchoolsForDistrict } = schools;

  const { isSaving, save, sendOnboardingEmail } = users;

  console.log("User props", toJS(user));

  const init = (initialState: PeopleFormState) => {
    if (isEditing) {
      invariant(user, "User must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        gradeLevels: user.gradeLevels
          ? userGradesEnumArrayToDropdownValues(user.gradeLevels)
          : [],
        districtRoles: user.districtRoles
          ? userRolesEnumArrayToDropdownValues(user.districtRoles)
          : [],
        schools: user.schools
          ? user.schools.map((school: any) => ({
              label: school.name,
              value: school.id,
            }))
          : [],
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { firstName, lastName, email } = state;

    console.log("Validate submit", state);

    const isEmailValid = (email: string) => {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      return emailRegex.test(email);
    };

    if (firstName && firstName.length > 0) {
      setIsSubmitDisabled(false);
    } else if (lastName && lastName.length > 0) {
      setIsSubmitDisabled(false);
    } else if (email && email.length > 0 && isEmailValid(email)) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);

      default:
        return null;
    }
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "gradeLevels":
        return renderGradeLevelsDropdown(id, field);
      case "districtRoles":
        return renderRolesDropdown(id, field);
      case "schools":
        return renderSchoolDropdown(id, field);
      default:
        return null;
    }
  };

  const renderRolesDropdown = (id: string, field: FormField) => {
    const dropdownOptions = userRoleDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderGradeLevelsDropdown = (id: string, field: FormField) => {
    const dropdownOptions = userGradeLevelDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderSchoolDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const schools = getSchoolsForDistrict(activeDistrict.id);

    console.log("Schools", toJS(schools));

    const options = schools.map((school) => ({
      label: school.name,
      value: school.id,
    }));

    console.log("Options", options);

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={true}
          data={options}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      // Check if district role is undefined
      if (state.districtRoles.length === 0) {
        alert("Please select at least one role");
        return;
      }

      if (isEditing) {
        invariant(user, "User must be defined");

        const updatedUser = {
          id: user.id,
          ...state,
          gradeLevels: state.gradeLevels.map(
            (gradeLevel) => gradeLevel.value as EnumUserGradeLevels
          ),
          districtRoles: state.districtRoles.map(
            (role) => role.value as EnumUserDistrictRoles
          ),
        };

        try {
          const res = await save(updatedUser);
          return onUpdateAfterSaving();
        } catch (err) {}
      } else {
        try {
          invariant(ui.activeDistrictId, "Active district must be defined");

          const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

          invariant(activeDistrict, "Active district must be defined");

          const newUser = {
            ...state,
            gradeLevels: state.gradeLevels.map(
              (gradeLevel) => gradeLevel.value as EnumUserGradeLevels
            ),
            districtRoles: state.districtRoles.map(
              (role) => role.value as EnumUserDistrictRoles
            ),
            district: {
              id: activeDistrict.id,
            },
            isOnboarded: false,
            password: "not-set",
            username:
              state.email.split("@")[0] +
              "-" +
              Math.random().toString(36).substring(7),
            roles: ["user"],
            // schools: [activeSchool.id],
          };

          const res = await save(newUser);

          return onUpdateAfterSaving();
        } catch (err) {}
      }
    },
    [
      isEditing,
      isSaving,
      state,
      user,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Add"} User
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {PEOPLE_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(PeopleCreateAndEditModal);
