import { computed, observable } from "mobx";
import {
  EnumUserDistrictRoles,
  EnumUserGradeLevels,
} from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

class DistrictUser extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  email: string;

  @Attribute
  @observable
  username: string;

  @Attribute
  @observable
  firstName: string;

  @Attribute
  @observable
  middleName: string | null;

  @Attribute
  @observable
  lastName: string;

  @Attribute
  @observable
  avatarUrl: string | null;

  @Attribute
  @observable
  districtRoles: EnumUserDistrictRoles[];

  @Attribute
  @observable
  edlinkId: string | null;

  @Attribute
  @observable
  gradeLevels: EnumUserGradeLevels[] | null;

  @Attribute
  @observable
  districtId: string;

  @observable
  district: any;

  @Attribute
  @observable
  schools: any; // was string [] earlier

  @observable
  schoolsJSON: any;

  @computed
  get fullName(): string {
    return this.firstName + " " + this.lastName;
  }

  @computed
  get schoolNames(): string[] {
    const { schools } = this.store.rootStore;

    let schoolNames: string[] = [];

    this.schools.forEach((schoolId: string) => {
      const school = schools.get(schoolId);

      if (school) {
        schoolNames.push(school.name);
      }
    });

    return schoolNames;
  }

  @computed
  get initial(): string {
    return this.firstName[0];
  }
}

export default DistrictUser;
