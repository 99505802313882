import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import useStores from "../hooks/useStores";

function Class() {
  const { classId = "", schoolId = "", districtId = "" } = useParams();

  const { sections, ui, classes, schools, districts, enrollments, calendars } =
    useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentClass = classes.getByUrlParam(classId);
  const currentSchool = schools.getByUrlParam(schoolId);
  const currentDistrict = districts.getByUrlParam(districtId);

  // Set active district in UI store
  useEffect(() => {
    if (currentClass) {
      ui.setActiveClassId(currentClass.urlId);
    }

    if (currentSchool) {
      ui.setActiveSchoolId(currentSchool.urlId);
    }

    if (currentDistrict) {
      ui.setActiveDistrictId(currentDistrict.urlId);
    }

    setIsLoading(false);
    return () => {
      ui.setActiveClassId(undefined);
    };
  }, [currentClass, ui, currentSchool, currentDistrict]);

  // Fetch all school related data
  useEffect(() => {
    if (currentClass) {
      sections.fetchClassSections(currentClass.id);
      enrollments.fetchEnrollmentsForClass(currentClass.id);
      calendars.fetchCalendarsForClass(currentClass.id);
    }
  }, [currentClass]);

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return <Outlet />;
}

export default observer(Class);
