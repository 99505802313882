import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import useStores from "../hooks/useStores";

function Session() {
  const { sessionId = "" } = useParams();

  const { calendars, classes, rotationSchedules, sessions, ui } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentSession = sessions.getByUrlParam(sessionId);

  // Set active district in UI store
  useEffect(() => {
    if (currentSession) {
      ui.setActiveSessionId(currentSession.urlId);
    }

    setIsLoading(false);

    return () => {
      ui.setActiveSessionId(undefined);
    };
  }, [currentSession, ui]);

  // Fetch all session related data
  useEffect(() => {
    console.log("Current session", currentSession);

    if (currentSession) {
      rotationSchedules.fetchSessionRotationSchedules(currentSession.id);
      calendars.fetchSessionRotationCalendars(currentSession.id);
      classes.fetchSessionClasses(currentSession.id);
    }
  }, [currentSession, rotationSchedules, calendars, classes]);

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return <Outlet />;
}

export default observer(Session);
