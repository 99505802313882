import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import {
  CreateSession,
  UpdateSession,
} from "../graphql/sessions/sessions.mutations";
import { GetSession, GetSessions } from "../graphql/sessions/sessions.queries";
import Session from "../models/Session";
import {
  SessionCreateInput,
  SessionUpdateInput,
  SessionWhereUniqueInput,
  SortOrder,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class SessionsStore extends BaseStore<Session> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Session, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @observable
  activeSessionRotationId: string | null = null;

  @action
  fetch = async (id: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSession,
          variables: {
            where: {
              id,
            },
          },
        })
        .then((res) => {
          const session = res.data.session;

          invariant(session, "Session must exist");
          invariant(session.district, "Session must have a district");
          invariant(session.sessionType, "Session must have a sessionType");
          invariant(session.state, "Session must have a state");

          const sanitizeSession = {
            createdAt: session.createdAt,
            deletedAt: session.deletedAt,
            districtId: session.district.id,
            edlinkId: session.edlinkId,
            endDate: session.endDate,
            id: session.id,
            identifiers: session.identifiers,
            name: session.name,
            properties: session.properties,
            schoolId: session.school ? session.school.id : null,
            sessionType: session.sessionType,
            startDate: session.startDate,
            state: session.state,
            updatedAt: session.updatedAt,
            urlId: session.urlId,
          };

          this.add(sanitizeSession);

          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  // READ
  @action
  fetchDistrictSessions = (districtId: string) => {
    this.isLoading = true;

    const { ui } = this.rootStore;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetSessions,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const sessions = res.data.sessions;

          if (!sessions) {
            reject(false);
          }

          runInAction("Populate districts", () => {
            sessions.forEach((session, index) => {
              console.log(session);

              invariant(session.district, "Session must have a district");
              invariant(session.sessionType, "Session must have a sessionType");
              invariant(session.state, "Session must have a state");

              // if (index === 0) {
              //   console.log("Setting active session id", session.urlId);
              //   ui.setActiveSessionId(session.urlId);
              // }

              const sanitizeSession = {
                createdAt: session.createdAt,
                deletedAt: session.deletedAt,
                districtId: session.district.id,
                edlinkId: session.edlinkId,
                endDate: session.endDate,
                id: session.id,
                identifiers: session.identifiers,
                name: session.name,
                properties: session.properties,
                schoolId: session.school ? session.school.id : null,
                sessionType: session.sessionType,
                startDate: session.startDate,
                state: session.state,
                updatedAt: session.updatedAt,
                urlId: session.urlId,
              };

              this.add(sanitizeSession);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  // CREATE AND EDIT
  // Function to save the course to the database
  @action
  save = async (args: Partial<Session>): Promise<Session> => {
    const { newlyCreated, id, ...rest } = args;

    this.isSaving = true;

    try {
      if (!id || newlyCreated) {
        return this.create(rest as SessionCreateInput);
      } else {
        return this.update(
          rest as SessionUpdateInput,
          { id } as SessionWhereUniqueInput
        );
      }
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  @action
  async create(data: SessionCreateInput): Promise<Session> {
    const res = await this.apolloClient.mutate({
      mutation: CreateSession,
      variables: {
        data,
      },
    });

    if (!res.data || !res.data.createSession) {
      throw Error("Failed to create session.");
    }

    const session = res.data.createSession;

    invariant(session.district, "Session must have a district");
    invariant(session.sessionType, "Session must have a sessionType");
    invariant(session.state, "Session must have a state");

    const sanitizeSession = {
      createdAt: session.createdAt,
      deletedAt: session.deletedAt,
      districtId: session.district.id,
      edlinkId: session.edlinkId,
      endDate: session.endDate,
      id: session.id,
      identifiers: session.identifiers,
      name: session.name,
      properties: session.properties,
      schoolId: session.school ? session.school.id : null,
      sessionType: session.sessionType,
      startDate: session.startDate,
      state: session.state,
      updatedAt: session.updatedAt,
      urlId: session.urlId,
    };

    return this.add(sanitizeSession);
  }

  async update(
    data: SessionUpdateInput,
    where: SessionWhereUniqueInput
  ): Promise<Session> {
    const res = await this.apolloClient.mutate({
      mutation: UpdateSession,
      variables: {
        data,
        where,
      },
    });

    if (!res.data || !res.data.updateSession) {
      throw Error("Failed to update session.");
    }

    const session = res.data.updateSession;

    invariant(session.district, "Session must have a district");
    invariant(session.sessionType, "Session must have a sessionType");
    invariant(session.state, "Session must have a state");

    const sanitizeSession = {
      createdAt: session.createdAt,
      deletedAt: session.deletedAt,
      districtId: session.district.id,
      edlinkId: session.edlinkId,
      endDate: session.endDate,
      id: session.id,
      identifiers: session.identifiers,
      name: session.name,
      properties: session.properties,
      schoolId: session.school ? session.school.id : null,
      sessionType: session.sessionType,
      startDate: session.startDate,
      state: session.state,
      updatedAt: session.updatedAt,
      urlId: session.urlId,
    };

    return this.add(sanitizeSession);
  }

  getByUrlParam = (urlId: string): Session | undefined => {
    return this.sortedData.find((session) => urlId.endsWith(session.urlId));
  };

  getSessionsForDistrict = (districtId: string): Session[] => {
    return this.sortedData.filter(
      (session) => session.districtId === districtId
    );
  };

  @action
  setActiveSessionRotationId = (id: string | null) => {
    this.activeSessionRotationId = id;
  };

  getSessionsForSchool = (districtId: string, schoolId: string): Session[] => {
    // First we filter by district
    const sessionsForDistrict = this.getSessionsForDistrict(districtId);

    // Next we filter by school or null
    return sessionsForDistrict.filter(
      (session) =>
        (session.schoolId === schoolId || session.schoolId === null) &&
        !session.deletedAt
    );
  };
}
