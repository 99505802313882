type AvatarProps = {
  userData: {
    id: string;
    name: string;
    initial: string;
    color: string;
    avatarUrl?: string | null;
  };
  size: number;
  border?: boolean;
};

export default function Avatar({ userData, size, border }: AvatarProps) {
  const fontSize = size / 2;
  const borderStyle = !border
    ? "border-none"
    : "border-2 border-current bg-current";

  if (userData.avatarUrl) {
    return (
      <img
        src={userData.avatarUrl}
        alt={userData.name}
        className={`rounded-full ${borderStyle}`}
        style={{
          border: !border ? "none" : "2px solid var(--slate7)",
          width: size,
          height: size,
        }}
      />
    );
  }

  return (
    <div
      className={`flex items-center justify-center rounded-full text-white  text-${fontSize} font-semibold ${borderStyle} `}
      style={{
        backgroundColor: userData.color,
        border: !border ? "none" : "2px solid var(--slate7)",
        width: size,
        height: size,
      }}
    >
      {userData.initial}
    </div>
  );
}
