// src/components/ErrorBoundary.tsx
import React, { Component, ReactNode } from "react";
import ButtonLarge from "../components/ButtonLarge";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
          <div className="text-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Something went wrong.
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Sorry, we encountered an error and cannot load the page.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <ButtonLarge
                type="button"
                buttonText="Go back home"
                onClick={() => {}}
                theme="primary"
              />
              <ButtonLarge
                type="button"
                buttonText="Contact support"
                onClick={() => {
                  // @ts-ignore
                  window!.Intercom("show");
                }}
                theme="secondary"
              />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
