import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import useStores from "../hooks/useStores";

function School() {
  const { schoolId = "" } = useParams();

  const { classes, ui, schools } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentSchool = schools.getByUrlParam(schoolId);

  // Set active district in UI store
  useEffect(() => {
    if (currentSchool) {
      ui.setActiveSchoolId(currentSchool.urlId);
    }

    setIsLoading(false);

    return () => {
      ui.setActiveSchoolId(undefined);
    };
  }, [currentSchool, ui]);

  // Fetch all school related data
  useEffect(() => {
    if (currentSchool) {
      classes.fetchSchoolClasses(currentSchool.id);
      //   sections.fetchDistrictDepartments(currentSchool.id);
      //   enrollments.fetchDistrictSubjects(currentSchool.id);
    }
  }, [currentSchool]);

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return <Outlet />;
}

export default observer(School);
