import { gql } from "../../__generated__/gql";

export const GetResources = gql(`
    query GetResources($where: ResourceWhereInput!, $orderBy: [ResourceOrderByInput!], $take: Float, $skip: Float) {
        resources(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
            archivedAt
            content
            course {
                id
            }
            createdAt
            deletedAt
            editorVersion
            id
            isOnboarding
            isTemplate
            lastModifiedBy {
                id
            }
            publishedAt
            publishedBy {
                id
            }
            resourceType
            template {
                id
            }
            drive {
                id
                driveType
                district {
                    id
                    name
                }
                classV2 {
                    id
                    name
                }
                courseV2 {
                    id
                    name
                }
            }
            parents {
                id
                title
            }
            planner {
                id
            }
            title
            updatedAt
            urlId
            usedAsTemplate {
                urlId
            }
            user {
                id
                avatarUrl
                firstName
                lastName
                email
            }
            version
            schedule
            standards
            units
            tracking
            thumbnail
            status
            attributes
            canEdit
            canMoveWithinDrive
            canMoveOutsideDrive
            canTrash
        }
    }
`);

export const GetResourcesMeta = gql(`
    query GetResourcesMeta($where: ResourceWhereInput!) {
        _resourcesMeta(where: $where) {
            count
        }
    }
`);
