import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import DistrictCreateAndEditModal from "../components/CRUD/CreateAndEdit/District";
import ListOfDistricts from "../components/Districts/ListOfDistricts";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";
import { client } from "../utils/HttpClient";

function Districts() {
  const { districts } = useStores();
  const { sortedData } = districts;

  const [showCreateDistrictModal, setShowCreateDistrictModal] = useState(false);

  const { addToast } = useToastsContext();

  const edlinkSync = async () => {
    const res = await client.get("/edlink/activeIntegrations", {});

    console.log("Res", res);
  };

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="secondary"
        className="mr-3"
        icon="sync"
        buttonText="Sync Edlink data"
        padding="medium"
        rounded="medium"
        onClick={() => {
          // Perform full syncs
          edlinkSync();
        }}
      />
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateDistrictModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        title="Districts"
        left={
          <div>
            <h2 className="text-xl font-semibold">Districts</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfDistricts key={sortedData.length} districts={sortedData} />
        </div>
      </PageLayout>
      {showCreateDistrictModal && (
        <DistrictCreateAndEditModal
          onClose={() => setShowCreateDistrictModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateDistrictModal(false);

            addToast("District created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Districts);
