import { gql } from "../../__generated__/gql";

export const GetBotConversations = gql(`
  query GetBotConversations(
    $where: BotConversationWhereInput!,
    $orderBy: [BotConversationOrderByInput!],
    $skip: Float,
    $take: Float
  ) {
    botConversations(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
      conversationEntries{
        createdAt
        deletedAt
        entry
        id
        speaker
        updatedAt
        user {
          id
          firstName
          lastName
          avatarUrl
          email
        }
      }
      createdAt                
      deletedAt                 
      id                         
      title                     
      updatedAt                
      urlId                     
      botConversationParticipants {
        createdAt
        deletedAt
        id
        updatedAt
        owner 
        permissions
        user {
          id
          firstName
          lastName
          avatarUrl
          email
        }
      }   
    }
  }
`);

export const GetBotConversationsMeta = gql(`
  query GetBotConversationsMeta(
    $where: BotConversationWhereInput!
  ) {
    _botConversationsMeta(where: $where) {
      count
    }
  }
`);
