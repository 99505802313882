import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import District from "../../../models/District";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import { generateRandomString } from "../../../utils/RandomString";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import TimeZoneDropdown from "../../Dropdown/TimezoneDropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import Switch from "../../Switch";

enum EnumSubscriptionType {
  Slides = "Slides",
  Copilot = "Copilot",
  CopilotSlidesBundle = "CopilotSlidesBundle",
}

const subscriptionTypeToStringMap: {
  [key in EnumSubscriptionType]: string;
} = {
  [EnumSubscriptionType.Slides]: "Slides",
  [EnumSubscriptionType.Copilot]: "Copilot",
  [EnumSubscriptionType.CopilotSlidesBundle]: "Copilot Slides Bundle",
};

type DistrictFormState = {
  name: string;
  timezone: string | null | undefined;
  seats?: string;
  ssoAvailable?: boolean;
  subscriptionType?: DropdownOptionType | null;
  enterprisePlan?: boolean;
  [key: string]:
    | string
    | boolean
    | any[]
    | null
    | undefined
    | DropdownOptionType;
};

const initialState: DistrictFormState = {
  name: "",
  timezone: null,
  seats: "0",
  subscriptionType: null,
  enterprisePlan: false,
};

const DISTRICT_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "District Name",
    placeholder: "Eg. Los Angeles Unified School District",
    required: true,
  },
  {
    fieldKind: "dropdown",
    id: "timezone",
    label: "Timezone",
  },
  {
    fieldKind: "switch",
    id: "ssoAvailable",
    label: "SSO Available",
  },
  {
    fieldKind: "text",
    id: "seats",
    label: "Number of Seats",
  },
  {
    fieldKind: "dropdown",
    id: "subscriptionType",
    label: "Subscription Type",
  },
  {
    fieldKind: "switch",
    id: "enterprisePlan",
    label: "Enterprise Plan",
  },
];

const formReducer: Reducer<DistrictFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type DistrictCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  district?: District;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function DistrictCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  district,
  isEditing = false,
}: DistrictCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { districts } = useStores();

  const { isSaving, save } = districts;

  const init = (initialState: DistrictFormState) => {
    if (isEditing) {
      invariant(district, "District must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: district.name,
        timezone: district.timezone,
        seats:
          district.payments && district.payments.numberOfSeats
            ? district.payments.numberOfSeats.toString()
            : "0",
        ssoAvailable:
          district.edlinkIntegrations && district.edlinkIntegrations[0]
            ? district.edlinkIntegrations[0].ssoAvailable
            : false,
        subscriptionType: district.subscriptionType
          ? subscriptionTypeEnumArrayToDropdownValue(district.subscriptionType)
          : null,
        enterprisePlan: district.payments?.enterprisePlan || false,
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name, seats } = state;

    console.log("Validate submit", state);

    if (name && name.length > 0) {
      setIsSubmitDisabled(false);
    } else if (
      !seats ||
      seats === "" ||
      Number.isNaN(Number(seats)) ||
      Number(seats) < 0 ||
      !state.subscriptionType
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "switch":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={field.label}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: value,
                });
              }}
              toggleValue={state[id] as boolean}
            />
          </div>
        );
      case "dropdown":
        return renderDropdown(id, field);
      default:
        return null;
    }
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "timezone":
        return (
          <TimeZoneDropdown
            id={id}
            label={field.label}
            value={state[id]}
            onChange={(value) => {
              dispatchFormAction({
                type: "dropdown",
                fieldID: id,
                payload: value,
              });
            }}
          />
        );
      case "subscriptionType":
        return renderSubscriptionTypeDropdown(id, field);
      default:
        return null;
    }
  };

  // USER GRADE LEVELS

  function subscriptionTypeDropdownOptions(): {
    label: string;
    value: string;
  }[] {
    return Object.keys(subscriptionTypeToStringMap).map((key) => {
      return {
        label: subscriptionTypeToStringMap[key as EnumSubscriptionType],
        value: key,
      };
    });
  }

  function subscriptionTypeEnumArrayToDropdownValue(
    enumArray: EnumSubscriptionType
  ): { label: string; value: string } {
    return {
      label: subscriptionTypeToStringMap[enumArray],
      value: enumArray,
    };
  }

  const renderSubscriptionTypeDropdown = (id: string, field: FormField) => {
    const dropdownOptions = subscriptionTypeDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={false}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (isEditing) {
        invariant(district, "District must be defined if editing");

        const seats = state.seats;
        if (
          !seats ||
          seats === "" ||
          Number.isNaN(Number(seats)) ||
          Number(seats) < 0
        ) {
          invariant(seats, "Seats must be defined if editing");
        }

        if (!state.subscriptionType) {
          invariant(
            state.subscriptionType,
            "Subscription type must be defined if editing"
          );
        }

        const payments = district.payments || {};

        // update number of seats in the payments object but if the active users is more than number of seats then splice that first
        const activeUsers = payments.activeUsers || [];

        const numberOfSeats = Number(seats);

        const updatedActiveUsers = activeUsers.slice(0, numberOfSeats);

        const updatedPayments = {
          ...payments,
          numberOfSeats,
          activeUsers: updatedActiveUsers,
          status: numberOfSeats > 0 ? "paid" : "cancelled",
          enterprisePlan: state.enterprisePlan,
          subscriptionType: state.subscriptionType.value,
        };

        const updatedDistrict = {
          id: district.id,
          ...state,
          payments: updatedPayments,
        };

        delete updatedDistrict.seats;
        delete updatedDistrict.ssoAvailable;
        delete updatedDistrict.subscriptionType;
        delete updatedDistrict.enterprisePlan;

        try {
          const res = await save(updatedDistrict);

          const edlinkIntegrationToUpdate = district.edlinkIntegrations
            ? district.edlinkIntegrations[0]
            : null;

          console.log(
            "Edlink Integration to update",
            edlinkIntegrationToUpdate
          );

          if (edlinkIntegrationToUpdate) {
            // Update SSO available for Edlink Integration
            const updateSSO = await districts.updateEdlinkIntegration(
              {
                ssoAvailable: state.ssoAvailable,
              },
              {
                id: edlinkIntegrationToUpdate.id,
              }
            );

            console.log("Updated district", res);
          }

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating district", err);
        }
      } else {
        try {
          if (!state.subscriptionType) {
            invariant(
              state.subscriptionType,
              "Subscription type must be defined if creating"
            );
          }

          const seats = state.seats ? Number(state.seats) : 0;

          const payments = {
            numberOfSeats: seats,
            status: seats && seats > 0 ? "paid" : "cancelled",
            enterprisePlan: state.enterprisePlan,
            subscriptionType: state.subscriptionType.value,
          };

          // Remove unnecessary fields from
          const createDistrictPayload = {
            ...state,
            payments,
          };

          delete createDistrictPayload.seats;
          delete createDistrictPayload.subscriptionType;
          delete createDistrictPayload.enterprisePlan;

          const res = await save({
            ...createDistrictPayload,
            urlId: generateRandomString(),
            edlinkId: generateRandomString(),
          });

          console.log("Created district", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating district", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      district,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} District
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {DISTRICT_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(DistrictCreateAndEditModal);
