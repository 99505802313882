// import * as Sentry from "@sentry/react";
import { ApolloClient } from "@apollo/client";
import { action, autorun, computed, observable, runInAction } from "mobx";
import RootStore from "./RootStore";

import {
  LoginAlaynaPortal,
  SignUpAlaynaPortal,
} from "../graphql/auth/auth.mutations";
// import { UpdateUserDetails } from "../graphql/user/user.mutations";
import invariant from "invariant";
import { omit } from "lodash";
import { GetAuthUser } from "../graphql/auth/auth.queries";
import Ability from "../models/Ability";
import User from "../models/User";
import LocalStorage from "../utils/LocalStorage";
// import { EnumUserUserRole } from "../__generated__/graphql";

export type AuthResponse = {
  success: boolean;
  error?: string | null;
};

const STORE_NAME = "AUTH";
const NON_REDIRECT_PATHS = ["/", "/dashboard", "/home", "/logout"];

type LocalLocalStorageData = {
  token?: string;
  userId?: string;
  user?: User;
  // org?: Organization;
  abilities?: Ability[];
};

type UserDetails = {
  firstName: string;
  lastName: string;
  email: string;
  userRole: string;
  avatarUr?: string;
};

export default class AuthStore {
  @observable
  user?: User | null;

  //   @observable
  //   org?: Organization | null;

  @observable
  token?: string | null;

  @observable
  userId?: string | null;

  @observable
  abilities: Ability[] = [];

  @observable
  saving = false;

  @observable
  error: string | null;

  @observable
  isSuspended = false;

  rootStore: RootStore;

  apolloClient: ApolloClient<any>;

  @observable
  loginError: string | null;

  @observable
  signupError: string | null;

  @observable
  resetPasswordError: string | null;

  @observable
  isLoggingIn: boolean = false;

  @observable
  isSigningUp: boolean = false;

  @observable
  isResettingPassword: boolean = false;

  // We will essentially use this to force fetch auth on every refresh (isInitialized)
  @observable
  isLoading: boolean = false;

  @observable
  isInitialized: boolean = false;

  @observable
  isUpdatingPassword: boolean = false;

  @observable
  updatePasswordError: string | null;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;

    const data: LocalLocalStorageData = LocalStorage.retrieve(STORE_NAME) || {};

    this.rehydrateStore(data);

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });

    // write a mobx reaction to listen to userId and call fetch
    autorun(() => {
      if (this.userId && this.isInitialized) {
        this.fetch();
      }
    });

    window.addEventListener("storage", (event) => {
      if (event.key === STORE_NAME && event.newValue) {
        const data: LocalLocalStorageData | null | undefined = JSON.parse(
          event.newValue
        );
        // data may be null if key is deleted in localLocalStorage
        if (!data) {
          return;
        }

        if (this.validSession) {
          if (data.userId === null) {
            this.logout();
          }
        } else {
          this.rehydrateStore(data);
        }
      }
    });
  }

  @action
  rehydrateStore(data: LocalLocalStorageData) {
    this.token = data.token;
    this.userId = data.userId;
    this.user = data.user ? new User(data.user, this) : undefined;

    this.addAbilities(data.abilities);

    if (this.token && this.userId) {
      //   setTimeout(() => this.fetch(), 0);
    }

    this.isInitialized = true;
  }

  addAbilities(abilities?: Ability[]) {
    if (abilities) {
      // We persist user abilities
      this.abilities = abilities;
      abilities.forEach((ability) => this.rootStore.abilities.add(ability));
    }
  }

  @computed
  get validSession(): boolean {
    return !!this.token;
  }

  @computed
  get toLocalLocalStorageData() {
    return {
      token: this.token,
      userId: this.userId,
      // user: this.user,
      abilities: this.abilities,
    };
  }

  @action
  fetch = async (): Promise<User | null> => {
    // Fetch the user data
    this.isLoading = true;

    try {
      invariant(
        this.userId,
        "Trying to fetch without Authenticated User without id initiated"
      );

      const { data, error } = await this.apolloClient.query({
        query: GetAuthUser,
        variables: {
          where: {
            id: this.userId,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      invariant(data, "Could not fetch authenticated user.");

      runInAction(async () => {
        // Add the abilities we fetch for the user using this.addAbilities
        const { user } = data;

        // Save user without __typename
        if (this.user) {
          this.user.updateFromJson(omit(user, "__typename"));
        } else {
          this.user = new User(omit(user, "__typename"), this);
        }

        // If we came from a redirect then send the user immediately there
        const redirectAfterLogin: string | null = await LocalStorage.retrieve(
          "redirectAfterLogin"
        );

        if (redirectAfterLogin) {
          await LocalStorage.delete("redirectAfterLogin");

          if (!NON_REDIRECT_PATHS.includes(redirectAfterLogin)) {
            window.location.href = redirectAfterLogin;
          }
        }
      });

      return this.user || null;
    } catch (err: any) {
      // Check if user has been suspended
      console.log("Err inside ", err);
      // Else set error
      this.error = err.message;

      return this.user || null;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  clearAuthErrors = () => {
    this.resetPasswordError = null;
    this.loginError = null;
    this.signupError = null;
  };

  //   // Login and set the userId and token
  @action
  loginUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isLoggingIn = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: LoginAlaynaPortal,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.loginAlaynaPortal) {
            const { accessToken, user } = res.data.loginAlaynaPortal;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  // Now write the same action for signUpWithEmail
  @action
  signupUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isSigningUp = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: SignUpAlaynaPortal,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.signUpAlaynaPortal) {
            const { accessToken, user } = res.data.signUpAlaynaPortal;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.signupError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.signupError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isSigningUp = false;
          });
        });
    });
  };

  //   // Reset password

  //   @action
  //   resetPasswordWithEmail = async (email: string): Promise<AuthResponse> => {
  //     this.isResettingPassword = true;
  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       this.apolloClient
  //         .mutate({
  //           mutation: ResetPasswordWithEmailId,
  //           variables: {
  //             email,
  //           },
  //         })
  //         .then((res) => {
  //           if (res.data && res.data.resetPasswordWithEmail) {
  //             const success = res.data.resetPasswordWithEmail;

  //             if (success) {
  //               resolve({
  //                 success: true,
  //               });
  //             } else {
  //               resolve({
  //                 success: false,
  //               });
  //             }
  //           } else {
  //             runInAction(() => {
  //               this.resetPasswordError = "Something went wrong. Try again.";
  //             });
  //             resolve({
  //               success: false,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           runInAction(() => {
  //             this.resetPasswordError = err.message
  //               ? err.message
  //               : "Something went wrong. Try again.";
  //           });

  //           resolve({
  //             success: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         })
  //         .finally(() => {
  //           runInAction(() => {
  //             this.isResettingPassword = false;
  //           });
  //         });
  //     });
  //   };

  //   // Login with Google
  //   @action
  //   loginWithGoogle = async (location: Location): Promise<AuthResponse> => {
  //     this.isLoggingIn = true;
  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/google/callback`;
  //       const endpoint = url + location.search;

  //       fetch(endpoint, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           if (data.user && !data.error) {
  //             const { accessToken, user } = data;

  //             if (user) {
  //               runInAction(() => {
  //                 this.userId = user.id;
  //                 this.token = accessToken;
  //               });
  //               resolve({
  //                 success: true,
  //               });
  //             } else {
  //               resolve({
  //                 success: false,
  //               });
  //             }
  //           } else {
  //             resolve({
  //               success: false,
  //               error: data.message,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           runInAction(() => {
  //             this.loginError = err.message
  //               ? err.message
  //               : "Something went wrong. Try again.";
  //           });
  //           resolve({
  //             success: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         })
  //         .finally(() => {
  //           runInAction(() => {
  //             this.isLoggingIn = false;
  //           });
  //         });
  //     });
  //   };

  //   // Login with Microsoft copy paste from google
  //   @action
  //   loginWithMicrosoft = async (location: Location): Promise<AuthResponse> => {
  //     this.isLoggingIn = true;
  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/microsoft/callback`;
  //       const endpoint = url + location.search;

  //       fetch(endpoint, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           if (data.user && !data.error) {
  //             const { accessToken, user } = data;

  //             if (user) {
  //               runInAction(() => {
  //                 this.userId = user.id;
  //                 this.token = accessToken;
  //               });
  //               resolve({
  //                 success: true,
  //               });
  //             } else {
  //               resolve({
  //                 success: false,
  //               });
  //             }
  //           } else {
  //             resolve({
  //               success: false,
  //               error: data.message,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           runInAction(() => {
  //             this.loginError = err.message
  //               ? err.message
  //               : "Something went wrong. Try again.";
  //           });
  //           resolve({
  //             success: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         })
  //         .finally(() => {
  //           runInAction(() => {
  //             this.isLoggingIn = false;
  //           });
  //         });
  //     });
  //   };

  //   @action
  //   updatePassword = async (
  //     token: string,
  //     password: string
  //   ): Promise<AuthResponse> => {
  //     this.isUpdatingPassword = true;

  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       this.apolloClient
  //         .mutate({
  //           mutation: UpdatePasswordWithEmailId,
  //           variables: {
  //             credentials: {
  //               token,
  //               password,
  //             },
  //           },
  //         })
  //         .then((res) => {
  //           if (res.data && res.data.updatePassword) {
  //             const success = res.data.updatePassword;

  //             if (success) {
  //               resolve({
  //                 success: true,
  //               });
  //             } else {
  //               resolve({
  //                 success: false,
  //               });
  //             }
  //           } else {
  //             runInAction(() => {
  //               this.updatePasswordError = "Something went wrong. Try again.";
  //             });
  //             resolve({
  //               success: false,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           runInAction(() => {
  //             this.updatePasswordError = err.message
  //               ? err.message
  //               : "Something went wrong. Try again.";
  //           });

  //           resolve({
  //             success: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         })
  //         .finally(() => {
  //           runInAction(() => {
  //             this.isUpdatingPassword = false;
  //           });
  //         });
  //     });
  //   };

  //   @action
  //   setNewPassword = async (
  //     currentPassword: string,
  //     newPassword: string
  //   ): Promise<AuthResponse> => {
  //     this.isUpdatingPassword = true;

  //     const email = this.user && this.user.email ? this.user.email : "";
  //     if (!email) {
  //       return Promise.resolve({ success: false, error: "No email found" });
  //     }

  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       this.apolloClient
  //         .mutate({
  //           mutation: SetNewPassword,
  //           variables: {
  //             passwordInput: {
  //               email,
  //               currentPassword,
  //               newPassword,
  //             },
  //           },
  //         })
  //         .then((res) => {
  //           if (res.data && res.data.setNewPassword) {
  //             const success = res.data.setNewPassword;

  //             if (success) {
  //               resolve({
  //                 success: true,
  //               });
  //             } else {
  //               resolve({
  //                 success: false,
  //               });
  //             }
  //           } else {
  //             runInAction(() => {
  //               this.updatePasswordError = "Something went wrong. Try again.";
  //             });
  //             resolve({
  //               success: false,
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           runInAction(() => {
  //             this.updatePasswordError = err.message
  //               ? err.message
  //               : "Something went wrong. Try again.";
  //           });

  //           resolve({
  //             success: false,
  //             error: "Something went wrong. Try again.",
  //           });
  //         })
  //         .finally(() => {
  //           runInAction(() => {
  //             this.isUpdatingPassword = false;
  //           });
  //         });
  //     });
  //   };

  //   // Helper function to check reset password token validity since tokens expire
  //   checkUpdatePasswordPageValidity = async (
  //     token: string
  //   ): Promise<AuthResponse> => {
  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       this.apolloClient
  //         .query({
  //           query: CheckUpdatePasswordTokenValidity,
  //           variables: {
  //             token,
  //           },
  //         })
  //         .then((res: any) => {
  //           if (res.data && res.data.checkUpdatePasswordPageValidity) {
  //             resolve({
  //               success: res.data.checkUpdatePasswordPageValidity ? true : false,
  //             });
  //           } else {
  //             resolve({
  //               success: false,
  //             });
  //           }
  //         })
  //         .catch((e) => {
  //           resolve({
  //             success: false,
  //           });
  //         });
  //     });
  //   };

  //   @action
  //   updateUserDetails = async (user: UserDetails): Promise<AuthResponse> => {
  //     const id = this.user && this.user.id ? this.user.id : "";
  //     if (!id) {
  //       return Promise.resolve({ success: false, error: "No id found" });
  //     }

  //     return new Promise<AuthResponse>((resolve, reject) => {
  //       this.apolloClient
  //         .mutate({
  //           mutation: UpdateUserDetails,
  //           variables: {
  //             userDetailsInput: {
  //               ...user,
  //               id,
  //               userRole: user.userRole as EnumUserUserRole,
  //             },
  //           },
  //         })
  //         .then((res: any) => {
  //           if (res.data && res.data.updateUserDetails) {
  //             // update locally also...
  //             this.updateUserParams({
  //               ...user,
  //             });
  //             resolve({
  //               success: res.data.updateUserDetails,
  //             });
  //           } else {
  //             resolve({
  //               success: false,
  //             });
  //           }
  //         })
  //         .catch((e) => {
  //           resolve({
  //             success: false,
  //           });
  //         });
  //     });
  //   };

  @action
  updateUserParams = (params: any) => {
    this.user = { ...this.user, ...params };
  };

  @action
  logout = async (saveRoute = false) => {
    // if this logout was forced from an protected route then
    // save the current path so we can go back there once signed in
    if (saveRoute) {
      const pathName = window.location.pathname;

      if (!NON_REDIRECT_PATHS.includes(pathName)) {
        LocalStorage.save("redirectAfterLogin", pathName);
      }
    }

    // If there is no auth token stored there is nothing else to do
    if (!this.validSession) {
      return;
    }

    // clear all credentials from cache (and local storage via autorun)
    runInAction(() => {
      this.user = null;
      this.userId = null;
      this.token = null;
      this.abilities = [];

      // remove google calendar events

      // Clear all other data
      this.rootStore.logout();
    });
  };
}
