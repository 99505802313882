import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import "./styles/markdown/github-markdown-light.css";
import "./styles/tiptap-editor.css";

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

import "./mobiscrollCustom.css";
import "./styles/globals.css";
import "./styles/mobiscroll.css";
import "./styles/slate.css";
import "./styles/themes/base.css";
import "./styles/themes/dark-theme.css";
import "./styles/themes/light-theme.css";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LocalStorage from "./utils/LocalStorage";

import createStores from "./stores";

import {
  AuthorizationError,
  BadGatewayError,
  BadRequestError,
  ForbiddenError,
  InternalServerError,
  NetworkError,
  NotFoundError,
  OfflineError,
  RateLimitExceededError,
  RequestError,
  ServiceUnavailableError,
} from "./utils/errors";

import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Provider as MobxProvider } from "mobx-react";
import { Spinner } from "./components/LoadingIndicators/Spinner";
import { ModalProvider } from "./contexts/modals";
import { ToastProvider } from "./contexts/toasts";
import AlternateDays from "./scenes/AlternateDays";
import AlternateSchedule from "./scenes/AlternateSchedule";
import Auth from "./scenes/Auth";
import Authenticated from "./scenes/Authenticated";
import Blocks from "./scenes/Blocks";
import BlockSchedule from "./scenes/BlockSchedule";
import Calendar from "./scenes/Calendar";
import Chats from "./scenes/Chats";
import Class from "./scenes/Class";
import ClassCalendar from "./scenes/ClassCalendar";
import Classes from "./scenes/Classes";
import ClassSchedules from "./scenes/ClassSchedules";
import ClassTimeblocking from "./scenes/ClassTimeblocking";
import Courses from "./scenes/Courses";
import Departments from "./scenes/Departments";
import District from "./scenes/District";
import Districts from "./scenes/Districts";
import DistrictUsers from "./scenes/DistrictUsers";
import Documents from "./scenes/Documents";
import Enrollments from "./scenes/Enrollments";
import ErrorBoundary from "./scenes/ErrorBoundary";
import Home from "./scenes/Home";
import Layout from "./scenes/Layout";
import Resources from "./scenes/Resources";
import RotationCalendar from "./scenes/RotationCalendar";
import RotationSchedules from "./scenes/RotationSchedules";
import Scheduling from "./scenes/Scheduling";
import School from "./scenes/School";
import Schools from "./scenes/Schools";
import Sections from "./scenes/Sections";
import Session from "./scenes/Session";
import SharedCalendar from "./scenes/SharedCalendar";
import Subjects from "./scenes/Subjects";
import Tools from "./scenes/Tools";
import Users from "./scenes/Users";

const withToken = setContext(async () => {
  // Get token from LocalStorage.retrieve('AUTH')
  const store = await LocalStorage.retrieve("AUTH");

  let token;

  if (store && store.token) {
    token = store.token;
  }

  return {
    token: token || null,
  };
});

const authLink = new ApolloLink((operation, forward) => {
  const { token } = operation.getContext();

  if (operation.getContext().clientName === "standards") {
    // STANDARDS API (PROD)
    operation.setContext(({ headers }: any) => ({
      headers: {
        ...headers,
        authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjbGZuYWozcHcwMDAwa3M2bzhwZWphMDZ0IiwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTY4NjEwNDk1OCwiZXhwIjoxNjkxMjg4OTU4fQ.lH3i1ic-jJAFs2QHJQZyn6qzYeN-S7iTVgVWfirTxOI",
      },
    }));

    // STANDARDS API (DEV)
    // operation.setContext(({ headers }: any) => ({
    //   headers: {
    //     ...headers,
    //     authorization:
    //       "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJjbGZsZ2Q3dTEwMDAwdjRneWFvdHdqNzBpIiwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTY3OTU5NjQ4NSwiZXhwIjoxNjc5NzY5Mjg1fQ.UHI_0Co3WYg7RGpcwFZza-kR8_dH8BFQjsnyoji5t2w",
    //   },
    // }));

    // We will need to use an API key for standards server
    return forward(operation);
  }

  if (!token) {
    return forward(operation);
  }

  // For default server we need to add the token to the headers
  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }));
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message === "Unauthorized") {
        // We need to redirect the user to login and clear asyncstorage

        console.log(`[GraphQL error]: UNAUTHENTICATED`);

        // Clear the auth store when the error is UNAUTHENTICATED
        const authStore = LocalStorage.retrieve("AUTH");
        const uiStore = LocalStorage.retrieve("UI");

        console.log("authStore", authStore);

        if (authStore && authStore.token) {
          LocalStorage.save("AUTH", {
            ...authStore,
            userId: null,
            user: null,
            token: null,
          });
          LocalStorage.save("UI", {
            ...uiStore,
            activePlannerId: null,
            activeCourseId: null,
            activeResourceId: null,
          });

          window.location.reload();
        }
      }

      // Now we configure custom errors for the app
      if (networkError && "statusCode" in networkError) {
        const statusCode = networkError.statusCode;
        switch (statusCode) {
          case 400:
            throw new BadRequestError(message);
          case 401:
            throw new AuthorizationError(message);
          case 403:
            throw new ForbiddenError(message);
          case 404:
            throw new NotFoundError(message);
          case 500:
            throw new InternalServerError(message);
          case 503:
            throw new ServiceUnavailableError(message);
          case 502:
            throw new BadGatewayError(message);
          case 429:
            throw new RateLimitExceededError(message);
          // Add more cases if needed
          default:
            throw new RequestError(message);
        }
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (navigator.onLine) {
      throw new NetworkError(networkError.message);
    } else {
      throw new OfflineError(networkError.message);
    }
  }
});

const defaultServerLink = new HttpLink({
  uri: process.env.REACT_APP_SERVER_URL
    ? `${process.env.REACT_APP_SERVER_URL}/graphql`
    : "http://localhost:8081/graphql",
});

const standardsMicroserviceLink = new HttpLink({
  uri: "https://standards-server-1.onrender.com/graphql",
  // uri: "http://localhost:3001/graphql",
});

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().clientName === "standards",
  standardsMicroserviceLink,
  defaultServerLink
);

const client = new ApolloClient({
  link: from([withToken, authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      fetchPolicy: "network-only",
    },
  },
});

const stores = createStores(client);

const router = createBrowserRouter([
  // Unauthenticated routes
  {
    path: "/",
    element: (
      <Suspense
        fallback={
          <div className="flex h-screen w-full items-center justify-center">
            <div className="flex flex-col items-center">
              <Spinner size={32} color={"black"} />
            </div>
          </div>
        }
      >
        <Layout />{" "}
      </Suspense>
    ),
    children: [
      {
        path: "/login",
        element: <Auth />,
        index: true,
      },
      {
        path: "/signup",
        element: <Auth />,
        index: true,
      },
      {
        path: "/logout",
        element: <Auth />,
        index: true,
      },
      {
        element: (
          <ErrorBoundary>
            <Suspense
              fallback={
                <div className="flex h-screen w-full items-center justify-center">
                  <div className="flex flex-col items-center">
                    <Spinner size={32} color={"black"} />
                  </div>
                </div>
              }
            >
              <Authenticated />
            </Suspense>
          </ErrorBoundary>
        ),
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: "/organizations",
            element: <Districts />,
          },
          {
            path: "/calendar/:calendarId",
            element: <SharedCalendar />,
          },
          {
            path: "/district/:districtId",
            element: <District />,
            children: [
              {
                path: "/district/:districtId/schools",
                element: <Schools />,
              },
              {
                path: "/district/:districtId/scheduling",
                element: <Scheduling />,
              },
              {
                path: "/district/:districtId/courses",
                element: <Courses />,
              },
              {
                path: "/district/:districtId/subjects",
                element: <Subjects />,
              },
              {
                path: "/district/:districtId/departments",
                element: <Departments />,
              },
              {
                path: "/district/:districtId/users",
                element: <DistrictUsers />,
              },
              {
                path: "/district/:districtId/classes",
                element: <Classes />,
              },
              {
                path: "/district/:districtId/:classId/sections",
                element: <Sections />,
              },
              {
                path: "/district/:districtId/school/:schoolId",
                element: <School />,
              },
              {
                path: "/district/:districtId/school/:schoolId/class/:classId",
                element: <Class />,
                children: [
                  {
                    path: "/district/:districtId/school/:schoolId/class/:classId/sections",
                    element: <Sections />,
                  },
                  {
                    path: "/district/:districtId/school/:schoolId/class/:classId/enrollments",
                    element: <Enrollments />,
                  },
                  {
                    path: "/district/:districtId/school/:schoolId/class/:classId/calendar",
                    element: <ClassCalendar />,
                  },
                ],
              },
              {
                path: "/district/:districtId/session/:sessionId",
                element: <Session />,
                children: [
                  {
                    path: "/district/:districtId/session/:sessionId/rotations",
                    element: <RotationSchedules />,
                  },
                  // Used to actually display the calendar for the session + make changes to calendar days + replace with alternate schedules
                  {
                    path: "/district/:districtId/session/:sessionId/rotations/:rotationId",
                    element: <RotationCalendar />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/blocks",
                    element: <Blocks />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/blocks/:blockId",
                    element: <BlockSchedule />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/alternate-schedules",
                    element: <AlternateDays />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/alternate-schedules/:alternateScheduleId",
                    element: <AlternateSchedule />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/calendar",
                    element: <Calendar />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/class-schedules",
                    element: <ClassSchedules />,
                  },
                  {
                    path: "/district/:districtId/session/:sessionId/class-schedules/:classId",
                    element: <ClassTimeblocking />,
                  },
                  // Used to actually create events for the session + make add holidays / non school days
                  // {
                  //   path: "/district/:districtId/session/:sessionId/events",
                  //   element: <Events />,
                  // },
                  // {
                  //   path: "/district/:districtId/session/:sessionId/alternate-schedules/:id",
                  //   element: <AlternateBlockScheduling />,
                  // },
                  // {
                  //   path: "/district/:districtId/session/:sessionId/class-schedules",
                  //   element: <ClassSchedules />,
                  // },
                  // {
                  //   path: "/district/:districtId/session/:sessionId/class-schedules/:classId",
                  //   element: <ClassCalendar />,
                  // },
                ],
              },
            ],
          },
          {
            path: "/tools",
            element: <Tools />,
          },
          {
            path: "/chats",
            element: <Chats />,
          },
          {
            path: "/users",
            element: <Users />,
          },
          {
            path: "/resources",
            element: <Resources />,
          },
          {
            path: "/documents",
            element: <Documents />,
          },
        ],
      },
    ],
  },
]);

function App() {
  // initializeGlobalErrorHandling();

  return (
    <ApolloProvider client={client}>
      <MobxProvider {...stores}>
        <TooltipProvider>
          <ToastProvider>
            <ModalProvider>
              <RouterProvider router={router} />
            </ModalProvider>
          </ToastProvider>
        </TooltipProvider>
      </MobxProvider>
    </ApolloProvider>
  );
}

export default App;
