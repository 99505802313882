import React from "react";
import Subject from "../../../models/Subject";
import { DashboardBreakpoint } from "../../../types";
import SubjectItem from "../SubjectItem";

type ListOfSubjectsProps = {
  subjects: Subject[];
};

function ListOfSchools({ subjects }: ListOfSubjectsProps) {
  const renderSubjectsLg = () => {
    return (
      <div className="hidden sm:block">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Code
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Department
                      </th>
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {renderSubjectsList("lg")}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSubjectsList = (breakpoint: DashboardBreakpoint) => {
    return subjects.map((subject) => {
      return (
        <SubjectItem
          key={subject.id}
          subject={subject}
          breakpoint={breakpoint}
        />
      );
    });
  };

  return <React.Fragment>{renderSubjectsLg()}</React.Fragment>;
}

export default ListOfSchools;
