import { useState } from "react";
import timezones from "timezones-list";
import { DropdownOptionType } from "../../types";
import Dropdown, { DropdownProps } from "./";

type TimeZoneDropdownProps = Omit<
  DropdownProps,
  "data" | "value" | "onChange"
> & {
  value: string | null | undefined;
  onChange: (val: string) => void;
};

export default function TimeZoneDropdown(props: TimeZoneDropdownProps) {
  const { onChange, value, ...dropdownProps } = props;

  // Get the list of timezones
  const timeZoneList: DropdownOptionType[] = timezones.map(
    ({ tzCode, label }) => ({
      value: tzCode,
      label,
    })
  );

  const defaultValueTimeZone = value
    ? timeZoneList.find((option) => option.value === value)
    : undefined;

  //   // Get the user's timezone
  //   const browserTimeZone = DateTime.local().zoneName;

  //   // Find the browser timezone from our list
  //   const browserTimeZoneOption = timeZoneList.find(
  //     (option) => option.value === browserTimeZone
  //   );

  //   // If we couldn't find the browser's timezone in our list (which shouldn't happen),
  //   // just use the first timezone in the list.
  //   const defaultOption = browserTimeZoneOption || timeZoneList[0];

  // Set the user's timezone as the default selected value
  const [selectedValue, setSelectedValue] = useState(
    value && defaultValueTimeZone ? defaultValueTimeZone : null
  );

  // Handle change
  const handleTimeZoneChange = (option: DropdownOptionType) => {
    setSelectedValue(option);
    onChange(option.value);
  };

  return (
    <Dropdown
      {...dropdownProps}
      data={timeZoneList}
      value={selectedValue}
      onChange={handleTimeZoneChange}
    />
  );
}
