import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import CourseV2 from "../../../models/CourseV2";
import { DashboardBreakpoint } from "../../../types";
import { courseGradesEnumArrayToStringArray } from "../../../utils/gradeLevelsMap";
import Button from "../../Button";
import CourseCreateAndEditModal from "../../CRUD/CreateAndEdit/Course";

type CourseItemsProps = {
  breakpoint: DashboardBreakpoint;
  course: CourseV2;
};

function CourseItem({ breakpoint, course }: CourseItemsProps) {
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);

  const { addToast } = useToastsContext();

  const renderCourseItemLg = () => (
    <tr key={course.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {course.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {courseGradesEnumArrayToStringArray(course.gradeLevels).join(", ")}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {course.sessionName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {course.subjectName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {course.schoolName}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditCourseModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderCourseItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditCourseModal && (
        <CourseCreateAndEditModal
          isEditing={true}
          course={course}
          onClose={() => setShowEditCourseModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditCourseModal(false);

            addToast("Course updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderCourseItemLg() : renderCourseItemSm()}
    </React.Fragment>
  );
}

export default observer(CourseItem);
