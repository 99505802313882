import { useApolloClient } from "@apollo/client";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import ChatMessageList from "../components/ChatMessageList";
import Dropdown from "../components/Dropdown";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import SlideOverWide from "../components/Modals/SlideoverWideModal";
import PageLayout from "../components/PageLayout";
import Pagination from "../components/Pagination";
import { useToastsContext } from "../contexts/toasts";
import {
  GetBotConversations,
  GetBotConversationsMeta,
} from "../graphql/botConversation/botConversation.queries";
import useStores from "../hooks/useStores";
import { DropdownOptionType } from "../types";
import { BotConversation, SortOrder } from "../__generated__/graphql";

function Chats() {
  const { addToast } = useToastsContext();

  const { users } = useStores();

  const [userFilterOptions, setUserFilterOptions] = useState<
    DropdownOptionType[]
  >([]);

  const [userFilter, setUserFilter] = useState<DropdownOptionType>({
    value: "all",
    label: "All Users",
  });
  const [botConversations, setBotConversations] = useState<BotConversation[]>(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = Number(process.env.REACT_APP_PAGE_SIZE || 10);
  const [isFetchingCount, setIsFetchingCount] = useState(false);
  const [isFetchingConversations, setIsFetchingConversations] = useState(false);
  const [currentTimeFilter, setCurrentTimeFilter] = useState("24hours");
  const [error, setError] = useState<any>(null);
  const [viewChatId, setViewChatId] = useState<string | null>(null);

  useEffect(() => {
    setUserFilterOptions([
      {
        value: "all",
        label: "All Users",
      },
      ...users.sortedData.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName + " (" + user.email + ")",
        };
      }),
    ] as DropdownOptionType[]);
  }, [users.sortedData]);

  const apolloClient = useApolloClient();

  const timeFilterOptions = [
    { id: "24hours", name: "24h", href: "#", current: true },
    { id: "7days", name: "7d", href: "#", current: true },
    { id: "30days", name: "30d", href: "#", current: false },
    { id: "allTime", name: "All", href: "#", current: false },
  ];

  const timeFilterDate = useMemo(() => {
    const currentDate = new Date();
    // Calculate time filter based on current time & selected time filter
    // Idea is to get the date from 7 days ago, 30 days ago, or all time
    // and then filter the tools based on that date
    let timeFilterDate = new Date();

    if (currentTimeFilter === "24hours") {
      timeFilterDate.setDate(currentDate.getDate() - 1);
    } else if (currentTimeFilter === "7days") {
      timeFilterDate.setDate(currentDate.getDate() - 7);
    } else if (currentTimeFilter === "30days") {
      timeFilterDate.setDate(currentDate.getDate() - 30);
    } else {
      timeFilterDate = new Date(0); // All time
    }

    return timeFilterDate;
  }, [currentTimeFilter]);

  const selectedChat = useMemo(() => {
    return botConversations.find(
      (conversation) => conversation.id === viewChatId
    );
  }, [viewChatId, botConversations]);

  const fetchBotConversationCount = async () => {
    setIsFetchingCount(true);

    try {
      const conversationCount = await apolloClient.query({
        query: GetBotConversationsMeta,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            botConversationParticipants:
              userFilter && userFilter.value !== "all"
                ? {
                    some: {
                      user: {
                        id: userFilter.value,
                      },
                    },
                  }
                : undefined,
          },
        },
      });

      if (
        !conversationCount.data ||
        !conversationCount.data._botConversationsMeta
      ) {
        addToast("There was an error fetching conversations.", {
          type: "error",
        });
        return;
      }

      console.log(
        "Total Count",
        conversationCount.data._botConversationsMeta.count
      );

      setTotalPages(
        Math.ceil(conversationCount.data._botConversationsMeta.count / pageSize)
      );
      setTotalCount(conversationCount.data._botConversationsMeta.count);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching conversations.", {
        type: "error",
      });
    } finally {
      setIsFetchingCount(false);
    }
  };

  const fetchConversations = async () => {
    setIsFetchingConversations(true);

    try {
      const chats = await apolloClient.query({
        query: GetBotConversations,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            botConversationParticipants:
              userFilter && userFilter.value !== "all"
                ? {
                    some: {
                      user: {
                        id: userFilter.value,
                      },
                    },
                  }
                : undefined,
          },
          orderBy: [{ createdAt: SortOrder.Desc }],
          take: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
      });

      if (!chats.data || !chats.data.botConversations) {
        addToast("There was an error fetching chats.", {
          type: "error",
        });
        return;
      }

      setBotConversations(chats.data.botConversations as BotConversation[]);

      const participants = chats.data.botConversations.map(
        (conversation: any) => {
          return conversation.botConversationParticipants.find(
            (participant: any) => participant.owner === true
          );
        }
      );

      // Filter out userFilterOptions based on actual participants
      const filteredUserFilterUsers = users.sortedData.filter((option) => {
        return participants.find(
          (participant: any) => participant?.user.id === option.id
        );
      });

      setUserFilterOptions([
        {
          value: "all",
          label: "All Users",
        },
        ...filteredUserFilterUsers.map((user) => {
          return {
            value: user.id,
            label:
              user.firstName + " " + user.lastName + " (" + user.email + ")",
          };
        }),
      ] as DropdownOptionType[]);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching chats.", {
        type: "error",
      });
    } finally {
      setIsFetchingConversations(false);
    }
  };

  useEffect(() => {
    fetchBotConversationCount();
    fetchConversations();
  }, [currentPage, currentTimeFilter, userFilter, timeFilterDate]);

  // If filter changes, reset page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [userFilter, currentTimeFilter, timeFilterDate]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderRight = (
    <div className="flex items-center">
      <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
        <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
          {timeFilterOptions.map((item: any) => (
            <button
              key={item.id}
              className={
                currentTimeFilter === item.id
                  ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                  : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
              }
              onClick={() => {
                setCurrentTimeFilter(item.id);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
        {/* Render User filter */}
        {!isFetchingConversations && userFilterOptions.length > 0 && (
          <div className="flex w-[200px]">
            <Dropdown
              id="userFilter"
              value={userFilter}
              onChange={(val: any) => setUserFilter(val)}
              data={userFilterOptions}
              placeholder="Filter by user"
            />
          </div>
        )}
      </div>
    </div>
  );

  const renderSelectedChat = () => {
    if (!selectedChat) {
      return null;
    }

    const { conversationEntries, botConversationParticipants, title } =
      selectedChat;

    const user = botConversationParticipants.find(
      (participant: any) => participant.owner === true
    )?.user;

    const sortConversationEntries = [...conversationEntries];

    // Sort the entries by date created
    sortConversationEntries.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    return (
      <div className="flex w-full flex-col space-y-8">
        {/* Title */}
        <div className="flex flex-col space-y-4">
          <div className="text-2xl font-semibold">{title}</div>
        </div>

        {/* User */}
        <div className="flex flex-col space-y-2">
          <div className="text-slate11 font-semibold">User</div>
          <div>
            {user?.firstName} {user?.lastName} ({user?.email})
          </div>
        </div>

        {/* Render Chat History */}
        <ChatMessageList
          messages={sortConversationEntries}
          activeConversation={selectedChat}
        />
      </div>
    );
  };

  const renderChatsTable = () => {
    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {/* Update these headers based on your tools data structure */}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Chat Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Number of Messages
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>
                    {/* Add more headers as needed */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {botConversations.map((conversation, index) => {
                    return (
                      <tr key={index}>
                        <td
                          className="cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 hover:underline sm:pl-6"
                          onClick={() => setViewChatId(conversation.id)}
                        >
                          {conversation.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {conversation.conversationEntries.length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment(conversation.createdAt).format(
                            "MM/DD/YYYY hh:mm A"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* Pagination Component */}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                totalCount={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Chats</h2>
          </div>
        }
        right={renderRight}
      >
        {isFetchingCount || isFetchingConversations ? (
          <div className="mt-36 flex w-full items-center justify-center">
            <Spinner color="black" size={32} />
          </div>
        ) : (
          <div className="w-full px-20 py-2 pt-4">
            {/* Chats Table */}
            {botConversations.length > 0 ? (
              renderChatsTable()
            ) : (
              <div className="mt-8 flex w-full items-center justify-center">
                <div className="text-xl font-semibold">
                  No chats found for the selected filters.
                </div>
              </div>
            )}
          </div>
        )}
      </PageLayout>

      {viewChatId && selectedChat && (
        <SlideOverWide
          isOpen={true}
          onCloseModal={() => {
            setViewChatId(null);
          }}
          title={moment(selectedChat.createdAt).format("MM/DD/YYYY hh:mm A")}
        >
          {renderSelectedChat()}
        </SlideOverWide>
      )}
    </React.Fragment>
  );
}

export default observer(Chats);
