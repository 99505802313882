import { lowerFirst, orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import { Class } from "utility-types";
import BaseModel from "../models/PersistModel";
import { PartialWithId } from "../types";

import { ApolloClient } from "@apollo/client";
import Ability from "../models/Ability";
import RootStore from "./RootStore";

export enum GQLAction {
  Fetch = "fetch",
  FetchAll = "fetchAll",
  Create = "create",
  Update = "update",
  Delete = "delete",
  Meta = "meta",
}

export default abstract class BaseStore<T extends BaseModel> {
  @observable
  data: Map<string, T> = new Map();

  @observable
  isLoading = false;

  @observable
  isSaving = false;

  @observable
  isLoaded = false;

  model: Class<T>;

  modelName: string;

  rootStore: RootStore;

  apolloClient: ApolloClient<any>;

  actions = [
    GQLAction.Fetch,
    GQLAction.FetchAll,
    GQLAction.Create,
    GQLAction.Update,
    GQLAction.Delete,
    GQLAction.Meta,
  ];

  constructor(
    rootStore: RootStore,
    model: Class<T>,
    apolloClient: ApolloClient<any>
  ) {
    this.rootStore = rootStore;
    this.model = model;
    this.modelName = lowerFirst(model.name).replace(/\d$/, "");
    this.apolloClient = apolloClient;
  }

  @action
  clear() {
    this.data.clear();
  }

  addAbilities = (abilities: Ability[]) => {
    if (abilities) {
      abilities.forEach((policy) => this.rootStore.abilities.add(policy));
    }
  };

  @action
  add = (item: PartialWithId<T> | T): T => {
    const ModelClass = this.model;

    if (!(item instanceof ModelClass)) {
      const existingModel = this.data.get(item.id);

      if (existingModel) {
        existingModel.updateFromJson(item);
        return existingModel;
      }

      const newModel = new ModelClass(item, this);
      this.data.set(newModel.id, newModel);
      return newModel;
    }

    this.data.set(item.id, item);
    return item;
  };

  @action
  remove(id: string): void {
    this.data.delete(id);
  }

  get(id: string): T | undefined {
    return this.data.get(id);
  }

  // SAVE (save)
  // CREATE (create)
  // DELETE (delete)
  // UPDATE (update)
  // FETCH (get(Model))
  // FETCH ALL (get(Models))

  // RETURN DATA IN AN ORDER
  @computed
  get sortedData(): T[] {
    return orderBy(Array.from(this.data.values()), "createdAt", "desc");
  }
}
