import { gql } from "../../__generated__/gql";

export const CreateUser = gql(`
    mutation CreateUser($data: UserCreateInput!) {
        createUser(data: $data) {
            avatarUrl
            createdAt
            deletedAt
            district {
                id
            }
            districtRoles
            edlinkId
            email
            firstName
            gradeLevels
            id 
            identifiers
            lastName
            middleName
            schools {
                id
            }
            updatedAt
            username 
        }
    }
`);

export const CreateDistrictUserAlaynaPortal = gql(`
    mutation CreateDistrictUserAlaynaPortal($data: UserCreateInput!) {
        createDistrictUserAlaynaPortal(data: $data) {
            avatarUrl
            createdAt
            deletedAt
            district {
                id
            }
            districtRoles
            edlinkId
            email
            firstName
            gradeLevels
            id 
            identifiers
            lastName
            middleName
            schools {
                id
            }
            updatedAt
            username 
        }
    }
`);

export const UpdateUser = gql(`
    mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
        updateUser(data: $data, where: $where) {
            avatarUrl
            createdAt
            deletedAt
            district {
                id
            }
            districtRoles
            edlinkId
            email
            firstName
            gradeLevels
            id 
            identifiers
            lastName
            middleName
            schools {
                id
            }
            updatedAt
            username 
        }
    }
`);
