import {
  ArrowLeftIcon,
  CalendarIcon,
  IdCardIcon,
  TokensIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../../hooks/useStores";
import {
  districtRoute,
  districtSchoolClassRoute,
} from "../../../utils/routeHelper";
import DistrictLogo from "../../Avatars/DistrictLogo";
import MainSidebar from "../MainSidebar";
import SidebarTab from "../SidebarTab";

function ClassSidebar() {
  const navigate = useNavigate();

  const { ui, districts, schools, classes } = useStores();

  const { activeDistrictId, activeSchoolId, getSchoolColor, activeClassId } =
    ui;

  if (!activeDistrictId || !activeSchoolId || !activeClassId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  const activeSchool = schools.getByUrlParam(activeSchoolId);

  const activeClass = classes.getByUrlParam(activeClassId);

  if (!activeDistrict || !activeSchool || !activeClass) return null;

  return (
    <MainSidebar>
      {/* Render the planner switching dropdown */}
      <button
        onClick={() => {
          navigate(districtRoute(activeDistrict));
        }}
        className={clsx(
          "bg-slate hover:bg-slate5 group m-2 flex items-center rounded-md px-4 py-6"
        )}
        style={{
          height: "40px",
        }}
      >
        <div className="text-slate11 group-hover:text-slate12 mr-1 pt-1">
          <ArrowLeftIcon className="h-5 w-5" />
        </div>
        <div className="text-slate11 group-hover:text-slate12 pt-1 text-[15px] font-bold">
          Back
        </div>
      </button>

      {/* Render Tabs */}
      <div className="m-2 mt-4 flex flex-col">
        {/* <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          Rostering
        </div> */}

        {/* <SidebarTab
          to={districtSchoolRoute(activeDistrict, activeSchool, "users")}
          icon={<UsersIcon />}
          text="People"
        /> */}

        {/* <SidebarTab
          to={districtSchoolRoute(activeDistrict, activeSchool, "courses")}
          icon={<MixIcon />}
          text="Courses"
        /> */}

        {/* <SidebarTab
          to={districtSchoolRoute(activeDistrict, activeSchool, "classes")}
          icon={<CalculatorIcon />}
          text="Classes"
        /> */}

        <SidebarTab
          to={districtSchoolClassRoute(
            activeDistrict,
            activeSchool,
            activeClass,
            "sections"
          )}
          icon={<TokensIcon />}
          text="Sections"
        />

        <SidebarTab
          to={districtSchoolClassRoute(
            activeDistrict,
            activeSchool,
            activeClass,
            "enrollments"
          )}
          icon={<IdCardIcon />}
          text="Enrollments"
        />

        <SidebarTab
          to={districtSchoolClassRoute(
            activeDistrict,
            activeSchool,
            activeClass,
            "calendar"
          )}
          icon={<CalendarIcon />}
          text="Calendar"
        />
      </div>

      {/* Render District name */}
      <div className="mt-auto flex w-full flex-col">
        <div className="text-slate11 mb-2 px-4 text-xs font-semibold">
          School
        </div>
        <div
          className={clsx(
            "bg-slate focus-visible:ring-blue7 m-2 mt-auto flex items-center rounded-md px-4 py-6 focus-visible:ring focus-visible:ring-opacity-50"
          )}
          style={{
            height: "40px",
          }}
        >
          {/* Wrap the planner logo */}
          <div className="pr-[12px]">
            <DistrictLogo
              districtData={{
                initial: activeSchool.name[0],
                id: activeSchool.id,
                color: getSchoolColor(activeSchool.id),
              }}
              size={28}
            />
          </div>
          {/* Render the planner name with truncation */}
          <div className="truncate text-[15px] font-semibold">
            {activeSchool.name}
          </div>
        </div>
      </div>
    </MainSidebar>
  );
}

export default observer(ClassSidebar);
