import {
  BuildingOffice2Icon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {
  ChatBubbleIcon,
  FileTextIcon,
  GearIcon,
  MixIcon,
} from "@radix-ui/react-icons";
import { observer } from "mobx-react";
import logo from "../../../assets/logos/logo.png";
import useStores from "../../../hooks/useStores";
import {
  chatsRoute,
  districtsRoute,
  documentsRoute,
  homeRoute,
  resourcesRoute,
  toolsRoute,
  usersRoute,
} from "../../../utils/routeHelper";
import MainSidebar from "../MainSidebar";
import SidebarTab from "../SidebarTab";

function AppSidebar() {
  const stores = useStores();

  const { ui, auth } = stores;

  const { user } = auth;

  return (
    <MainSidebar className="group">
      {/* Render the planner switching dropdown */}
      <div className="ml-6 mt-2 flex h-12 shrink-0 items-center">
        <img className="mr-3 h-8" src={logo} alt="Alayna Logo" />
      </div>

      {/* Render the main tabs */}
      <div className="m-2 mt-4 flex flex-col">
        <SidebarTab to={homeRoute()} icon={<HomeIcon />} text="Home" />
        <SidebarTab
          to={districtsRoute()}
          icon={<BuildingOffice2Icon />}
          text="Organizations"
        />
        <SidebarTab to={usersRoute()} icon={<UsersIcon />} text="Users" />
        <SidebarTab to={chatsRoute()} icon={<ChatBubbleIcon />} text="Chats" />
        <SidebarTab to={toolsRoute()} icon={<GearIcon />} text="Tools" />
        <SidebarTab to={resourcesRoute()} icon={<MixIcon />} text="Resources" />
        <SidebarTab
          to={documentsRoute()}
          icon={<FileTextIcon />}
          text="Documents"
        />
        {/* <SidebarTab
          to={analyticsRoute()}
          icon={<ChartBarSquareIcon />}
          text="Analytics"
        /> */}
      </div>
    </MainSidebar>
  );
}

export default observer(AppSidebar);
