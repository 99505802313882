import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import DistrictUser from "../../../models/DistrictUser";
import Enrollment from "../../../models/Enrollment";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import {
  enrollmentRoleEnumToDropdownOptions,
  enrollmentRoleEnumToSelectedOption,
  enrollmentStateEnumToDropdownOptions,
  enrollmentStateEnumToSelectedOption,
} from "../../../utils/classAndSectionEnums";
import {
  EnumEnrollmentRole,
  EnumEnrollmentState,
} from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";
import Switch from "../../Switch";

type EnrollmentFormState = {
  state: DropdownOptionType | null;
  role: DropdownOptionType | null;
  primary: boolean;
  users: DropdownOptionType[];
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: EnrollmentFormState = {
  role: null,
  primary: false,
  state: null,
  users: [],
};

const ENROLLMENT_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "dropdown",
    id: "users",
    label: "Users",
    required: true,
  },
  {
    fieldKind: "dropdown",
    id: "role",
    label: "Role (required)",
    placeholder: "Select the state of the class",
  },
  {
    fieldKind: "dropdown",
    id: "state",
    label: "State (required)",
    placeholder: "Select the state of the class",
  },
  {
    fieldKind: "switch",
    id: "primary",
    label: "Primary Teacher*",
    placeholder: "",
    required: false,
  },
];

const formReducer: Reducer<EnrollmentFormState, FormAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type EnrollmentCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  enrollment?: Enrollment;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function EnrollmentCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  enrollment,
  isEditing = false,
}: EnrollmentCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { classes, districts, enrollments, sections, ui, users, schools } =
    useStores();

  const { isSaving, save } = enrollments;

  const [allUsers, setAllUsers] = useState<DistrictUser[]>([]);

  const [filteredUserOptions, setFilteredUserOptions] = useState<
    DropdownOptionType[]
  >([]);

  const [selectedGradeLevel, setSelectedGradeLevel] =
    useState<DropdownOptionType | null>(null);
  const [selectedUserRole, setSelectedUserRole] =
    useState<DropdownOptionType | null>(null);

  useEffect(() => {
    if (!ui.activeSchoolId) return;

    const activeSchool = schools.getByUrlParam(ui.activeSchoolId);

    invariant(activeSchool, "Active school must be defined");

    const allUsers = users.getUsersForSchool(activeSchool.id);
    setAllUsers(allUsers);
  }, [ui, schools]);

  useEffect(() => {
    // filter the users based on the selected grade level and role
    if (!allUsers) return;

    const filteredUsers = allUsers.filter((user) => {
      if (selectedGradeLevel && user.gradeLevels) {
        return user.gradeLevels.find(
          (gradeLevel) => gradeLevel === selectedGradeLevel.value
        );
      }

      if (selectedUserRole && user.districtRoles) {
        return user.districtRoles.find(
          (role) => role === selectedUserRole.value
        );
      }

      return true;
    });

    setFilteredUserOptions(
      filteredUsers.map((user) => ({
        label: user.fullName,
        value: user.id,
      }))
    );
  }, [allUsers, selectedGradeLevel, selectedUserRole]);

  // create the grade levels options based on the enum
  const gradeLevelsOptions = [
    { label: "All", value: "all" },
    { label: "Al", value: "Al" },
    { label: "Birth", value: "Birth" },
    { label: "Cl", value: "Cl" },
    { label: "G1", value: "G1" },
    { label: "G2", value: "G2" },
    { label: "G3", value: "G3" },
    { label: "G4", value: "G4" },
    { label: "G5", value: "G5" },
    { label: "G6", value: "G6" },
    { label: "G7", value: "G7" },
    { label: "G8", value: "G8" },
    { label: "G9", value: "G9" },
    { label: "G10", value: "G10" },
    { label: "G11", value: "G11" },
    { label: "G12", value: "G12" },
    { label: "G13", value: "G13" },
    { label: "It", value: "It" },
    { label: "Kg", value: "Kg" },
    { label: "Other", value: "Other" },
    { label: "Pk", value: "Pk" },
    { label: "Pr", value: "Pr" },
    { label: "Prenatal", value: "Prenatal" },
    { label: "Ps", value: "Ps" },
    { label: "Tk", value: "Tk" },
    { label: "Ug", value: "Ug" },
  ];

  const userRolesOptions = [
    { label: "All", value: "all" },
    { label: "Administrator", value: "Administrator" },
    { label: "Aide", value: "Aide" },
    { label: "Designer", value: "Designer" },
    { label: "DistrictAdministrator", value: "DistrictAdministrator" },
    { label: "Guardian", value: "Guardian" },
    { label: "Member", value: "Member" },
    { label: "Parent", value: "Parent" },
    { label: "Staff", value: "Staff" },
    { label: "Student", value: "Student" },
    { label: "Ta", value: "Ta" },
    { label: "Teacher", value: "Teacher" },
  ];

  const init = (initialState: EnrollmentFormState) => {
    if (isEditing) {
      if (!ui.activeSchoolId) return initialState;

      const activeSchool = schools.getByUrlParam(ui.activeSchoolId);

      invariant(activeSchool, "Active school must be defined");

      const allUsers = users.getUsersForSchool(activeSchool.id);

      invariant(enrollment, "ClassV2 must be defined if editing");

      console.log(allUsers);
      const user = allUsers.find((user) => user.id === enrollment.userId);
      console.log(user);
      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        // name: sectionV2.name,
        // description: sectionV2.description || "",
        state: enrollmentStateEnumToSelectedOption(enrollment.state),
        role: enrollmentRoleEnumToSelectedOption(enrollment.role),
        primary: enrollment.primary ? enrollment.primary : false,
        users: [
          {
            label: user?.firstName + " " + user?.lastName || "",
            value: user?.id || "",
          },
        ],
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name, state: classState, users, role } = state;

    if (users.length > 0 && classState && role) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "switch":
        if (!state["role"]) return null;
        if (state["role"].value !== "Teacher") return null;

        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Switch
              id={id}
              label={field.label}
              onToggle={(value) => {
                dispatchFormAction({
                  type: "switch",
                  fieldID: id,
                  payload: value,
                });
              }}
              toggleValue={state[id] as boolean}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      default:
        return null;
    }
  };

  const renderUsersDropdown = (id: string, field: FormField) => {
    if (isEditing) {
      return (
        <div>
          <label>
            {state["users"] && state["users"].length > 0
              ? state["users"][0].label
              : "No User Selected"}
          </label>
        </div>
      );
    }
    return (
      <div>
        {/* <label>User(s)</label> */}
        <div className="flex space-x-10 pb-5">
          <div className="min-w-[150px]">
            <Dropdown
              id={id}
              label={"Grade Filter"}
              data={gradeLevelsOptions}
              value={selectedGradeLevel as DropdownOptionType}
              onChange={(value) => {
                console.log("Dropdown onChange called", value);
                if (value.value === "all") {
                  setSelectedGradeLevel(null);
                } else {
                  setSelectedGradeLevel(value);
                }
              }}
            />
          </div>
          <div className="min-w-[150px]">
            <Dropdown
              id={id}
              label={"Role Filter"}
              data={userRolesOptions}
              value={selectedUserRole as DropdownOptionType}
              onChange={(value) => {
                console.log("Dropdown onChange called", value);
                if (value.value === "all") {
                  setSelectedUserRole(null);
                } else {
                  setSelectedUserRole(value);
                }
              }}
            />
          </div>
        </div>
        <div>
          <Dropdown
            id={id}
            label={"Select Users"}
            multiSelect={true}
            data={filteredUserOptions}
            value={state[id] as DropdownOptionType[]}
            onChange={(value) => {
              console.log("Dropdown onChange called", value);
              dispatchFormAction({
                type: "dropdown",
                fieldID: id,
                payload: value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const renderEnrollmentRolesDropdown = (id: string, field: FormField) => {
    const dropdownOptions = enrollmentRoleEnumToDropdownOptions();

    console.log("Dropdown Value", state[id]);

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            if (value.value !== "Teacher") {
              dispatchFormAction({
                type: "switch",
                fieldID: "primary",
                payload: false,
              });
            }
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderEnrollmentStatesDropdown = (id: string, field: FormField) => {
    const dropdownOptions = enrollmentStateEnumToDropdownOptions();
    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={dropdownOptions}
          value={state[id] as DropdownOptionType[]}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "multiselect",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "users":
        return renderUsersDropdown(id, field);
      case "state":
        return renderEnrollmentStatesDropdown(id, field);
      case "role":
        return renderEnrollmentRolesDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (
        !state.state ||
        !state.role ||
        !state.users
        // || !state.sessions
        // || state.sessions.length === 0
      ) {
        return false;
      }

      if (isEditing) {
        // TODO: Add logic for updating enrollment
        invariant(enrollment, "Enrollment must be defined if editing");

        try {
          const updateEnrollment = {
            id: enrollment.id,
            state: state.state.value as EnumEnrollmentState,
            role: state.role.value as EnumEnrollmentRole,
            primary: state.primary,
          };
          await save(updateEnrollment);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating session", err);
        }
      } else {
        try {
          invariant(ui.activeClassId, "Active class ID must be defined");
          const activeClass = classes.getByUrlParam(ui.activeClassId);
          invariant(activeClass, "Active class must be defined");

          invariant(ui.activeSectionId, "Active section ID must be defined");
          const activeSection = sections.getByUrlParam(ui.activeSectionId);
          invariant(activeSection, "Active section must be defined");

          for (let i = 0; i < state.users.length; i++) {
            const user = state.users[i];
            const enrollment = {
              ...state,
              classV2: { id: activeClass.id },
              section: { id: activeSection.id },
              user: { id: user.value },
              state: state.state.value as EnumEnrollmentState,
              role: state.role.value as EnumEnrollmentRole,
            } as any;
            delete enrollment.users;
            await save(enrollment);
          }

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating session", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      enrollment,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Add"} Enrollments
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {ENROLLMENT_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(EnrollmentCreateAndEditModal);
