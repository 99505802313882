import { gql } from "../../__generated__/gql";

export const CreateDistrict = gql(`
    mutation createDistrictWithDrive($data: DistrictCreateInput!) {
        createDistrictWithDrive(data: $data) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
                ssoAvailable
            }
            id
            identifiers
            locale
            name
            payments
            properties
            schools {
                id
            }
            timezone
            updatedAt
            users {
                id
            }
            urlId
        }
    }
`);

export const UpdateDistrict = gql(`
    mutation updateDistrict($data: DistrictUpdateInput!, $where: DistrictWhereUniqueInput!) {
        updateDistrict(data: $data, where: $where) {
            address
            createdAt
            deletedAt
            id
            identifiers
            edlinkId
            edlinkIntegrations {
                id
                provider {
                    application
                    name
                }
                ssoAvailable
            }
            id
            identifiers
            locale
            name
            payments
            properties
            schools {
                id
            }
            timezone
            updatedAt
            users {
                id
            }
            urlId
        }
    }
`);

export const UpdateEdlinkIntegration = gql(`
    mutation updateEdlinkIntegration($data: EdlinkIntegrationUpdateInput!, $where: EdlinkIntegrationWhereUniqueInput!) {
        updateEdlinkIntegration(data: $data, where: $where) {
            id
            provider {
                application
                name
            }
            district {
                id
            }
            ssoAvailable
        }
    }
`);

export const MakeUserActive = gql(/* Graphql */ `
mutation makeUserActiveAlaynaPortal($makeUserActiveInput: MakeUserActiveInput!) {
  makeUserActiveAlaynaPortal(makeUserActiveInput: $makeUserActiveInput)
}
`);

export const ChangeUserLicense = gql(/* Graphql */ `
mutation changeUserLicense($userLicenseActiveInput: UserLicenseActiveInput!) {
  changeUserLicense(userLicenseActiveInput: $userLicenseActiveInput)
}
`);

export const MakeUsersActiveBulk = gql(/* Graphql */ `
mutation makeUsersActiveBulkAlaynaPortal($bulkUserActiveInput: BulkUserActiveInput!) {
  makeUsersActiveBulkAlaynaPortal(bulkUserActiveInput: $bulkUserActiveInput)
}
`);

export const ChangeUserLicensesBulk = gql(/* Graphql */ `
mutation changeUserLicenseBulk($bulkLicenseActiveInput: BulkLicenseActiveInput!) {
  changeUserLicenseBulk(bulkLicenseActiveInput: $bulkLicenseActiveInput)
}
`);
