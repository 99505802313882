import { observer } from "mobx-react";
import React, { useState } from "react";
import SectionCreateAndEditModal from "../../../components/CRUD/CreateAndEdit/Section";
import { useToastsContext } from "../../../contexts/toasts";
import SectionV2 from "../../../models/SectionV2";
import { DashboardBreakpoint } from "../../../types";
import { classStateToStringMap } from "../../../utils/classAndSectionEnums";
import { EnumClassV2State } from "../../../__generated__/graphql";
import Button from "../../Button";

type ClassItemsProps = {
  breakpoint: DashboardBreakpoint;
  sectionV2: SectionV2;
};

function SectionItem({ breakpoint, sectionV2 }: ClassItemsProps) {
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const { addToast } = useToastsContext();

  const renderClassStateBadge = (sessionState: EnumClassV2State) => {
    switch (sessionState) {
      case EnumClassV2State.Active:
        return (
          <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Completed:
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Upcoming:
        return (
          <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Inactive:
        return (
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Archived:
        return (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumClassV2State.Template:
        return (
          <span className="inline-flex items-center rounded-md bg-pink-100 px-2 py-1 text-xs font-medium text-gray-800">
            {classStateToStringMap[sessionState]}
          </span>
        );

      default:
        return null;
    }
  };

  const renderSectionItemLg = () => (
    <tr key={sectionV2.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {sectionV2.name}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {sectionV2.primaryEnrollment?.fullName || "N/A"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sectionV2.enrollments.length}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditSectionModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderSectionItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditSectionModal && (
        <SectionCreateAndEditModal
          isEditing={true}
          sectionV2={sectionV2}
          onClose={() => setShowEditSectionModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditSectionModal(false);
            addToast("Section updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderSectionItemLg() : renderSectionItemSm()}
    </React.Fragment>
  );
}

export default observer(SectionItem);
