import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import Department from "../../../models/Department";
import { DashboardBreakpoint } from "../../../types";
import Button from "../../Button";
import DepartmentCreateAndEditModal from "../../CRUD/CreateAndEdit/Department";

type DepartmentItemProps = {
  breakpoint: DashboardBreakpoint;
  department: Department;
};

function DepartmentItem({ breakpoint, department }: DepartmentItemProps) {
  const [showEditDepartmentModal, setShowEditDepartmentModal] = useState(false);

  const { addToast } = useToastsContext();

  const renderDepartmentItemLg = () => (
    <tr key={department.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {department.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {department.schoolNames}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {department.subjectNames}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditDepartmentModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderDepartmentItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditDepartmentModal && (
        <DepartmentCreateAndEditModal
          isEditing={true}
          department={department}
          onClose={() => setShowEditDepartmentModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditDepartmentModal(false);

            addToast("Department updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg"
        ? renderDepartmentItemLg()
        : renderDepartmentItemSm()}
    </React.Fragment>
  );
}

export default observer(DepartmentItem);
