import { observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import ResponsiveLayout from "../components/Layout";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import ResponsiveLayout from "../components/ResponsiveLayout";
import useStores from "../hooks/useStores";

function Authenticated() {
  const { auth, districts, users } = useStores();

  const { validSession, isLoading, user, isInitialized } = auth;

  const navigate = useNavigate();

  const location = useLocation();

  // First entry point for all authenticated routes. We can check if the user is authenticated and if the user has completed onboarding here.
  useEffect(() => {
    if (isInitialized && !validSession) {
      navigate("/login");
      return;
    }

    if (isInitialized && validSession && user) {
      // Here we can fetch all the initial Dashboard data.
      districts.fetchAllDistricts();
      // users.fetchAllUsers();

      // If the current route is /login or /signup or /logout, we want to redirect the user to the home page.
      if (
        location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/logout"
      ) {
        navigate("/");
      }
    }

    // We want to fetch all the user data only once when the user is authenticated and has completed onboarding.
  }, [isInitialized, validSession, user, location, districts, navigate]);

  // TO-DO: Replace Spinner with a skeleton loader
  if (!isInitialized || !user || isLoading) {
    return (
      <div className="h-full w-full">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      {/* Replace title with current planner name */}
      <ResponsiveLayout />
    </div>
  );
}

export default observer(Authenticated);
