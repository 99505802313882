type DistrictLogoProps = {
  districtData: {
    id: string;
    initial: string;
    color: string;
  };
  size: number;
  border?: boolean;
};

export default function DistrictLogo({
  districtData,
  size,
  border,
}: DistrictLogoProps) {
  const fontSize = size / 2;
  const borderStyle = !border
    ? "border-none"
    : "border-2 border-current bg-current";

  return (
    <div
      className={`flex items-center justify-center rounded-md text-white  text-${fontSize} font-semibold ${borderStyle} `}
      style={{
        backgroundColor: districtData.color,
        border: !border ? "none" : "2px solid var(--slate7)",
        width: size,
        height: size,
      }}
    >
      {districtData.initial}
    </div>
  );
}
