import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import SubjectCreateAndEditModal from "../components/CRUD/CreateAndEdit/Subject";
import PageLayout from "../components/PageLayout";
import ListOfSubjects from "../components/Subjects/ListOfSubjects";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Subjects() {
  const [showCreateSubjectModal, setShowCreateSubjectModal] = useState(false);

  const { ui, districts, subjects } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId } = ui;

  if (!activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  const districtSubjects = subjects.getSubjectsForDistrict(activeDistrict.id);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateSubjectModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Subjects</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfSubjects
            key={districtSubjects.length}
            subjects={districtSubjects}
          />
        </div>
      </PageLayout>
      {showCreateSubjectModal && (
        <SubjectCreateAndEditModal
          onClose={() => setShowCreateSubjectModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateSubjectModal(false);

            addToast("Subject created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Subjects);
