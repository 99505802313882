import { action, computed, observable } from "mobx";
import { EnumDistrictWorkspaceType } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

export type EdlinkIntegrationType = {
  id: string;
  providerName: string;
  providerApplication: string;
  ssoAvailable: boolean;
};

class District extends DeletableModel {
  @observable
  id: string;

  @observable
  address: any | null;

  @observable
  edlinkId: string | null;

  @observable
  edlinkIntegrations: EdlinkIntegrationType[] | null;

  @observable
  identifiers: any | null;

  @observable
  locale: string | null;

  @Attribute
  @observable
  name: string;

  @observable
  properties: any | null;

  @observable
  schoolIds: string[] | null;

  @observable
  timezone: string | null;

  @observable
  userIds: string[] | null;

  @observable
  urlId: string;

  @observable
  activeSchoolId: string | null;

  @Attribute
  @observable
  payments: any;

  @Attribute
  @observable
  users: any;

  @Attribute
  @observable
  workspaceType: EnumDistrictWorkspaceType;

  @action
  setActiveSchoolId(id: string) {
    this.activeSchoolId = id;
  }

  @computed
  get edlinkIntegrationsCount() {
    return this.edlinkIntegrations ? this.edlinkIntegrations.length : 0;
  }

  @computed
  get schoolsCount() {
    return this.schoolIds ? this.schoolIds.length : 0;
  }

  @computed
  get numberOfSeats() {
    return this.payments && this.payments.numberOfSeats
      ? this.payments.numberOfSeats
      : 0;
  }

  @computed
  get subscriptionType() {
    return this.payments ? this.payments.subscriptionType : null;
  }

  @computed
  get usersCount() {
    return this.userIds ? this.userIds.length : 0;
  }

  @computed
  get licensedUsers(): string[] | null {
    if (!this.payments) return null;

    return this.payments.activeUsers || [];
  }
}

export default District;
