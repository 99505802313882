import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logos/logo.png";
import Alert from "../components/Alert";
import Button from "../components/Button";
import Input from "../components/Input";
import Link from "../components/Link";
import styles from "./Auth.module.css";

import { observer } from "mobx-react";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Auth() {
  // State to store email, password and error
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { auth } = useStores();

  const {
    isLoggingIn,
    loginUserWithEmail,
    loginError,
    isSigningUp,
    signupUserWithEmail,
    signupError,
    isResettingPassword,
    resetPasswordError,
    validSession,
    // logout,
    isInitialized,
    // clearAuthErrors,
    // resetPasswordWithEmail,
    // loginWithGoogle,
    // loginWithMicrosoft,
  } = auth;

  const navigate = useNavigate();

  const location = useLocation();

  const { addToast } = useToastsContext();

  const [isSignupRoute, setIsSignupRoute] = useState(
    location.pathname === "/signup"
  );
  const [isResetPasswordRoute, setIsResetPasswordRoute] = useState(
    location.pathname === "/reset-password"
  );

  useEffect(() => {
    // clearAuthErrors();
  }, [email, password, location.pathname]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (location.pathname.includes("/auth/google/callback")) {
    //   (async () => {
    //     const { success, error } = await loginWithGoogle(location);
    //     if (success) {
    //       navigate("/");
    //       setLoading(false);
    //     } else if (error) {
    //       alert(error);
    //       navigate("/");
    //       setLoading(false);
    //     }
    //   })();
    // }
    // // microsoft
    // else if (location.pathname.includes("/auth/microsoft/callback")) {
    //   (async () => {
    //     const { success, error } = await loginWithMicrosoft(location);
    //     if (success) {
    //       navigate("/");
    //       setLoading(false);
    //     } else if (error) {
    //       alert(error);
    //       navigate("/");
    //       setLoading(false);
    //     }
    //   })();
    // } else {
    //   setLoading(false);
    // }
    // logout
    if (location.pathname === "/logout") {
      logoutUser();
    }
    setIsSignupRoute(location.pathname === "/signup");
    setIsResetPasswordRoute(location.pathname === "/reset-password");
    setEmail("");
    setPassword("");

    async function logoutUser() {
      //   await logout();
      navigate("/");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialized && validSession) {
      navigate("/");
    }
  }, [validSession, isInitialized]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center py-12">
        <Spinner color="black" size={24} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        styles.appBackground,
        "flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8"
      )}
      style={{ backgroundColor: "#fff" }}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mx-auto flex w-auto flex-row items-center justify-center">
          <img className="mr-3 h-12" src={logo} alt="Your Company" />
        </div>
      </div>

      <div
        className={clsx(
          styles.formColors,
          "mt-12 border-[1px] sm:mx-auto sm:w-full sm:max-w-md sm:rounded-lg"
        )}
      >
        {/* Render Tabs for switching between Sign up and Sign in using Links from react-router-dom */}
        {isResetPasswordRoute ? null : (
          <div className="w-full sm:flex sm:items-center sm:justify-between">
            <Link
              to={"/login"}
              className={clsx(
                !isSignupRoute ? styles.activeTab : styles.inactiveTab,
                "text-md w-1/2 py-3 text-center font-medium"
              )}
            >
              Sign in
            </Link>
            <Link
              to={"/signup"}
              className={clsx(
                isSignupRoute ? styles.activeTab : styles.inactiveTab,
                "text-md w-1/2 py-3 text-center font-medium"
              )}
            >
              Sign up
            </Link>
          </div>
        )}

        {/* Render Sign up or Sign in form depending on isSignupRoute */}
        <div className="py-8 px-4 sm:px-10">
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              if (isResetPasswordRoute) {
                // setLoading(true);
                // const { success } = await resetPasswordWithEmail(email);
                // // const success = true;
                // if (success) {
                //   addToast(
                //     "An email has been sent to you. Please check your email and follow the instructions to reset your password.",
                //     {
                //       type: "success",
                //     }
                //   );
                //   // navigate("/onboarding");
                //   // when reset password is clicked, show a message that an email has been sent
                // }
                // setLoading(false);
              } else if (isSignupRoute) {
                const { success } = await signupUserWithEmail(email, password);

                if (success) {
                  navigate("/");
                } else {
                  addToast(
                    "An account already exists for this email address.",
                    {
                      type: "error",
                    }
                  );
                }
                setLoading(false);
              } else {
                const { success } = await loginUserWithEmail(email, password);
                if (success) {
                  navigate("/");
                } else {
                  addToast("Invalid email or password.", {
                    type: "error",
                  });
                }
                setLoading(false);
              }
            }}
            className="space-y-6"
            action="#"
            method="POST"
          >
            {
              // Render back button if resetting password
              isResetPasswordRoute ? (
                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <Button
                      type="button"
                      theme="tertiary"
                      onClick={() => {
                        navigate("/login");
                      }}
                      icon={"chevron-left"}
                      buttonText={"Back to login"}
                    />
                  </div>
                </div>
              ) : null
            }
            <div>
              <Input
                label={"Email address"}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required={true}
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </div>

            {isResetPasswordRoute ? null : (
              <Input
                label={"Password"}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required={true}
                value={password}
                onChange={(value) => setPassword(value)}
              />
            )}

            {isResetPasswordRoute ? null : (
              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link to={"/reset-password"}>Forgot your password?</Link>
                </div>
              </div>
            )}

            <div>
              <button
                // theme="primary"
                disabled={isSigningUp || isLoggingIn || isResettingPassword}
                // fullWidth={true}
                type="submit"
                className="flex w-full justify-center rounded-lg bg-[#0062ff] py-2 px-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                // className="hover:bg-primary-700 focus:ring-primary-500 flex w-full justify-center border border-transparent bg-[#0062ff] py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                {isResetPasswordRoute
                  ? "Reset Password"
                  : isSignupRoute
                  ? "Sign up"
                  : "Sign in"}
              </button>
            </div>
          </form>

          {isResetPasswordRoute ? null : (
            <div className="mt-6">
              <div className="relative">
                {/* <div className="absolute inset-0 flex items-center">
                  <div
                    className={clsx(styles.continueBorder, "w-full border-t")}
                  />
                </div> */}
                <div className="relative flex justify-center text-sm">
                  {/* <span className={clsx("px-2", styles.continueText)}>
                    Or continue with
                  </span> */}
                </div>
              </div>

              <div className="space-y-2">
                {/* <div>
                  <a
                    href={`${process.env.REACT_APP_SERVER_URL}/api/auth/google/login`}
                    className={clsx(
                      styles.socialMediaButton,
                      styles.roboto,
                      "inline-flex w-full justify-center rounded-lg border py-2 px-4 pl-6 text-sm shadow-sm"
                    )}
                  >
                    <img
                      className="my-1 h-4"
                      src={GoogleLogo}
                      alt="Google Logo"
                    />
                    <span className="ml-[12px] w-40 pt-[2px] font-[15px]">
                      Sign in with Google
                    </span>
                  </a>
                </div>

                <div>
                  <a
                    href={`${process.env.REACT_APP_SERVER_URL}/api/auth/microsoft/login`}
                    className={clsx(
                      styles.socialMediaButton,
                      styles.segoe,
                      "inline-flex w-full justify-center rounded-lg border py-2 px-4 pl-6 text-sm shadow-sm"
                    )}
                  >
                    <img
                      className="my-1 h-4"
                      src={MicrosoftLogo}
                      alt="Microsoft Logo"
                    />
                    <span className="ml-[12px] w-40 pt-[1px] font-[15px]">
                      Sign in with Microsoft
                    </span>
                  </a>
                </div> */}
              </div>
            </div>
          )}

          {signupError && isSignupRoute && (
            <Alert type="error" className="mt-6" message={signupError} />
          )}
          {loginError && !isSignupRoute && (
            <Alert type="error" className="mt-6" message={loginError} />
          )}
          {resetPasswordError && (
            <Alert type="error" className="mt-6" message={resetPasswordError} />
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Auth);
