import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import useStores from "../hooks/useStores";

function District() {
  const { districtId = "" } = useParams();

  const {
    courses,
    departments,
    districts,
    ui,
    schools,
    sessions,
    subjects,
    users,
  } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  const currentDistrict = districts.getByUrlParam(districtId);

  // Set active district in UI store
  useEffect(() => {
    if (currentDistrict) {
      ui.setActiveDistrictId(currentDistrict.urlId);
    }

    setIsLoading(false);

    return () => ui.setActiveDistrictId(undefined);
  }, [currentDistrict, ui]);

  // Fetch all district related data
  useEffect(() => {
    if (currentDistrict) {
      schools.fetchDistrictSchools(currentDistrict.id);
      users.fetchDistrictUsers(currentDistrict.id);
      // sessions.fetchDistrictSessions(currentDistrict.id);
      // courses.fetchDistrictCourses(currentDistrict.id);
      // departments.fetchDistrictDepartments(currentDistrict.id);
      // subjects.fetchDistrictSubjects(currentDistrict.id);
    }
  }, [
    currentDistrict,
    schools,
    sessions,
    courses,
    subjects,
    departments,
    users,
  ]);

  if (isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  return <Outlet />;
}

export default observer(District);
