// here we create a component that will display the chat messages

import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import goldTeacher from "../../assets/gold-teacher.png";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import {
  BotConversation,
  BotConversationEntry,
  EnumBotConversationEntrySpeaker,
} from "../../__generated__/graphql";
import Avatar from "../Avatars/Avatar";
import Button from "../Button";
import Tooltip from "../Tooltip";

type ChatMessageListProps = {
  messages: BotConversationEntry[];
  activeConversation: BotConversation;
};

function ChatMessageList(props: ChatMessageListProps) {
  const { messages, activeConversation } = props;
  const { auth } = useStores();
  const user = auth.user;

  const { addToast } = useToastsContext();

  const { addModal, closeModalAll } = useModalContext();

  const renderMessageContent = (entry: any) => {
    let text = "";

    if (!entry) return null;

    if (typeof entry === "string") {
      text = entry;
    } else {
      if (!entry.text) return null;

      text = entry.text;
    }

    return (
      <div className="markdown-body overflow-x-scroll">
        <ReactMarkdown
          remarkPlugins={[remarkMath, remarkGfm]}
          rehypePlugins={[rehypeRaw, rehypeKatex]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || "");
              const language = match ? match[1] : undefined;

              console.log("Language", language);

              const copyCodeToClipboard = () => {
                navigator.clipboard.writeText(
                  String(children).replace(/\n$/, "")
                );

                addToast("Code copied!", {
                  type: "success",
                });
              };

              return !inline && language ? (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: 6,
                      left: 12,
                      color: "white",
                      background: "black",
                      padding: "4px 8px",
                      borderRadius: 4,
                    }}
                  >
                    {language}
                  </div>
                  <button
                    style={{
                      position: "absolute",
                      top: 6,
                      right: 12,
                      color: "white",
                      background: "black",
                      padding: "4px 8px",
                      borderRadius: 4,
                    }}
                    onClick={copyCodeToClipboard}
                  >
                    {"Copy"}
                  </button>
                  <SyntaxHighlighter
                    {...props}
                    children={String(children).replace(/\n$/, "")}
                    style={vscDarkPlus}
                    language={language}
                    customStyle={{
                      padding: "36px 12px 24px",
                      borderRadius: 12,
                    }}
                    PreTag="div"
                  />
                </div>
              ) : (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            },
          }}
        >
          {text}
        </ReactMarkdown>
      </div>
    );
  };

  const copyMessageContent = (entry: any) => {
    let text = "";

    if (!entry) return null;

    if (typeof entry === "string") {
      text = entry;
    } else {
      if (!entry.text) return null;

      text = entry.text;
    }

    navigator.clipboard.writeText(text);
    addToast("Message copied!", {
      type: "success",
    });
  };

  if (!user) return null;

  return (
    <div className="px-8">
      {messages.map((message: BotConversationEntry, index: any) => {
        const role = message.speaker;
        const id = message.id;

        let text = toJS(message.entry);

        let isStreamingResponse = false;

        const displayName =
          role === EnumBotConversationEntrySpeaker.User
            ? user.firstName
            : "Alayna";

        return (
          <div key={id} className="chat-message fadeIn pb-5">
            <div
              className={clsx(
                "flex items-end justify-end",
                role === EnumBotConversationEntrySpeaker.User
                  ? ""
                  : "flex-row-reverse"
              )}
            >
              <div className="relative mx-2 flex max-w-2xl flex-col space-y-2 text-left text-sm">
                <div className=" flex overflow-x-scroll">
                  {/* Message container */}
                  <div className="flex flex-col space-y-2">
                    <span
                      className={clsx(
                        "inline-block max-w-full rounded-xl text-left",
                        role === EnumBotConversationEntrySpeaker.User
                          ? "rounded-br-none bg-[#4697e7] px-4 py-2 text-white"
                          : "rounded-bl-none bg-gray-100 px-4 py-2 text-gray-600"
                      )}
                      style={{
                        maxWidth: 1024,
                      }}
                    >
                      {renderMessageContent(text)}
                    </span>
                  </div>
                </div>
                {!isStreamingResponse &&
                  role === EnumBotConversationEntrySpeaker.Bot && (
                    <Tooltip
                      content={"Copy message"}
                      asChild={false}
                      triggerClassname="absolute top-2 -right-[48px] ml-2"
                    >
                      <Button
                        type="button"
                        theme="secondary"
                        rounded="medium"
                        padding="medium"
                        icon={"copy"}
                        onClick={() => copyMessageContent(text)}
                      />
                    </Tooltip>
                  )}
                <div
                  className={clsx(
                    "text-xs text-gray-500",
                    role === EnumBotConversationEntrySpeaker.User
                      ? "text-right"
                      : "text-left"
                  )}
                >
                  {displayName}
                </div>{" "}
              </div>

              <div className="mt-[-5px]">
                {role === EnumBotConversationEntrySpeaker.User ? (
                  <Avatar
                    userData={{
                      name: user.firstName + " " + user.lastName,
                      initial: user.firstName ? user.firstName[0] : "",
                      id: user.id,
                      color: "blue",
                      avatarUrl: user.avatarUrl,
                    }}
                    size={32}
                  />
                ) : (
                  <Avatar
                    userData={{
                      name: "Alayna",
                      initial: "A",
                      id: "alayna",
                      color: "gray",
                      avatarUrl: goldTeacher,
                    }}
                    size={32}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default observer(ChatMessageList);
