import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import SectionCreateAndEditModal from "../components/CRUD/CreateAndEdit/Section";
import PageLayout from "../components/PageLayout";
import ListOfSections from "../components/Sections/ListOfSections";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Sections() {
  const [showCreateSectionModal, setShowCreateSectionModal] = useState(false);

  const { ui, classes, sections } = useStores();

  const { addToast } = useToastsContext();

  const { activeClassId } = ui;

  if (!activeClassId) return null;

  const activeClass = classes.getByUrlParam(activeClassId);

  if (!activeClass) return null;

  const classSections = sections.getSectionsForClass(activeClass.id);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateSectionModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Sections</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfSections key={classSections.length} sections={classSections} />
        </div>
      </PageLayout>
      {showCreateSectionModal && (
        <SectionCreateAndEditModal
          onClose={() => setShowCreateSectionModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateSectionModal(false);
            addToast("Class created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Sections);
