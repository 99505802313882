import { useApolloClient } from "@apollo/client";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import Dropdown from "../components/Dropdown";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import PageLayout from "../components/PageLayout";
import Pagination from "../components/Pagination";
import { useToastsContext } from "../contexts/toasts";
import { GetUsers, GetUsersMeta } from "../graphql/users/users.queries";
import useStores from "../hooks/useStores";
import { DropdownOptionType } from "../types";
import { SortOrder, User } from "../__generated__/graphql";

const userCategoryOptions = [
  { id: "all", name: "All" },
  { id: "individual", name: "Individual" },
  { id: "enterprise", name: "Enterprise" },
];

const timeFilterOptions = [
  { id: "24hours", name: "24h", href: "#", current: true },
  { id: "7days", name: "7d", href: "#", current: true },
  { id: "30days", name: "30d", href: "#", current: false },
  { id: "allTime", name: "All Time", href: "#", current: false },
];

function Users() {
  const { addToast } = useToastsContext();

  const { districts } = useStores();

  const [districtFilter, setDistrictFilter] = useState<DropdownOptionType>({
    value: "all",
    label: "All Orgs",
  }); // ["all", "districtId"]
  const [currentUserCategory, setCurrentUserCategory] = useState("all");
  const [currentTimeFilter, setCurrentTimeFilter] = useState("24hours");
  const [users, setUsers] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = Number(process.env.REACT_APP_PAGE_SIZE || 10);
  const [isFetchingCount, setIsFetchingCount] = useState(false);
  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [error, setError] = useState<any>(null);
  const [viewUserId, setViewUserId] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  const districtFilterOptions = useMemo(() => {
    return [
      { value: "all", label: "All Orgs" },
      ...districts.sortedData.map((district) => ({
        value: district.id,
        label: district.name,
      })),
    ] as DropdownOptionType[];
  }, [districts.sortedData]);

  const timeFilterDate = useMemo(() => {
    const currentDate = new Date();
    // Calculate time filter based on current time & selected time filter
    // Idea is to get the date from 7 days ago, 30 days ago, or all time
    // and then filter the users based on that date
    let timeFilterDate = new Date();

    if (currentTimeFilter === "24hours") {
      timeFilterDate.setDate(currentDate.getDate() - 1);
    } else if (currentTimeFilter === "7days") {
      timeFilterDate.setDate(currentDate.getDate() - 7);
    } else if (currentTimeFilter === "30days") {
      timeFilterDate.setDate(currentDate.getDate() - 30);
    } else {
      timeFilterDate = new Date(0); // All time
    }

    return timeFilterDate;
  }, [currentTimeFilter]);

  const selectedUser = useMemo(() => {
    return users.find((user) => user.id === viewUserId);
  }, [viewUserId, users]);

  const fetchTotalCount = async () => {
    setIsFetchingCount(true);

    try {
      const userCount = await apolloClient.query({
        query: GetUsersMeta,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            district:
              districtFilter.value !== "all"
                ? { id: districtFilter.value }
                : undefined,
            edlinkId:
              currentUserCategory === "enterprise"
                ? {
                    not: null,
                  }
                : currentUserCategory === "individual"
                ? {
                    equals: null,
                  }
                : undefined,
            isOnboarded: {
              equals: true,
            },
          },
        },
      });

      if (!userCount.data || !userCount.data._usersMeta) {
        addToast("There was an error fetching users.", {
          type: "error",
        });
        return;
      }

      console.log("Total Count", userCount.data._usersMeta.count);

      setTotalPages(Math.ceil(userCount.data._usersMeta.count / pageSize));
      setTotalCount(userCount.data._usersMeta.count);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching users.", {
        type: "error",
      });
    } finally {
      setIsFetchingCount(false);
    }
  };

  const fetchUsers = async () => {
    setIsFetchingUsers(true);

    try {
      const users = await apolloClient.query({
        query: GetUsers,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            district:
              districtFilter.value !== "all"
                ? { id: districtFilter.value }
                : undefined,
            edlinkId:
              currentUserCategory === "enterprise"
                ? {
                    not: null,
                  }
                : currentUserCategory === "individual"
                ? {
                    equals: null,
                  }
                : undefined,
            isOnboarded: {
              equals: true,
            },
          },
          orderBy: [{ createdAt: SortOrder.Desc }],
          take: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
      });

      if (!users.data || !users.data.users) {
        addToast("There was an error fetching users.", {
          type: "error",
        });
        return;
      }

      setUsers(users.data.users as User[]);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching users.", {
        type: "error",
      });
    } finally {
      setIsFetchingUsers(false);
    }
  };

  useEffect(() => {
    fetchTotalCount();
    fetchUsers();
  }, [
    currentPage,
    currentTimeFilter,
    districtFilter,
    timeFilterDate,
    currentUserCategory,
  ]);

  // If filter changes, reset page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [districtFilter, currentUserCategory, currentTimeFilter]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderRight = (
    <div className="flex items-center">
      <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
        <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
          {timeFilterOptions.map((item: any) => (
            <button
              key={item.id}
              className={
                currentTimeFilter === item.id
                  ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                  : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
              }
              onClick={() => {
                setCurrentTimeFilter(item.id);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
          <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
            {userCategoryOptions.map((item: any) => (
              <button
                key={item.id}
                className={
                  currentUserCategory === item.id
                    ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                    : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
                }
                onClick={() => {
                  setCurrentUserCategory(item.id);
                }}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
        {currentUserCategory === "enterprise" && (
          <div className="flex w-[200px]">
            <Dropdown
              id="districtFilter"
              value={districtFilter}
              onChange={(val: any) => setDistrictFilter(val)}
              data={districtFilterOptions}
              placeholder="Filter by user"
            />
          </div>
        )}
      </div>
    </div>
  );

  const renderUsersTable = () => {
    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role(s)
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Workspace/District
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Paid Seat/License
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>
                    <th scope="col" className="relative py-3.5 ">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {/* Render users */}
                  {users.map((user, index) => {
                    // Calculate if paid user or not
                    let paid = false;

                    const proPlanExceptions = JSON.parse(
                      process.env.REACT_APP_PRO_PLAN_EXCEPTIONS || "[]"
                    );

                    if (proPlanExceptions.includes(user.email)) {
                      paid = true;
                    }

                    if (user.district && user.district.payments) {
                      const status = user.district.payments.status;
                      const activeUsers = user.district.payments.activeUsers;

                      if (
                        status === "paid" &&
                        activeUsers &&
                        activeUsers.includes(user.id)
                      ) {
                        paid = true;
                      } else if (status === "cancelled") {
                        const now = new Date();
                        if (
                          user.district.payments.subscription &&
                          user.district.payments.subscription.cancel_at
                        ) {
                          const cancelledAt = new Date(
                            user.district.payments.subscription.cancel_at
                          );
                          if (cancelledAt > now) {
                            paid = true;
                          }
                        }
                      }
                    }

                    return (
                      <tr key={index}>
                        <td
                          className="cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 hover:underline sm:pl-6"
                          onClick={() => setViewUserId(user.id)}
                        >
                          {user.firstName || "undefined"} {user.lastName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.districtRoles
                            ? user.districtRoles.join(", ")
                            : "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {user.district ? user.district.name : "-"}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {paid ? "Yes" : "No"}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment(user.createdAt).format("MM/DD/YYYY hh:mm A")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* Pagination Component */}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                totalCount={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Users</h2>
          </div>
        }
        right={renderRight}
      >
        {isFetchingCount || isFetchingUsers ? (
          <div className="mt-36 flex w-full items-center justify-center">
            <Spinner color="black" size={32} />
          </div>
        ) : (
          <div className="w-full px-20 py-2 pt-4">
            {/* Users Table */}
            {users.length > 0 ? (
              renderUsersTable()
            ) : (
              <div className="mt-8 flex w-full items-center justify-center">
                <div className="text-xl font-semibold">
                  No users found for the selected filters.
                </div>
              </div>
            )}
          </div>
        )}
      </PageLayout>
    </React.Fragment>
  );
}

export default observer(Users);
