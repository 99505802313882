import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import DepartmentCreateAndEditModal from "../components/CRUD/CreateAndEdit/Department";
import ListOfDepartments from "../components/Departments/ListOfDepartments";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Departments() {
  const [showCreateDepartmentModal, setShowCreateDepartmentModal] =
    useState(false);

  const { ui, districts, departments } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId } = ui;

  if (!activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  const districtDepartments = departments.getDepartmentsForDistrict(
    activeDistrict.id
  );

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateDepartmentModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Departments</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfDepartments
            key={districtDepartments.length}
            departments={districtDepartments}
          />
        </div>
      </PageLayout>
      {showCreateDepartmentModal && (
        <DepartmentCreateAndEditModal
          onClose={() => setShowCreateDepartmentModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateDepartmentModal(false);

            addToast("Department created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Departments);
