import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import Subject from "../../../models/Subject";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type SubjectFormState = {
  name: string;
  description: string;
  code: string;
  cedsCode: string;
  department: DropdownOptionType | null;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: SubjectFormState = {
  name: "",
  description: "",
  code: "",
  cedsCode: "",
  department: null,
};

const SUBJECT_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "Subject Name",
    placeholder: "Eg. AP Statistics",
    required: true,
  },
  {
    fieldKind: "textarea",
    id: "description",
    label: "Description",
    placeholder: "Eg. This subject is an introduction to statistics",
  },
  {
    fieldKind: "text",
    id: "code",
    label: "Code",
    placeholder: "Eg. APSTAT",
  },
  {
    fieldKind: "text",
    id: "cedsCode",
    label: "CEDS Code",
    placeholder: "Eg. 02",
  },
  {
    fieldKind: "dropdown",
    id: "department",
    label: "Department",
    placeholder: "Select the department the subject belongs to",
    required: true,
  },
];

const formReducer: Reducer<SubjectFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type SubjectCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  subject?: Subject;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function SubjectCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  subject,
  isEditing = false,
}: SubjectCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { districts, departments, subjects, ui } = useStores();

  const { isSaving, save } = subjects;

  const { getDepartmentsForDistrict } = departments;

  const init = (initialState: SubjectFormState) => {
    if (isEditing) {
      invariant(subject, "Course must be defined if editing");

      // TODO: Add logic for initializing state from district Mobx model
      return {
        ...initialState,
        name: subject.name,
        description: subject.description || "",
        code: subject.code || "",
        cedsCode: subject.cedsCode || "",
        department: {
          label: subject.departmentName || "undefined",
          value: subject.departmentId,
        },
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name, department } = state;

    console.log("Validate submit", state);

    if (name && department && department.value) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      default:
        return null;
    }
  };

  const renderDepartmentDropdown = (id: string, field: FormField) => {
    invariant(ui.activeDistrictId, "Active district must be defined");

    const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

    invariant(activeDistrict, "Active district must be defined");

    const departments = getDepartmentsForDistrict(activeDistrict.id);

    const options = departments.map((department) => ({
      label: department.name,
      value: department.id,
    }));

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          multiSelect={false}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "department":
        return renderDepartmentDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (!state.department) {
        return;
      }

      if (isEditing) {
        invariant(subject, "Subject must be defined if editing");

        // TBD: Run a check to ensure that the school is not being changed

        const updateSubject = {
          id: subject.id,
          ...state,
          cedsCode: state.cedsCode || null,
          code: state.code || null,
          description: state.description || null,
          department: {
            id: state.department.value,
          },
        };

        try {
          const res = await save(updateSubject);

          console.log("Updated subject", res);

          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating subject", err);
        }
      } else {
        try {
          invariant(ui.activeDistrictId, "Active district must be defined");

          const activeDistrict = districts.getByUrlParam(ui.activeDistrictId);

          invariant(activeDistrict, "Active district must be defined");

          const newSubject = {
            ...state,
            cedsCode: state.cedsCode || null,
            code: state.code || null,
            description: state.description || null,
            district: {
              id: activeDistrict.id,
            },
            department: {
              id: state.department.value,
            },
          };

          const res = await save(newSubject);
          console.log("Created subject", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating subject", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      subject,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Subject
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SUBJECT_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(SubjectCreateAndEditModal);
