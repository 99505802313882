import invariant from "invariant";
import { observer } from "mobx-react";
import { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import useStores from "../../../hooks/useStores";
import SectionV2 from "../../../models/SectionV2";
import { DropdownOptionType, FormAction, FormField } from "../../../types";
import {
  sectionStateEnumToDropdownOptions,
  sectionStateEnumToSelectedOption,
} from "../../../utils/classAndSectionEnums";
import { generateRandomString } from "../../../utils/RandomString";
import { EnumSectionV2State } from "../../../__generated__/graphql";
import ButtonLarge from "../../ButtonLarge";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import SlideOverModal from "../../Modals/SlideoverModal";

type SectionFormState = {
  name: string;
  description: string;
  state: DropdownOptionType | null;
  [key: string]: string | boolean | any[] | null | Date | DropdownOptionType;
};

const initialState: SectionFormState = {
  name: "",
  description: "",
  state: null,
};

const SECTION_FORM_FIELDS: FormField[] = [
  {
    fieldKind: "text",
    id: "name",
    label: "Section Name",
    placeholder: "",
    required: true,
  },
  {
    fieldKind: "textarea",
    id: "description",
    label: "Description",
    placeholder: "",
    required: false,
  },
  {
    fieldKind: "dropdown",
    id: "state",
    label: "State (required)",
    placeholder: "Select the state of the class",
  },
];

const formReducer: Reducer<SectionFormState, FormAction> = (state, action) => {
  switch (action.type) {
    case "text":
    case "textarea":
    case "dropdown":
    case "switch":
    case "datepicker":
    case "multiselect":
      return {
        ...state,
        [action.fieldID]: action.payload,
      };
    default:
      return state;
  }
};

type SectionCreateAndEditProps = {
  onClose: () => void;
  onUpdateAfterSaving: () => void;
  sectionV2?: SectionV2;
  isEditing?: boolean;
  onUpdateAfterArchiving?: () => void;
};

function SectionCreateAndEditModal({
  onClose,
  onUpdateAfterSaving,
  sectionV2,
  isEditing = false,
}: SectionCreateAndEditProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showArchiveModal, setShowArchiveModal] = useState(true);

  const { classes, districts, sections, sessions, ui } = useStores();

  const { isSaving, save } = sections;

  console.log("Section V2", sectionV2 ? sectionV2.id : null);

  const init = (initialState: SectionFormState) => {
    if (isEditing) {
      invariant(sectionV2, "ClassV2 must be defined if editing");
      return {
        ...initialState,
        name: sectionV2.name,
        description: sectionV2.description || "",
        state: sectionStateEnumToSelectedOption(sectionV2.state),
      };
    } else {
      return initialState;
    }
  };

  const [state, dispatchFormAction] = useReducer(
    formReducer,
    initialState,
    init
  );

  const validateSubmit = useCallback(() => {
    const { name, state: classState, sessions } = state;

    console.log("Validate submit", state);

    if (name && name !== "" && classState) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [state]);

  useEffect(() => {
    validateSubmit();
  }, [state, validateSubmit]);

  const renderField = (field: FormField) => {
    const { fieldKind, id } = field;

    switch (fieldKind) {
      case "text":
      case "textarea":
        return (
          <div key={id} style={{ marginBottom: "20px" }}>
            <Input
              {...field}
              name={id}
              onChange={(value) => {
                dispatchFormAction({
                  type: "text",
                  fieldID: id,
                  payload: value,
                });
              }}
              value={state[id] as string}
              multiLine={fieldKind === "textarea"}
            />
          </div>
        );
      case "dropdown":
      case "multiselect":
        return renderDropdown(id, field);
      default:
        return null;
    }
  };

  const renderStateDropdown = (id: string, field: FormField) => {
    const options = sectionStateEnumToDropdownOptions();

    return (
      <div key={id} style={{ marginBottom: "20px" }}>
        <Dropdown
          id={id}
          label={field.label}
          data={options}
          value={state[id] as DropdownOptionType}
          onChange={(value) => {
            console.log("Dropdown onChange called", value);
            dispatchFormAction({
              type: "dropdown",
              fieldID: id,
              payload: value,
            });
          }}
        />
      </div>
    );
  };

  const renderDropdown = (id: string, field: FormField) => {
    switch (id) {
      case "state":
        return renderStateDropdown(id, field);
      default:
        return null;
    }
  };

  const submitForm = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      // Disable form if already submitted and being processed
      if (isSaving) return;

      // Prevent Submission if disabled
      if (isSubmitDisabled) {
        return;
      } else {
        setIsSubmitDisabled(true);
      }

      if (!state.state || !state.name) {
        return false;
      }

      if (isEditing) {
        invariant(sectionV2, "Course must be defined if editing");

        const updateSectionV2 = {
          ...state,
          id: sectionV2.id,
          description: state.description || null,
          state: state.state.value as EnumSectionV2State,
        };

        try {
          const res = await save(updateSectionV2);
          console.log("Updated session", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error updating session", err);
        }
      } else {
        try {
          invariant(ui.activeClassId, "Active class ID must be defined");

          const activeClass = classes.getByUrlParam(ui.activeClassId);

          invariant(activeClass, "Active class must be defined");

          const newSection = {
            ...state,
            description: state.description || null,
            urlId: generateRandomString(),
            state: state.state.value as EnumSectionV2State,
            classV2: {
              id: activeClass.id,
            },
          };

          const res = await save(newSection);
          console.log("Created section", res);
          return onUpdateAfterSaving();
        } catch (err) {
          console.log("Error creating session", err);
        }
      }
    },
    [
      isEditing,
      isSaving,
      state,
      sectionV2,
      save,
      onUpdateAfterSaving,
      isSubmitDisabled,
      ui.activeDistrictId,
      districts,
    ]
  );

  return (
    <SlideOverModal isOpen={true} onCloseModal={() => onClose()}>
      <form
        className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
        onSubmit={(e) => submitForm(e)}
      >
        <div>
          {/* <!-- Header --> */}
          <div className="bg-gray-50 px-4 py-6 sm:px-6">
            <div className="flex items-start justify-between space-x-3">
              <div className="space-y-1">
                <h2
                  id="slide-over-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  {isEditing ? "Edit" : "Create"} Section
                </h2>
                <p className="text-sm text-gray-500">
                  {isEditing ? "Edit" : "Fill in"} the information below
                </p>
              </div>
              <div className="flex h-7 items-center">
                <button
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className="sr-only">Close panel</span>
                  {/* <!-- Heroicon name: x --> */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- FORM SECTION --> */}
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              {SECTION_FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>

        {/* BUTTON SECTION */}
        <div className="space-between flex flex-shrink-0 px-4 py-4">
          {isEditing && (
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="destructive"
              buttonText="Delete"
              onClick={() => setShowArchiveModal(true)}
              rounded="medium"
            />
          )}

          <div className="flex flex-1 justify-end">
            <ButtonLarge
              className="ml-4"
              type="button"
              theme="secondary"
              onClick={() => {
                onClose();
              }}
              disabled={isSaving}
              buttonText="Cancel"
              rounded="medium"
            />

            <ButtonLarge
              className="ml-4"
              type="submit"
              theme="primary"
              disabled={isSubmitDisabled || isSaving}
              buttonText={isEditing ? "Save" : "Create"}
              rounded="medium"
            />
          </div>
        </div>
      </form>
    </SlideOverModal>
  );
}

export default observer(SectionCreateAndEditModal);
