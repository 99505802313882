import { observer } from "mobx-react";
import React, { useState } from "react";
import { useToastsContext } from "../../../contexts/toasts";
import Subject from "../../../models/Subject";
import { DashboardBreakpoint } from "../../../types";
import Button from "../../Button";
import SubjectCreateAndEditModal from "../../CRUD/CreateAndEdit/Subject";

type SubjectItemsProps = {
  breakpoint: DashboardBreakpoint;
  subject: Subject;
};

function SubjectItem({ breakpoint, subject }: SubjectItemsProps) {
  const [showEditSubjectModal, setShowEditSubjectModal] = useState(false);

  const { addToast } = useToastsContext();

  const renderSubjectItemLg = () => (
    <tr key={subject.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {subject.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {subject.code}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {subject.departmentName}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditSubjectModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderSubjectItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditSubjectModal && (
        <SubjectCreateAndEditModal
          isEditing={true}
          subject={subject}
          onClose={() => setShowEditSubjectModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditSubjectModal(false);

            addToast("Subject updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderSubjectItemLg() : renderSubjectItemSm()}
    </React.Fragment>
  );
}

export default observer(SubjectItem);
