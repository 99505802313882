import { observer } from "mobx-react";
import React, { useState } from "react";
import Button from "../components/Button";
import ListOfCourses from "../components/Courses/ListOfCourses";
import CourseCreateAndEditModal from "../components/CRUD/CreateAndEdit/Course";
import PageLayout from "../components/PageLayout";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";

function Courses() {
  const [showCreateCourseModal, setShowCreateCourseModal] = useState(false);

  const { ui, districts, courses } = useStores();

  const { addToast } = useToastsContext();

  const { activeDistrictId } = ui;

  if (!activeDistrictId) return null;

  const activeDistrict = districts.getByUrlParam(activeDistrictId);

  if (!activeDistrict) return null;

  const districtCourses = courses.getCoursesForDistrict(activeDistrict.id);

  const renderRight = (
    <div className="flex items-center">
      <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateCourseModal(true)}
      />
    </div>
  );

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Courses</h2>
          </div>
        }
        right={renderRight}
      >
        <div className="w-full px-20 py-2 pt-4">
          <ListOfCourses
            key={districtCourses.length}
            courses={districtCourses}
          />
        </div>
      </PageLayout>
      {showCreateCourseModal && (
        <CourseCreateAndEditModal
          onClose={() => setShowCreateCourseModal(false)}
          onUpdateAfterSaving={() => {
            setShowCreateCourseModal(false);

            addToast("Course created successfully", {
              type: "success",
            });
          }}
        />
      )}
    </React.Fragment>
  );
}

export default observer(Courses);
