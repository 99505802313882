//
import { MbscSelectedDateChangeEvent } from "@mobiscroll/react";
import { EventcalendarBase } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";
import invariant from "invariant";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import SharedCalendarViewDropdown from "../components/Dropdown/SharedCalendarViewDropdown";
import EventsCalendar from "../components/EventsCalendar";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import PageLayout from "../components/PageLayout";
import Tooltip from "../components/Tooltip";
import { useToastsContext } from "../contexts/toasts";
import useStores from "../hooks/useStores";
import Calendar from "../models/Calendar";
import RotationSchedule from "../models/RotationSchedule";
import Session from "../models/Session";
import { OfflineError } from "../utils/errors";
import { homeRoute } from "../utils/routeHelper";
import NoMatch from "./NoMatch";
import Offline from "./Offline";

export function capitalizeFirstLetter(word: string) {
  if (typeof word !== "string" || word.length === 0) {
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const dateDiffInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / (24 * 3600 * 1000)));
};

const getFirstDayOfWeek = (current: Date, previous: boolean) => {
  const day = current.getDay();
  const diff = current.getDate() - day + (previous ? -7 : 7);
  return new Date(current.setDate(diff));
};

function SharedCalendar() {
  const { calendarId = "" } = useParams();

  const [mobiscrollView, setMobiscrollView] = useState<any>({
    schedule: { type: "week" },
  });

  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [calendarMin, setCalendarMin] = useState<Date | null>(null);
  const [calendarMax, setCalendarMax] = useState<Date | null>(null);

  const { ui, districts, calendars, calendarEvents, sessions } = useStores();

  const {
    sharedCalendarViewDate: calendarViewDate,
    setSharedCalendarCurrentDate: setCurrentDate,
  } = calendarEvents;

  const { sharedCalendarView: calendarView } = ui;

  const navigate = useNavigate();

  const calendar: Calendar | undefined = calendars.getByUrlParam(calendarId);

  const activeSession: Session | null = calendar ? calendar.session : null;

  const { addToast } = useToastsContext();

  const activeRotationSchedule: RotationSchedule | null = calendar
    ? calendar.rotationSchedule
    : null;

  const [loadCalendarError, setLoadCalendarError] = useState<Error | null>(
    null
  );

  useEffect(() => {
    //
    if (!calendarId) {
      navigate(homeRoute());
    }

    async function fetchCalendar() {
      try {
        await calendars.fetchByUrlId(calendarId);
      } catch (e: any) {
        console.log("Error in fetchCalendar", e);
        setLoadCalendarError(e);
      }
    }
    fetchCalendar();
  }, [calendarId, navigate, calendars]);

  // Set current view date for calendar
  useEffect(() => {
    if (!calendar || !activeSession) return;

    let currentDateTime: DateTime = DateTime.fromJSDate(new Date(), {
      zone: calendar.timezone || "utc",
    });

    let currentDate: Date;

    const { startDate, endDate } = activeSession;

    invariant(startDate && endDate, "Session must have start and end date");

    const calendarMin = DateTime.fromISO(startDate, {
      zone: calendar.timezone || "utc",
    });

    const calendarMax = DateTime.fromISO(endDate, {
      zone: calendar.timezone || "utc",
    });

    if (currentDateTime < calendarMin) {
      currentDate = calendarMin.toJSDate();
    } else if (currentDateTime > calendarMax) {
      currentDate = calendarMax.toJSDate();
    } else {
      currentDate = currentDateTime.toJSDate();
    }

    setCurrentDate(currentDate);
    setCalendarMin(calendarMin.toJSDate());
    setCalendarMax(calendarMax.toJSDate());

    return () => {
      setCurrentDate(null);
      setCalendarMin(null);
      setCalendarMax(null);
    };
  }, [
    ui,
    calendarEvents,
    districts,
    sessions,
    activeSession,
    calendar,
    setCurrentDate,
  ]);

  // Handle calendar view change
  useEffect(() => {
    let updatedView: any;

    switch (calendarView) {
      case "month":
        updatedView = {
          calendar: {
            labels: true,
          },
        };
        break;
      case "week":
        updatedView = {
          schedule: {
            type: "week",
            startTime: "07:00",
            endTime: "17:00",
          },
        };
        break;
      case "day":
        updatedView = {
          schedule: {
            type: "day",
            startDay: calendarViewDate ? calendarViewDate.getDay() : 0,
            endDay: calendarViewDate ? calendarViewDate.getDay() : 0,
            startTime: "07:00",
            endTime: "17:00",
          },
        };
        break;
      default:
        updatedView = {
          schedule: {
            type: "week",
            startTime: "07:00",
            endTime: "17:00",
          },
        };
    }

    setMobiscrollView(updatedView);
  }, [calendarView, calendarViewDate]);

  useEffect(() => {
    if (calendarViewDate) {
      const date = calendarViewDate as Date;

      // Long Month
      const month = date.toLocaleString("default", { month: "long" });

      // Year
      const year = date.getFullYear();

      setCurrentMonth(month);
      setCurrentYear(year.toString());
    }
  }, [calendarViewDate]);

  const goToToday = () => {
    const date = new Date();
    if (calendarMin && date < new Date(calendarMin)) {
      const minDate = new Date(calendarMin);
      setCurrentDate(minDate);
      if (mobiscrollView.schedule && mobiscrollView.schedule.type === "day") {
        setMobiscrollView({
          schedule: {
            type: "day",
            startDay: minDate.getDay(),
            endDay: minDate.getDay(),
          },
        });
      }
    } else if (calendarMax && date > new Date(calendarMax)) {
      const maxDate = new Date(calendarMax);
      setCurrentDate(maxDate);
      if (mobiscrollView.schedule && mobiscrollView.schedule.type === "day") {
        setMobiscrollView({
          schedule: {
            type: "day",
            startDay: maxDate.getDay(),
            endDay: maxDate.getDay(),
          },
        });
      }
    } else {
      if (mobiscrollView.schedule && mobiscrollView.schedule.type === "day") {
        setMobiscrollView({
          schedule: {
            type: "day",
            startDay: date.getDay(),
            endDay: date.getDay(),
          },
        });
      }
      setCurrentDate(date);
    }
  };

  const navigatePage = (previous: boolean) => {
    if (!calendarViewDate) {
      return;
    }

    if (calendarView === "month") {
      const prevNextPage = new Date(
        calendarViewDate.getFullYear(),
        calendarViewDate.getMonth() + (previous ? -1 : 1),
        1
      );
      if (
        (calendarMin &&
          prevNextPage.getMonth() < new Date(calendarMin).getMonth()) ||
        (calendarMax &&
          prevNextPage.getMonth() > new Date(calendarMax).getMonth())
      ) {
        return;
      }
      setCurrentDate(prevNextPage);
    } else if (calendarView === "week") {
      // week
      const date = new Date(calendarViewDate);
      const prevNextSunday = getFirstDayOfWeek(date, previous);
      const minDate = calendarMin ? new Date(calendarMin) : new Date();
      const maxDate = calendarMax ? new Date(calendarMax) : new Date();
      if (
        (calendarView === "week" &&
          calendarMin &&
          prevNextSunday < minDate &&
          dateDiffInDays(minDate, prevNextSunday) >= 7) ||
        (calendarMax &&
          prevNextSunday > maxDate &&
          dateDiffInDays(maxDate, prevNextSunday) >= 7)
      ) {
        return;
      }
      setCurrentDate(prevNextSunday);
    } else {
      if (previous) {
        const prevDate = calendarViewDate
          ? new Date(calendarViewDate)
          : new Date();
        prevDate.setDate(prevDate.getDate() - 1);
        if (calendarMin && prevDate < new Date(calendarMin)) {
          return;
        }
        setCurrentDate(prevDate);
      } else {
        const nextDate = calendarViewDate
          ? new Date(calendarViewDate)
          : new Date();
        nextDate.setDate(nextDate.getDate() + 1);
        if (calendarMax && nextDate > new Date(calendarMax)) {
          return;
        }
        setCurrentDate(nextDate);
      }
    }
  };

  const onSelectedDateChange = (
    event: MbscSelectedDateChangeEvent,
    inst: EventcalendarBase
  ) => {
    setCurrentDate(event.date as Date);
  };

  const renderRight = (
    <div className="flex items-center">
      <SharedCalendarViewDropdown>
        <Tooltip content="Change view" side="bottom" asChild={false}>
          <Button
            type="button"
            theme="secondary"
            className="mr-2"
            rounded={"medium"}
            icon="chevron-down"
            iconPosition="right"
            buttonText={capitalizeFirstLetter(calendarView)}
          />
        </Tooltip>
      </SharedCalendarViewDropdown>

      <Tooltip content="Go to today" side="bottom" asChild={false}>
        <Button
          type="button"
          theme="secondary"
          className="mr-2"
          rounded={"medium"}
          buttonText="Today"
          onClick={goToToday}
        />
      </Tooltip>
      <Tooltip content="Move view left" side="bottom" asChild={false}>
        <Button
          type="button"
          theme="secondary"
          className="mr-2"
          width={"24"}
          rounded={"medium"}
          icon="chevron-left"
          onClick={() => navigatePage(true)}
        />
      </Tooltip>
      <Tooltip content="Move view right" side="bottom" asChild={false}>
        <Button
          type="button"
          theme="secondary"
          className="mr-3"
          width={"24"}
          rounded={"medium"}
          icon="chevron-right"
          onClick={() => navigatePage(false)}
        />
      </Tooltip>
      {/* <Button
        type="button"
        theme="primary"
        className="mr-3"
        icon="plus"
        buttonText="New"
        padding="medium"
        rounded="medium"
        onClick={() => setShowCreateAlternateDayModal(true)}
      /> */}
    </div>
  );

  if (loadCalendarError) {
    return loadCalendarError instanceof OfflineError ? (
      <Offline />
    ) : (
      <NoMatch />
    );
  }

  if (calendars.isLoading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner color="black" size={24} />
        </div>
      </div>
    );
  }

  console.log("calendar", calendar);
  console.log("calendarViewDate", calendarViewDate);
  console.log("activeRotationSchedule", activeRotationSchedule);
  console.log("Calendar min", calendarMin);
  console.log("Calendar max", calendarMax);
  console.log(
    "Rotation schedule active calendar",
    activeRotationSchedule?.activeCalendar
  );

  //
  // Return null
  if (
    !calendar ||
    !calendarViewDate ||
    !activeRotationSchedule ||
    !activeRotationSchedule.activeCalendar
  ) {
    return null;
  }

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div className="flex items-center">
            <div className="mr-4 flex items-center">
              <div className="mr-2 text-2xl font-bold">{currentMonth}</div>
              <div className="text-2xl font-medium">{currentYear}</div>
            </div>
            {/* Current month */}
          </div>
        }
        right={renderRight}
        title={calendar.calendarName}
        alwaysShowTitle={true}
      >
        {/* Calendar mobiscroll */}
        {calendarMin && calendarMax && calendar && (
          <div
            className="calendar-cell-large"
            style={{
              height: calendarView === "month" ? "calc(100vh - 200px)" : "",
            }}
          >
            <EventsCalendar
              onSelectDateChange={onSelectedDateChange}
              selectedDate={calendarViewDate}
              mobiscrollView={mobiscrollView}
              min={calendarMin}
              max={calendarMax}
              calendarView={calendarView}
              eventsCalendarId={calendar.id}
              rotationCalendarId={activeRotationSchedule.activeCalendar.id}
              includeRotationCalendarEvents={true}
              calendarTimezone={calendar.timezone || "utc"}
              editCalendarDay={false}
            />
          </div>
        )}
      </PageLayout>
    </React.Fragment>
  );
}

export default observer(SharedCalendar);
