import { pick } from "lodash";
import { observable, set } from "mobx";
import { getModelAttributes } from "./decorators/Attribute";

// This is a base class for models that are useful for representing models that are persisted in the database on the front-end
export default abstract class PersistModel {
  @observable
  id: string;

  @observable
  saving: boolean;

  @observable
  newlyCreated: boolean;

  @observable
  createdAt: string;

  @observable
  updatedAt: string;

  store: any;

  protected attributesStore: Partial<PersistModel> = {};

  constructor(fields: Record<string, any>, store: any) {
    this.updateFromJson(fields);
    this.newlyCreated = !this.id;
    this.store = store;
  }

  save = async (args?: Record<string, any>) => {
    this.saving = true;

    try {
      // ensure that the id is passed if the document has one
      if (!args) {
        args = this.toGQLAttributes();
      }

      const model = await this.store.save({
        ...args,
        id: this.id,
      });

      // if saving is successful set the new values on the model itself
      set(this, { ...args, ...model, newlyCreated: false });

      this.attributesStore = this.toGQLAttributes();

      return model;
    } finally {
      this.saving = false;
    }
  };

  updateFromJson = (data: any) => {
    set(this, { ...data, newlyCreated: false });
    this.attributesStore = this.toGQLAttributes();
  };

  fetch = (options?: any) => {
    return this.store.fetch(this.id, options);
  };

  refresh = () => {
    return this.fetch({
      force: true,
    });
  };

  delete = async () => {
    this.saving = true;

    try {
      return await this.store.delete(this);
    } finally {
      this.saving = false;
    }
  };

  toGQLAttributes = (): Record<string, any> => {
    const fields = getModelAttributes(this);
    return pick(this, fields) || [];
  };

  toJSON() {
    const output: Partial<typeof this> = {};

    for (const property in this) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        this.hasOwnProperty(property) &&
        !["attributesStore", "store", "saving", "newlyCreated"].includes(
          property
        )
      ) {
        output[property] = this[property];
      }
    }

    return output;
  }

  hasBeenModified(): boolean {
    const attributes = this.toGQLAttributes();

    if (Object.keys(attributes).length === 0) {
      console.warn("Object has no @Attributes");
    }

    return JSON.stringify(this.attributesStore) !== JSON.stringify(attributes);
  }
}
