import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react";
import React, { useState } from "react";
import EnrollmentCreateAndEditModal from "../../../components/CRUD/CreateAndEdit/Enrollment";
import { useToastsContext } from "../../../contexts/toasts";
import Enrollment from "../../../models/Enrollment";
import { DashboardBreakpoint } from "../../../types";
import { classStateToStringMap } from "../../../utils/classAndSectionEnums";
import { EnumEnrollmentState } from "../../../__generated__/graphql";
import Button from "../../Button";

type ClassItemsProps = {
  breakpoint: DashboardBreakpoint;
  enrollment: Enrollment;
};

function EnrollmentItem({ breakpoint, enrollment }: ClassItemsProps) {
  const [showEditEnrollmentModal, setShowEditEnrollmentModal] = useState(false);
  const { addToast } = useToastsContext();

  const renderEnrollmentStateBadge = (sessionState: EnumEnrollmentState) => {
    switch (sessionState) {
      case EnumEnrollmentState.Active:
        return (
          <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      case EnumEnrollmentState.Completed:
        return (
          <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
            {classStateToStringMap[sessionState]}
          </span>
        );
      //   case EnumEnrollmentState.Archived:
      //     return (
      //       <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      //         {classStateToStringMap[sessionState]}
      //       </span>
      //     );

      //   case EnumEnrollmentState.Inactive:
      //     return (
      //       <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">
      //         {classStateToStringMap[sessionState]}
      //       </span>
      //     );
      //   case EnumEnrollmentState.Pending:
      //     return (
      //       <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      //         {classStateToStringMap[sessionState]}
      //       </span>
      //     );
      case EnumEnrollmentState.Upcoming:
        return (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
            {classStateToStringMap[sessionState]}
          </span>
        );

      default:
        return null;
    }
  };

  const renderClassItemLg = () => (
    <tr key={enrollment.id}>
      {/* <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {sectionV2.name}
      </td>
  */}
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {enrollment.fullName || "N/A"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {renderEnrollmentStateBadge(enrollment.state)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {enrollment.primary ? (
          <CheckBadgeIcon className=" text-green-800" height={20} width={20} />
        ) : null}
      </td>
      {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sectionV2.sessionNames}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {sectionV2.courseName}
      </td> */}
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditEnrollmentModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderClassItemSm = () => <div />;

  // console.log("showEditEnrollmentModal", showEditEnrollmentModal);

  return (
    <React.Fragment>
      {showEditEnrollmentModal && (
        <EnrollmentCreateAndEditModal
          isEditing={true}
          enrollment={enrollment}
          onClose={() => setShowEditEnrollmentModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditEnrollmentModal(false);
            addToast("Class created successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderClassItemLg() : renderClassItemSm()}
    </React.Fragment>
  );
}

export default observer(EnrollmentItem);
