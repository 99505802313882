import invariant from "invariant";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useToastsContext } from "../../../contexts/toasts";
import District from "../../../models/District";
import { DashboardBreakpoint } from "../../../types";
import { districtRoute } from "../../../utils/routeHelper";
import Button from "../../Button";
import DistrictCreateAndEditModal from "../../CRUD/CreateAndEdit/District";

import BlackbaudLogo from "../../../assets/integrationLogos/BlackBaud.png";
import CanvasLogo from "../../../assets/integrationLogos/Canvas.png";
import GoogleClassroomLogo from "../../../assets/integrationLogos/GoogleClassroom.png";
import PowerschoolLogo from "../../../assets/integrationLogos/Powerschool.webp";
import SchoologyLogo from "../../../assets/integrationLogos/Schoology.jpeg";
import useStores from "../../../hooks/useStores";

type DistrictItemProps = {
  breakpoint: DashboardBreakpoint;
  district: District;
};

function DistrictItem({ breakpoint, district }: DistrictItemProps) {
  const [showEditDistrictModal, setShowEditDistrictModal] = useState(false);

  const { addToast } = useToastsContext();

  const { districts } = useStores();

  const renderEdlinkIntegration = () => {
    invariant(
      district.edlinkIntegrations,
      "District should have at least one edlink integration"
    );

    const edlinkIntegration = district.edlinkIntegrations[0];

    switch (edlinkIntegration.providerApplication) {
      case "canvas":
        return <img src={CanvasLogo} alt="Canvas" className="h-8 w-8" />;

      case "google":
        return (
          <img
            src={GoogleClassroomLogo}
            alt="Google Classroom"
            className="h-8 w-8"
          />
        );

      case "schoology":
        return <img src={SchoologyLogo} alt="Schoology" className="h-8 w-8" />;

      case "blackbaud":
        return <img src={BlackbaudLogo} alt="Blackbaud" className="h-8 w-8" />;

      case "powerschool":
        return (
          <img src={PowerschoolLogo} alt="Powerschool" className="h-8 w-8" />
        );
      default:
        return <div>{edlinkIntegration.providerName}</div>;
    }
  };

  const renderDistrictItemLg = () => (
    <tr key={district.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Link to={districtRoute(district)} className="hover:underline">
          {district.name}
        </Link>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {district.numberOfSeats}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {district.schoolsCount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {district.edlinkIntegrationsCount > 0 ? renderEdlinkIntegration() : "-"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {district.edlinkIntegrationsCount > 0 && district.edlinkIntegrations
          ? district.edlinkIntegrations[0].ssoAvailable
            ? "Yes"
            : "No"
          : "-"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {district.usersCount}
      </td>
      <td className="relative whitespace-nowrap py-4 text-right text-sm font-medium ">
        <Button
          onClick={() => setShowEditDistrictModal(true)}
          type="button"
          theme="editor"
          buttonText="Edit"
          padding="medium"
          width="48"
        />
      </td>
    </tr>
  );

  // TBD
  const renderDistrictItemSm = () => <div />;

  return (
    <React.Fragment>
      {showEditDistrictModal && (
        <DistrictCreateAndEditModal
          isEditing={true}
          district={district}
          onClose={() => setShowEditDistrictModal(false)}
          onUpdateAfterSaving={() => {
            setShowEditDistrictModal(false);

            districts.fetchAllDistricts();

            addToast("District updated successfully", {
              type: "success",
            });
          }}
        />
      )}
      {breakpoint === "lg" ? renderDistrictItemLg() : renderDistrictItemSm()}
    </React.Fragment>
  );
}

export default observer(DistrictItem);
