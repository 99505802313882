import AlternateSchedule from "../models/AlternateSchedule";
import Block from "../models/Block";
import ClassV2 from "../models/ClassV2";
import District from "../models/District";
import DistrictUser from "../models/DistrictUser";
import RotationSchedule from "../models/RotationSchedule";
import School from "../models/School";
import Session from "../models/Session";

export function homeRoute(): string {
  return "/";
}

export function districtsRoute(): string {
  return "/organizations";
}

export function usersRoute(): string {
  return "/users";
}

export function chatsRoute(): string {
  return "/chats";
}

export function toolsRoute(): string {
  return "/tools";
}

export function resourcesRoute(): string {
  return "/resources";
}

export function documentsRoute(): string {
  return "/documents";
}

export function coursesRoute(): string {
  return "/courses";
}

export function paymentsRoute(): string {
  return "/payments";
}

export function analyticsRoute(): string {
  return "/analytics";
}

export function districtRoute(district: District, tab?: string): string {
  if (tab) {
    return `/district/${district.urlId}/${tab}`;
  }

  return `/district/${district.urlId}/users`;
}

export function districtSchedulingRoute(
  district: District,
  session: Session,
  tab?: string
): string {
  if (tab) {
    return `/district/${district.urlId}/session/${session.urlId}/${tab}`;
  }

  return `/district/${district.urlId}/session/${session.urlId}/calendar`;
}

export function districtScheduleRotationDaysRoute(
  district: District,
  session: Session,
  rotationSchedule: RotationSchedule
): string {
  return `/district/${district.urlId}/session/${session.urlId}/rotations/${rotationSchedule.id}`;
}

export function districtSchoolRoute(
  district: District,
  school: School,
  tab?: string
): string {
  if (tab) {
    return `/district/${district.urlId}/school/${school.urlId}/${tab}`;
  }

  return `/district/${district.urlId}/school/${school.urlId}/classes`;
}

export function districtSchoolClassRoute(
  district: District,
  school: School,
  classV2: ClassV2,
  tab?: string
): string {
  if (tab) {
    return `/district/${district.urlId}/school/${school.urlId}/class/${classV2.urlId}/${tab}`;
  }

  return `/district/${district.urlId}/school/${school.urlId}/class/${classV2.urlId}/sections`;
}

export function userRoute(user: DistrictUser, tab?: string): string {
  if (tab) {
    return `/user/${user.id}/${tab}`;
  }

  return `/user/${user.id}/home`;
}

export function districtScheduleBlockRoute(
  district: District,
  session: Session,
  block: Block
): string {
  return `/district/${district.urlId}/session/${session.urlId}/blocks/${block.id}`;
}

export function districtScheduleAlternateDayRoute(
  district: District,
  session: Session,
  alternateSchedule: AlternateSchedule
): string {
  return `/district/${district.urlId}/session/${session.urlId}/alternate-schedules/${alternateSchedule.id}`;
}

export function districtScheduleClassTimeblockingRoute(
  district: District,
  session: Session,
  classV2: ClassV2
): string {
  return `/district/${district.urlId}/session/${session.urlId}/class-schedules/${classV2.urlId}`;
}
