import { useApolloClient } from "@apollo/client";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import Dropdown from "../components/Dropdown";
import { Spinner } from "../components/LoadingIndicators/Spinner";
import SlideOverWide from "../components/Modals/SlideoverWideModal";
import PageLayout from "../components/PageLayout";
import Pagination from "../components/Pagination";
import { useToastsContext } from "../contexts/toasts";
import { GetAITools, GetAIToolsMeta } from "../graphql/aiTools/aiTools.queries";
import useStores from "../hooks/useStores";
import { DropdownOptionType } from "../types";
import { AiTool, SortOrder } from "../__generated__/graphql";

const timeFilterOptions = [
  { id: "24hours", name: "24h", href: "#", current: true },
  { id: "7days", name: "7d", href: "#", current: true },
  { id: "30days", name: "30d", href: "#", current: false },
  { id: "allTime", name: "All", href: "#", current: false },
];

function Tools() {
  const { addToast } = useToastsContext();

  const { users } = useStores();

  const [userFilterOptions, setUserFilterOptions] = useState<
    DropdownOptionType[]
  >([]);

  const [userFilter, setUserFilter] = useState<DropdownOptionType>({
    value: "all",
    label: "All Users",
  });
  const [toolTypeFilter, setToolTypeFilter] = useState({
    value: "all",
    label: "All Tools",
  });
  const [tools, setTools] = useState<AiTool[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = Number(process.env.REACT_APP_PAGE_SIZE || 10);
  const [isFetchingCount, setIsFetchingCount] = useState(false);
  const [isFetchingTools, setIsFetchingTools] = useState(false);
  const [currentTimeFilter, setCurrentTimeFilter] = useState("24hours");
  const [error, setError] = useState<any>(null);
  const [viewToolId, setViewToolId] = useState<string | null>(null);

  useEffect(() => {
    setUserFilterOptions([
      {
        value: "all",
        label: "All Users",
      },
      ...users.sortedData.map((user) => {
        return {
          value: user.id,
          label: user.firstName + " " + user.lastName + " (" + user.email + ")",
        };
      }),
    ] as DropdownOptionType[]);
  }, [users.sortedData]);

  const apolloClient = useApolloClient();

  const toolTypeFilterOptions = [
    { id: "all", name: "All Tools", href: "#", current: true },
    {
      id: "ai-lesson-planner",
      name: "Lesson Planner",
      href: "#",
      current: false,
    },
    {
      id: "ai-scope-and-sequencing",
      name: "Scope & Sequencing Tool",
      href: "#",
      current: false,
    },
    {
      id: "ai-unit-planner-ubd",
      name: "Unit Planner",
      href: "#",
      current: false,
    },
    {
      id: "ai-science-lab-generator",
      name: "Science Lab Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-quiz-generator",
      name: "Quiz Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-learning-activity-generator",
      name: "Learning Activity Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-rubric-generator",
      name: "Rubric Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-syllabus-generator",
      name: "Syllabus Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-text-leveler",
      name: "Text Rewrite Tool",
      href: "#",
      current: false,
    },
    {
      id: "ai-ela-content-generator",
      name: "ELA Content Generator",
      href: "#",
      current: false,
    },
    {
      id: "ai-email-generator",
      name: "Email Generator",
      href: "#",
      current: false,
    },
  ];

  const toolIdToNameMap: { [key: string]: string } = {
    "ai-lesson-planner": "Lesson Planner",
    "ai-scope-and-sequencing": "Scope & Sequencing Tool",
    "ai-unit-planner-ubd": "Unit Planner",
    "ai-science-lab-generator": "Science Lab Generator",
    "ai-quiz-generator": "Quiz Generator",
    "ai-learning-activity-generator": "Learning Activity Generator",
    "ai-rubric-generator": "Rubric Generator",
    "ai-syllabus-generator": "Syllabus Generator",
    "ai-text-leveler": "Text Rewrite Tool",
    "ai-ela-content-generator": "ELA Content Generator",
    "ai-email-generator": "Email Generator",
  };

  const timeFilterDate = useMemo(() => {
    const currentDate = new Date();
    // Calculate time filter based on current time & selected time filter
    // Idea is to get the date from 7 days ago, 30 days ago, or all time
    // and then filter the tools based on that date
    let timeFilterDate = new Date();

    if (currentTimeFilter === "24hours") {
      timeFilterDate.setDate(currentDate.getDate() - 1);
    } else if (currentTimeFilter === "7days") {
      timeFilterDate.setDate(currentDate.getDate() - 7);
    } else if (currentTimeFilter === "30days") {
      timeFilterDate.setDate(currentDate.getDate() - 30);
    } else {
      timeFilterDate = new Date(0); // All time
    }

    return timeFilterDate;
  }, [currentTimeFilter]);

  const selectedTool = useMemo(() => {
    return tools.find((tool) => tool.id === viewToolId);
  }, [viewToolId, tools]);

  const fetchTotalCount = async () => {
    setIsFetchingCount(true);

    try {
      const toolCount = await apolloClient.query({
        query: GetAIToolsMeta,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            aiToolParticipants:
              userFilter && userFilter.value !== "all"
                ? {
                    some: {
                      user: {
                        id: userFilter.value,
                      },
                    },
                  }
                : undefined,
            toolId:
              toolTypeFilter.value !== "all"
                ? {
                    equals: toolTypeFilter.value,
                    not: "ai-differentiated-resource-generator",
                  }
                : {
                    not: "ai-differentiated-resource-generator",
                  },
          },
        },
      });

      if (!toolCount.data || !toolCount.data._aiToolsMeta) {
        addToast("There was an error fetching tools.", {
          type: "error",
        });
        return;
      }

      console.log("Total Count", toolCount.data._aiToolsMeta.count);

      setTotalPages(Math.ceil(toolCount.data._aiToolsMeta.count / pageSize));
      setTotalCount(toolCount.data._aiToolsMeta.count);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching tools.", {
        type: "error",
      });
    } finally {
      setIsFetchingCount(false);
    }
  };

  const fetchTools = async () => {
    setIsFetchingTools(true);

    try {
      const tools = await apolloClient.query({
        query: GetAITools,
        variables: {
          where: {
            createdAt: {
              gte: timeFilterDate.toISOString(),
            },
            aiToolParticipants:
              userFilter && userFilter.value !== "all"
                ? {
                    some: {
                      user: {
                        id: userFilter.value,
                      },
                    },
                  }
                : undefined,
            toolId:
              toolTypeFilter.value !== "all"
                ? {
                    equals: toolTypeFilter.value,
                    not: "ai-differentiated-resource-generator",
                  }
                : {
                    not: "ai-differentiated-resource-generator",
                  },
          },
          orderBy: [{ createdAt: SortOrder.Desc }],
          take: pageSize,
          skip: (currentPage - 1) * pageSize,
        },
      });

      if (!tools.data || !tools.data.aiTools) {
        addToast("There was an error fetching tools.", {
          type: "error",
        });
        return;
      }

      setTools(tools.data.aiTools as AiTool[]);

      const participants = tools.data.aiTools.map((tool: any) => {
        return tool.aiToolParticipants.find(
          (participant: any) => participant.owner === true
        );
      });

      // Filter out userFilterOptions based on actual participants
      const filteredUserFilterUsers = users.sortedData.filter((option) => {
        return participants.find(
          (participant: any) => participant?.user.id === option.id
        );
      });

      setUserFilterOptions([
        {
          value: "all",
          label: "All Users",
        },
        ...filteredUserFilterUsers.map((user) => {
          return {
            value: user.id,
            label:
              user.firstName + " " + user.lastName + " (" + user.email + ")",
          };
        }),
      ] as DropdownOptionType[]);
    } catch (e) {
      setError(e);
      console.log(e);
      addToast("There was an error fetching tools.", {
        type: "error",
      });
    } finally {
      setIsFetchingTools(false);
    }
  };

  const fetchToolsFeedback = async () => {};

  useEffect(() => {
    fetchTotalCount();
    fetchTools();
  }, [
    currentPage,
    currentTimeFilter,
    userFilter,
    toolTypeFilter,
    timeFilterDate,
  ]);

  // If filter changes, reset page to 1
  useEffect(() => {
    setCurrentPage(1);
  }, [userFilter, toolTypeFilter, currentTimeFilter]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const renderRight = (
    <div className="flex items-center">
      <div className="flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap ">
        <div className="m:leading-7 bg-slate2 order-last flex w-full space-x-1 rounded-md text-sm font-semibold leading-6 sm:order-none sm:w-auto">
          {timeFilterOptions.map((item: any) => (
            <button
              key={item.id}
              className={
                currentTimeFilter === item.id
                  ? "border-slate4 rounded-md border border-solid bg-black px-2 py-1 text-white shadow"
                  : "bg-slate2 rounded-md px-2  py-1 text-gray-700"
              }
              onClick={() => {
                setCurrentTimeFilter(item.id);
              }}
            >
              {item.name}
            </button>
          ))}
        </div>
        {/* Render User filter */}
        {!isFetchingTools && userFilterOptions.length > 0 && (
          <div className="flex w-[200px]">
            <Dropdown
              id="userFilter"
              value={userFilter}
              onChange={(val: any) => setUserFilter(val)}
              data={userFilterOptions}
              placeholder="Filter by user"
            />
          </div>
        )}
        {/* Tool Type filter */}
        <div className="flex w-[200px]">
          <Dropdown
            id="toolTypeFilter"
            value={toolTypeFilter}
            onChange={(val: any) => setToolTypeFilter(val)}
            data={toolTypeFilterOptions.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })}
            placeholder="Filter by tool type"
          />
        </div>
      </div>
    </div>
  );

  const renderSelectedTool = () => {
    if (!selectedTool) {
      return null;
    }

    const { aiToolSteps, aiToolParticipants, title, initData, data, toolId } =
      selectedTool;

    const user = aiToolParticipants.find(
      (participant: any) => participant.owner === true
    )?.user;

    let initialTopic;
    let finalOutput;

    const { gradeLevel, subject } = initData;

    switch (toolId) {
      case "ai-learning-activity-generator":
      case "ai-unit-planner-ubd":
      case "ai-science-lab-generator":
        finalOutput = data.finalOutput && data.finalOutput[0];
        break;
      case "ai-ela-content-generator":
        const readingContentStep = aiToolSteps.find(
          (step: any) => step.stepId === "readingContent"
        );

        if (!readingContentStep || !readingContentStep.data.completed) {
          break;
        }

        const jsonData = readingContentStep.data;

        const activeText = jsonData.texts.find(
          (text: any) => text.current
        ).text;
        const lessonHook = jsonData.lessonHook;
        const keyVocabulary = jsonData.keyVocabulary;
        const mainIdeaAndTheme = jsonData.mainIdeaAndTheme;
        const discussionQuestions = jsonData.discussionQuestions;
        const eslSupportResources = jsonData.eslSupportResources; // Optional

        const combinedString1 = `
            ${activeText.trim()}

            ## Lesson Hook:
            ${lessonHook.trim()}

            ## Key Vocabulary: 
            ${keyVocabulary.trim()}

            ## Main Idea and Theme: 
            ${mainIdeaAndTheme.trim()}

            ## Discussion Questions: 
            ${discussionQuestions.trim()}

            ## ESL Support Resources (Optional): 
            ${eslSupportResources.trim()}
        `;

        const activitiesStep = aiToolSteps.find(
          (step: any) => step.stepId === "activities"
        );

        if (!activitiesStep) {
          finalOutput = combinedString1;
          break;
        }

        const activitiesJsonData = activitiesStep.data;
        const quiz = activitiesJsonData.quiz;
        const games = activitiesJsonData.games;
        const flashcards = activitiesJsonData.flashcards;
        const writingPrompts = activitiesJsonData.writingPrompts;
        const literaryAnalysis = activitiesJsonData.literaryAnalysis;

        const combinedString2 = `
            ## Quiz: 
            ${quiz.trim()}
             
            ## Games: 
            ${games.trim()}
                         
            ## Flashcards: 
            ${flashcards.trim()}
             
            ## Writing Prompts: 
            ${writingPrompts.trim()}
                         
            ## Literary Analysis: 
            ${literaryAnalysis.trim()}
             
        `;

        finalOutput =
          combinedString1.trim() + " \n \n" + combinedString2.trim();
        break;

      default:
        // Find the last step in the tool steps (updatedAt)
        const lastStep = aiToolSteps.reduce((prev: any, current: any) => {
          return prev.updatedAt > current.updatedAt ? prev : current;
        });

        console.log("Last Step", lastStep);

        finalOutput =
          lastStep.data.llmOutput.value &&
          lastStep.data.llmOutput.value[
            lastStep.data.llmOutput.value.length - 1
          ];
        break;
    }

    switch (toolId) {
      case "ai-learning-activity-generator":
        initialTopic = initData.learningActivityTitle;
        break;
      case "ai-lesson-planner":
        initialTopic = initData.lessonTopic;
        break;
      case "ai-quiz-generator":
        initialTopic = initData.quizTopic;
        break;
      case "ai-rubric-generator":
        initialTopic = initData.assessmentTitle;
        break;
      case "ai-science-lab-generator":
        initialTopic = initData.labTopic;
        break;
      case "ai-scope-and-sequencing":
        initialTopic = initData.topic;
        break;
      case "ai-syllabus-generator":
        initialTopic = initData.courseTitle;
        break;
      case "ai-unit-planner-ubd":
        initialTopic = initData.unitTopic;
        break;
      default:
        initialTopic = title;
        break;
    }

    return (
      <div className="flex w-full flex-col space-y-8">
        {/* Title */}
        <div className="flex flex-col space-y-4">
          <div className="text-2xl font-semibold">{title}</div>
        </div>

        {/* User */}
        <div className="flex flex-col space-y-2">
          <div className="text-slate11 font-semibold">User</div>
          <div>
            {user?.firstName} {user?.lastName} ({user?.email})
          </div>
        </div>

        {/* Inputs */}
        <div className="flex flex-col space-y-2">
          <div className="text-slate11 font-semibold">Inputs</div>
          <div className="bg-blue3  rounded-md p-4">
            <div className="flex flex-col space-y-4">
              <div className="flex space-x-2">
                <div className="text-slate12 font-medium">Topic</div>
                <div>{initialTopic}</div>
              </div>
              {gradeLevel && (
                <div className="flex space-x-2">
                  <div className="text-slate12 font-medium">Grade Level</div>
                  <div>{gradeLevel.label}</div>
                </div>
              )}
              {subject && (
                <div className="flex space-x-2">
                  <div className="text-slate12 font-medium">Subject</div>
                  <div>{subject.label}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Output */}
        <div className="flex flex-col space-y-2">
          <div className="text-slate11 font-semibold">Alayna Output</div>
          {finalOutput ? (
            <div className="bg-green3  space-y-4 rounded-md p-4">
              <div className="markdown-body">
                <ReactMarkdown
                  remarkPlugins={[remarkMath, remarkGfm]}
                  rehypePlugins={[rehypeRaw, rehypeKatex]}
                >
                  {finalOutput}
                </ReactMarkdown>
              </div>
            </div>
          ) : (
            <div className="bg-slate3 flex justify-center rounded-md p-4">
              <div className="text-slate12 font-medium">
                No output generated.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderToolsTable = () => {
    return (
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {/* Update these headers based on your tools data structure */}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Tool Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created At
                    </th>
                    {/* Add more headers as needed */}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {tools.map((tool, index) => {
                    const toolTypeLabel = toolTypeFilterOptions.find(
                      (item) => item.id === tool.toolId
                    )?.name;

                    return (
                      <tr key={index}>
                        <td
                          className="cursor-pointer whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 hover:underline sm:pl-6"
                          onClick={() => setViewToolId(tool.id)}
                        >
                          {tool.title}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {toolTypeLabel}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {moment(tool.createdAt).format("MM/DD/YYYY hh:mm A")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* Pagination Component */}
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                pageSize={pageSize}
                totalCount={totalCount}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <PageLayout
        left={
          <div>
            <h2 className="text-xl font-semibold">Tools</h2>
          </div>
        }
        right={renderRight}
      >
        {isFetchingCount || isFetchingTools ? (
          <div className="mt-36 flex w-full items-center justify-center">
            <Spinner color="black" size={32} />
          </div>
        ) : (
          <div className="w-full px-20 py-2 pt-4">
            {/* Tools Table */}
            {tools.length > 0 ? (
              renderToolsTable()
            ) : (
              <div className="mt-8 flex w-full items-center justify-center">
                <div className="text-xl font-semibold">
                  No tools found for the selected filters.
                </div>
              </div>
            )}
          </div>
        )}
      </PageLayout>

      {viewToolId && selectedTool && (
        <SlideOverWide
          isOpen={true}
          onCloseModal={() => {
            setViewToolId(null);
          }}
          title={
            toolIdToNameMap[selectedTool.toolId] +
            " " +
            moment(selectedTool.createdAt).format("MM/DD/YYYY hh:mm A")
          }
        >
          {renderSelectedTool()}
        </SlideOverWide>
      )}
    </React.Fragment>
  );
}

export default observer(Tools);
