import React from "react";
import SectionV2 from "../../../models/SectionV2";
import { DashboardBreakpoint } from "../../../types";
import SectionItem from "../SectionItem";

type ListOfSectionsProps = {
  sections: SectionV2[];
};

function ListOfSections({ sections }: ListOfSectionsProps) {
  const renderSectionsLg = () => {
    return (
      <div className="hidden sm:block">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Primary Instructor
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Enrollments
                      </th>
                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Sessions
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Course
                      </th> */}
                      <th scope="col" className="relative py-3.5 ">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {renderClassesList("lg")}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderClassesList = (breakpoint: DashboardBreakpoint) => {
    return sections.map((sectionV2) => {
      return (
        <SectionItem
          key={sectionV2.id}
          sectionV2={sectionV2}
          breakpoint={breakpoint}
        />
      );
    });
  };

  return <React.Fragment>{renderSectionsLg()}</React.Fragment>;
}

export default ListOfSections;
